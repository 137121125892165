import axios from 'axios';
import moment from 'moment';

export const LoadOrderDetials = async (setlist, prefix, token, date, SID) => {
  const data = {
    table_prefix: prefix,
    DATE: moment(date).format('YYYY-MM-DD'),
    SID: SID,
  };
  axios
    .post('/api/orderBooking/loadOrder', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (!res.data.error) {
        setlist(res.data.response);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
