import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BsSquare, BsCheckSquare } from 'react-icons/bs';

import axios from 'axios';
import { setLogin, setToken } from '../utility/Redux/security';
import { useDispatch, useSelector } from 'react-redux';
import { useFocus } from '../utility/hooks/hooks';
import {
  selectISFirebaseSocketOn,
  selectWidth,
  setCompany,
  setCompanyAddress,
  setCurrency,
  setDevloperMode,
  setPrefix,
  setStationID,
  setStationName,
  setUserCode,
  setUserName,
  setUserType,
  setdevloper,
  setemail,
  setfirebaseStatusField,
  setreportsFromDate,
  setreportsTodate,
} from '../utility/Redux/profile';
import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setCheckEmailAlert,
  setloading,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import AlertModal from '../utility/component/modals/alert';
import CheckEmailModall from '../utility/component/modals/resetPassword';
import EmailVerify from '../utility/component/modals/verifyEmailModal';
import Gicon from '../utility/images/go_logo.svg';
import EmailVerified from '../utility/component/modals/emailVerified';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import { UserLogin } from '../utility/component/buttons';
import AlertModal2 from '../utility/component/modals/alertWithLottie';
import ForgetModel from './forgetModel';
import RoutbillingLogo from '../utility/component/styles/routbillingLogo';
import SpinnerLoading from '../utility/component/modals/spinnerLoading';
import CallAPI from '../utility/functions/getData';
import database from '../routeBilling/issueStock/firebase';
import { child, get, set, ref } from 'firebase/database';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import deleteWholeDatabase from '../utility/functions/deleteWholeDatabase';
import HandleIndexedDB from '../utility/functions/indexDbDatabase';
import { licenceExpired_alert } from '../utility/constant';
export const Login = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const isFirebaseSocket = useSelector(selectISFirebaseSocketOn);
  const [remember, setRember] = useState(false);
  const currentUrl = window.location.href;
  const windowWiidt = useSelector(selectWidth);
  const [passRef, setPasswordFocus] = useFocus();
  const [hidden, sethidden] = useState(true);
  const [email, setEmailLogin] = useState(searchParams.get('userName'));
  const [password, setpassword] = useState(searchParams.get('password'));
  const [forget, setForget] = useState(false);
  const [emailBordered, setEmailLoginBordered] = useState('gray');
  const [PasswordBordered, setPasswordBordered] = useState('gray');
  const move = useNavigate();
  const [subDomain, setSubDomain] = useState('');

  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const location = useLocation();

  const secretPass = process.env.REACT_APP_SECRET_KEY;

  const encryptData = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem('prefix', data);
  };

  useEffect(() => {
    if (currentUrl.includes('shopkeeper')) {
      move('/auth/shopkeeper_login');
    }
  }, []);

  const encryptUserType = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem('userType', data);
  };

  const encryptDevMode = (text) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(text),
      secretPass
    ).toString();

    localStorage.setItem('dev', data);
  };

  const storeRememberData = async (email, passwrd) => {
    const e = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();
    const p = CryptoJS.AES.encrypt(
      JSON.stringify(passwrd),
      secretPass
    ).toString();

    localStorage.setItem(
      'rememberd',
      JSON.stringify({
        email: e,
        password: p,
      })
    );
  };

  // const signupData = location.state

  const initialValues = {
    Email: '',
    login_password: '',
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const isRemmberd = JSON.parse(localStorage.getItem('rememberd'));

    if (isRemmberd) {
      const bytes = CryptoJS.AES.decrypt(isRemmberd.email, secretPass);
      const bytes2 = CryptoJS.AES.decrypt(isRemmberd.password, secretPass);

      if (bytes.sigBytes < 0 || bytes2.sigBytes < 0) {
        localStorage.removeItem('rememberd');
      } else {
        const decEmail = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        const decPass = JSON.parse(bytes2.toString(CryptoJS.enc.Utf8));

        setEmailLogin(decEmail);
        setpassword(decPass);
        setRember(true);
      }
    }
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => sethidden(true), 1000);
    return () => clearTimeout(timer);
  }, [hidden]);

  useEffect(() => {
    localStorage.removeItem('stationName');
    localStorage.removeItem('stationId');
    localStorage.removeItem('billDetails');
    localStorage.removeItem('userName');
    localStorage.removeItem('count');
    localStorage.removeItem('tkn');
    localStorage.removeItem('company');
    localStorage.removeItem('histryId');
    localStorage.removeItem('prefix');
    // localStorage.removeItem('expiresAt');
    localStorage.removeItem('orderdItemList');
    localStorage.removeItem('userType');
    localStorage.removeItem('companyDetails');
  }, []);

  const moveHome = (response, userDetail, experiDate) => {
    encryptEmail(email);
    const databaseName = 'userDetails';
    const storeName = 'data';
    if (userDetail) {
      deleteWholeDatabase(databaseName);

      const dataToSave = [
        {
          start_date: userDetail.start_date,
          end_date: userDetail.end_date,
          currency_name: userDetail.currency_name,
          currency_symbol: userDetail.currency_symbol,
          user_email: userDetail.user_email,
          ICODE: 1,
          address: userDetail.address,
          city: userDetail.city,
          company_email: userDetail.company_email,
          company_name: userDetail.company_name,
          company_website: userDetail.company_website,
          user_mobile: userDetail.user_mobile,
          user_name: userDetail.user_name,
        },
      ];

      HandleIndexedDB({
        databaseName,
        storeName,
        dataToSave,
      });
    }

    localStorage.setItem('expiresAt', experiDate);

    dispatch(setToken(response.data.token));

    dispatch(setLogin(true));
    dispatch(setUserName(response.data.data[0].user_name));
    dispatch(setCompany(response.data.data[0].company_name));
    dispatch(setPrefix(response.data.data[0].table_prefix));
    dispatch(setStationName(response.data.data[0].station_name));

    localStorage.setItem(
      'companyDetails',
      JSON.stringify({
        address: userDetail.address,
        city: userDetail.city,
        state: userDetail.state,
        mobile: userDetail.company_mobile,
        email: userDetail.company_email,
        companyName: userDetail.company_name,
        companyWebsite: userDetail.company_website,
        start_date: userDetail.start_date,
        end_date: userDetail.end_date,
      })
    );

    dispatch(
      setCompanyAddress({
        address: userDetail.address,
        city: userDetail.city,
        state: userDetail.state,
        mobile: userDetail.company_mobile,
        email: userDetail.company_email,
        companyName: userDetail.company_name,
        companyWebsite: userDetail.company_website,
      })
    );

    encryptData(response.data.data[0].table_prefix);
    encryptUserType(response.data.data[0].user_type);
    encryptDevMode(userDetail.developer_yn);
    localStorage.setItem('company', response.data.data[0].company_name);
    localStorage.setItem('userName', response.data.data[0].user_name);
    if (response.data.data[0].user_type === 'MANAGER') {
      localStorage.setItem('stationId', '');
    } else {
      localStorage.setItem('stationId', response.data.data[0].station_id);
    }

    if (response.data.data[0].user_type === 'MANAGER') {
      localStorage.setItem('stationName', '');
    } else {
      localStorage.setItem('stationName', response.data.data[0].station_name);
    }

    dispatch(setUserType(response.data.data[0].user_type));
    if (response.data.data[0].user_type === 'MANAGER') {
      dispatch(setStationName(''));
    } else {
      dispatch(setStationName(response.data.data[0].station_name));
    }
    dispatch(setdevloper(userDetail.developer_yn));
    localStorage.removeItem('lastDate');
    if (remember) {
      storeRememberData(email, password);
    } else {
      localStorage.removeItem('rememberd');
    }
    if (response.data.data[0].user_type === 'MANAGER') {
      dispatch(setStationID(''));
    } else {
      dispatch(setStationID(response.data.data[0].station_id));
    }
    if (userDetail.developer_yn === 'Y') {
      localStorage.setItem('devloper', 'YES');
      dispatch(setDevloperMode(true));
    }
    localStorage.setItem('fromDate', moment().format('YYYY-MM-DD'));
    localStorage.setItem('toDate', moment().format('YYYY-MM-DD'));
    dispatch(setreportsFromDate(moment().format('YYYY-MM-DD')));
    dispatch(setreportsTodate(moment().format('YYYY-MM-DD')));
    localStorage.setItem('tknTime', new Date().getTime() + 60 * 60000);

    move('/', {
      state: {
        sid: response.data.data[0].station_id,
        email: email,
        start_date: userDetail.start_date,
        end_date: userDetail.end_date,
        currency: userDetail.currency_symbol,
      },
    });
    dispatch(setemail(email));
    dispatch(setCurrency(userDetail.currency_symbol));
    dispatch(setSessionExpired(false));
  };

  let hasRun = false;
  let hasRun1 = false;

  let hasRun2 = false;

  let hasRun3 = false;

  const getLoginWithFirebase = (
    connectedRef,
    userDetail,
    setConnectRef,
    setConnectMsgRef,
    response,
    statusField
  ) => {
    // Start the two-second timeout when the component mounts
    if (!hasRun) {
      hasRun = true;
      dispatch(setSpinnerLoading('Checking '));

      setTimeout(() => {
        get(child(connectedRef, `/${statusField}/msg`)).then((msg) => {
          if (msg.exists()) {
            const data = msg.val();
            if (!data.includes('rejected')) {
              dispatch(setUserCode(userDetail.code));
              const expire = new Date().getTime() + 60 * 60000;

              set(setConnectRef, `c-${expire}`);
              set(setConnectMsgRef, `connected`);
              dispatch(setfirebaseStatusField(statusField));
              moveHome(response, userDetail, expire);
              dispatch(setSpinnerLoading(false));
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Already connected',
                })
              );
            }
          }
        });
      }, 2000);
    }
  };

  const getMangerLoginWithFirebase = (
    connectedRef,
    userEmail,
    userMobile,
    e,
    hmacString,
    prefix,
    userCode
  ) => {
    // Start the two-second timeout when the component mounts
    if (!hasRun1) {
      hasRun1 = true;
      setTimeout(() => {
        get(child(connectedRef, `/${'status'}/msg`)).then((msg) => {
          if (msg.exists()) {
            const data = msg.val();
            console.log(data);

            if (!data.includes('rejected')) {
              afterCheckFirebase(
                userEmail,
                userMobile,
                e,
                hmacString,
                'status'
              );
            } else {
              const setConnectMsgRef = ref(
                database,
                `${prefix}/user/${userCode}/status2/msg`
              );
              set(setConnectMsgRef, `isConnected`);
              getMangerLoginWithFirebase2(
                connectedRef,
                userEmail,
                userMobile,
                e,
                hmacString,
                prefix,
                userCode
              );
            }
          } else {
            afterCheckFirebase(userEmail, userMobile, e, hmacString, 'status');
          }
        });
      }, 2000);
    }
  };

  const getMangerLoginWithFirebase2 = (
    connectedRef,
    userEmail,
    userMobile,
    e,
    hmacString,
    prefix,
    userCode
  ) => {
    // Start the two-second timeout when the component mounts
    if (!hasRun2) {
      hasRun2 = true;
      setTimeout(() => {
        get(child(connectedRef, `/${'status2'}/msg`)).then((msg) => {
          if (msg.exists()) {
            const data = msg.val();
            if (!data.includes('rejected')) {
              afterCheckFirebase(
                userEmail,
                userMobile,
                e,
                hmacString,
                'status2'
              );
            } else {
              const setConnectMsgRef = ref(
                database,
                `${prefix}/user/${userCode}/status3/msg`
              );
              set(setConnectMsgRef, `isConnected`);
              getMangerLoginWithFirebase3(
                connectedRef,
                userEmail,
                userMobile,
                e,
                hmacString,
                prefix,
                userCode
              );
            }
          } else {
            afterCheckFirebase(userEmail, userMobile, e, hmacString, 'status2');
          }
        });
      }, 2000);
    }
  };

  const getMangerLoginWithFirebase3 = (
    connectedRef,
    userEmail,
    userMobile,
    e,
    hmacString,
    prefix,
    userCode
  ) => {
    // Start the two-second timeout when the component mounts
    if (!hasRun3) {
      hasRun3 = true;
      setTimeout(() => {
        get(child(connectedRef, `/${'status3'}/msg`)).then((msg) => {
          if (msg.exists()) {
            const data = msg.val();
            if (!data.includes('rejected')) {
              afterCheckFirebase(
                userEmail,
                userMobile,
                e,
                hmacString,
                'status3'
              );
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'Already connected',
                })
              );
            }
          } else {
            afterCheckFirebase(userEmail, userMobile, e, hmacString, 'status3');
          }
        });
      }, 2000);
    }
  };

  const checkTodayAccess = (
    userDetail,
    response,
    userEmail,
    userMobile,
    statusField
  ) => {
    if (
      moment(userDetail.end_date).format('YYYY-MM-DD') >=
      moment().format('YYYY-MM-DD')
    ) {
      if (
        userDetail.user_email === userEmail ||
        userDetail.user_mobile === userMobile
      ) {
        if (userDetail.table_prefix !== null) {
          if (userDetail.user_status === 'ACTIVE') {
            if (isFirebaseSocket) {
              if (userDetail.user_type !== 'MANAGER') {
                const dataBaseRef = ref(
                  database,
                  `${response.data.data[0].table_prefix}/user/`
                );
                const connectedRef = ref(
                  database,
                  `${response.data.data[0].table_prefix}/user/${userDetail.code}`
                );
                const setConnectRef = ref(
                  database,
                  `${response.data.data[0].table_prefix}/user/${userDetail.code}/${statusField}/connection`
                );
                const setConnectMsgRef = ref(
                  database,
                  `${response.data.data[0].table_prefix}/user/${userDetail.code}/${statusField}/msg`
                );
                get(child(dataBaseRef, `/${userDetail.code}`))
                  .then((sn) => {
                    if (sn.exists()) {
                      get(child(connectedRef, `/${statusField}/msg`))
                        .then((snapshot) => {
                          if (snapshot.exists()) {
                            set(setConnectMsgRef, `isConnected`);
                            getLoginWithFirebase(
                              connectedRef,
                              userDetail,
                              setConnectRef,
                              setConnectMsgRef,
                              response,
                              statusField
                            );
                          } else {
                            dispatch(setUserCode(userDetail.code));
                            const expire = new Date().getTime() + 60 * 60000;

                            set(setConnectRef, `c-${expire}`);
                            set(setConnectMsgRef, `connected`);
                            dispatch(setfirebaseStatusField(statusField));
                            moveHome(response, userDetail, expire);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      dispatch(setUserCode(userDetail.code));
                      const expire = new Date().getTime() + 60 * 60000;

                      set(setConnectRef, `c-${expire}`);
                      set(setConnectMsgRef, `connected`);

                      dispatch(setfirebaseStatusField(statusField));

                      moveHome(response, userDetail, expire);
                    }
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else {
                dispatch(setUserCode(userDetail.code));
                const expire = new Date().getTime() + 60 * 60000;

                const setConnectRef = ref(
                  database,
                  `${response.data.data[0].table_prefix}/user/${userDetail.code}/${statusField}/connection`
                );

                const setConnectMsgRef = ref(
                  database,
                  `${response.data.data[0].table_prefix}/user/${userDetail.code}/${statusField}/msg`
                );
                set(setConnectRef, `c-${expire}`);
                set(setConnectMsgRef, `connected`);
                dispatch(setfirebaseStatusField(statusField));
                moveHome(response, userDetail, expire);
              }
            } else {
              dispatch(setUserCode(userDetail.code));
              const expire = new Date().getTime() + 60 * 60000;

              moveHome(response, userDetail, expire);
            }
          } else {
            dispatch(
              setAlertMsg(
                'Access to your account has been restricted. Please contact the admin to activate your account.'
              )
            );
            dispatch(setALertStatus(true));
          }
        } else {
          dispatch(
            setAlertMsg('Crediantials Issue Kindly Contact Support Team')
          );
          dispatch(setALertStatus(true));
        }

        //  localStorage.setItem('token',  response.data.token );
      } else {
        dispatch(setAlertMsg('Email does not exist'));
        dispatch(setALertStatus(true));
      }
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: licenceExpired_alert,
          horizontal: true,
        })
      );
    }
  };
  const afterCheckFirebase = (
    userEmail,
    userMobile,
    e,
    hmacString,
    statusField
  ) => {
    const data = {
      user_email: userEmail ? userEmail : '',
      user_mobile: userMobile ? userMobile : '',
      user_password: password,
      code: e.data.response[0].code,
    };
    dispatch(setloading(true));
    dispatch(setSpinnerLoading('Verifying Credentials'));
    axios
      .post(
        `/api/users/login`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${hmacString}`,
          },
        }
      )
      .then(async function (response) {
        if (response !== null) {
          if (response.data.error) {
            dispatch(setSpinnerLoading(false));

            if (response.data.error === 'Invalid Email or Password.') {
              dispatch(
                setAlertWithTitle({
                  title: 'Incorrect Credentials',
                  msg: 'Incorrect username or password. Please try again.',
                  lottie: 'reject',
                })
              );
            } else {
              axios
                .post(
                  `/api/users/userInfo`,
                  {
                    user_email: userEmail ? userEmail : '',
                    user_mobile: userMobile ? userMobile : '',
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${hmacString}`,
                    },
                  }
                )
                .then((res) => {
                  const userDetail = res.data.response[0];
                  if (userDetail.email_verified === 'N') {
                    dispatch(
                      setAlertMsg(
                        'Your account email needs to be verified. Please check your inbox for a verification link'
                      )
                    );
                    dispatch(setALertStatus(true));
                  } else {
                    dispatch(
                      setAlertMsg(
                        'Thank you for registering. Your account is currently being processed. You will be able to login once your account has been activated.'
                      )
                    );
                    dispatch(setALertStatus(true));
                  }
                })
                .catch((err) => {
                  dispatch(
                    setAlertMsg('Email does not exist or invalid details.')
                  );
                  dispatch(setALertStatus(true));
                });
            }
          } else {
            const p = response.data.data[0].table_prefix;
            const w = p.split('_');
            const firstPart = w[0];
            dispatch(setSpinnerLoading('Authentication Successful'));
            if (
              firstPart === isSubdomain(window.location.href) ||
              window.location.host.includes('localhost') ||
              window.location.host.includes('192.168') ||
              e.data.response[0].sub_domain === 'NO' ||
              (response.data.data[0].sub_domain === 'NO' &&
                !isSubdomain(window.location.href))
            ) {
              axios
                .post(
                  `/api/users/userInfo`,
                  {
                    user_email: userEmail ? userEmail : '',
                    user_mobile: !userEmail ? userMobile : '',
                  },
                  {
                    headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${hmacString}`,
                    },
                  }
                )
                .then((res) => {
                  const userDetail = res.data.response[0];
                  checkTodayAccess(
                    userDetail,
                    response,
                    userEmail,
                    userMobile,
                    statusField
                  );
                });
            } else {
              dispatch(setSpinnerLoading(false));
              dispatch(
                setAlertWithTitle({
                  title: 'Error',
                  msg: `Subdomain not matched`,
                })
              );
            }
          }
        } else {
          dispatch(setSpinnerLoading(false));
          dispatch(setAlertMsg('Email does not exist1'));
          dispatch(setALertStatus(true));
        }
      })
      .catch(function (error) {
        console.log(error);

        dispatch(setSpinnerLoading(false));
        if (error.response.data.error === 'Invalid Email or Password.') {
          dispatch(
            setAlertWithTitle({
              title: 'Incorrect Credentials',
              msg: 'Incorrect username or password. Please try again.',
              lottie: 'reject',
            })
          );
        } else {
          dispatch(setAlertMsg(error.code + ' ' + error.message));
          dispatch(setALertStatus(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const loginApi = () => {
    dispatch(setSpinnerLoading('Checking username'));
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let mobileRegex = /^[6-9]\d{9}$/;

    if (re.test(email) || mobileRegex.test(email)) {
      const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;
      // const hmac = CryptoJS.HmacSHA256(message, secret);
      const hmac = CryptoJS.HmacSHA256(email, secretPass);
      // Convert the HMAC to a string and encode it using Base64
      const hmacString = hmac.toString(CryptoJS.enc.Base64);
      let userEmail = re.test(email) ? email : '';
      let userMobile = mobileRegex.test(email) ? email : '';

      if (password.length >= 5) {
        CallAPI(
          '/api/users/userInfo',
          {
            user_email: userEmail ? userEmail : '',
            user_mobile: userMobile ? userMobile : '',
          },
          hmacString,
          (e) => {
            if (!e.data.error) {
              if (e.data.response[0]) {
                const userData = e.data.response[0];
                if (isFirebaseSocket) {
                  dispatch(setSpinnerLoading('Checking connection'));
                  if (
                    userData.user_type !== 'MANAGER' &&
                    userData.user_type !== 'ADMIN'
                  ) {
                    console.log('hi2222');
                    afterCheckFirebase(
                      userEmail,
                      userMobile,
                      e,
                      hmacString,
                      'status'
                    );
                  } else {
                    console.log('hi22s22');

                    const connected = ref(
                      database,
                      `${userData.table_prefix}/user/${userData.code}`
                    );
                    const setConnectMsgRef = ref(
                      database,
                      `${userData.table_prefix}/user/${userData.code}/status/msg`
                    );
                    set(setConnectMsgRef, `isConnected`);

                    getMangerLoginWithFirebase(
                      connected,
                      userEmail,
                      userMobile,
                      e,
                      hmacString,
                      userData.table_prefix,
                      userData.code
                    );
                  }
                } else {
                  afterCheckFirebase(
                    userEmail,
                    userMobile,
                    e,
                    hmacString,
                    'status'
                  );
                }
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Alert',

                    msg: 'User does not exist',
                  })
                );
              }
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Alert',
                  msg: 'something went wrong while getting user info',
                })
              );
            }
          },
          dispatch,
          () => {
            console.log('');
          },
          () => {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'something went wrong while getting user info',
              })
            );
          }
        );
      } else {
        if (password) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Incorrect password',
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Write password',
            })
          );
        }
      }
    } else {
      // invalid email, maybe show an error to the user.

      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Invalid email or mobile',
        })
      );
    }
  };

  const forgetModel = (e) => {
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(e)) {
      setForget(true);
    } else {
      dispatch(
        setAlertMsg('Enter valid user email address for change password')
      );
      dispatch(setALertStatus(true));
    }
  };

  const forgetPassword = (emailCheck) => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(email, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    let re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const phoneRegex = /^[6-9]\d{9}$/;
    if (re.test(emailCheck) || phoneRegex.test(emailCheck)) {
      dispatch(setloading(true));

      axios
        .post(
          '/api/users/findUserDetail',
          {
            user_email: !phoneRegex.test(emailCheck) ? emailCheck : '',
            user_mobile: phoneRegex.test(emailCheck) ? emailCheck : '',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${hmacString}`,
            },
          }
        )
        .then((res) => {
          if (res.data[0]) {
            axios
              .post(
                `/api/users/resetUserPasswordEmail`,
                {
                  user_email: !phoneRegex.test(emailCheck) ? emailCheck : '',
                  user_name: res.data[0].user_name,
                  user_mobile: phoneRegex.test(emailCheck) ? emailCheck : '',
                },
                {
                  headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${hmacString}`,
                  },
                }
              )
              .then((r) => {
                dispatch(setCheckEmailAlert(true));
                // move('/auth/checkEmail')
              })
              .catch((err) => {
                dispatch(setAlertMsg(err.code + ' ' + err.message));
                dispatch(setALertStatus(true));
              })
              .finally(() => {
                dispatch(setloading(false));
              });
          } else {
            dispatch(setAlertMsg('Not registered user'));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          dispatch(setAlertMsg('Something wrong'));
          dispatch(setALertStatus(true));
        });
    } else {
      dispatch(setAlertMsg('Invailed email address'));
      dispatch(setALertStatus(true));
    }
  };

  const encryptEmail = (email) => {
    const data = CryptoJS.AES.encrypt(
      JSON.stringify(email),
      secretPass
    ).toString();

    localStorage.setItem('email', data);
  };

  function isSubdomain(url) {
    const hostname = new URL(url).hostname;

    const parts = hostname.split('.');
    const filteredParts = parts.filter((part) => part.toLowerCase() !== 'www');
    if (filteredParts.length >= 2 && filteredParts[0].toLowerCase() !== 'www') {
      // Check if there are at least 3 parts, and the first part is not 'www'
      return filteredParts.slice(0, -2).join('.');
    } else {
      return null;
    }
  }

  useEffect(() => {
    const currentURL = window.location.href;

    setSubDomain(isSubdomain(currentURL));
  }, []);

  useEffect(() => {
    if (email && password) {
      dispatch(setSpinnerLoading('Connecting'));
      loginApi();
    }
  }, []);

  useEffect(() => {
    if (indexedDB.databases) {
      indexedDB
        .databases()
        .then((databases) => {
          databases.forEach((dbInfo) => {
            if (dbInfo.name !== 'SaveIssueCart') {
              if (dbInfo.name !== 'Calculations') {
                indexedDB.deleteDatabase(dbInfo.name);
              }
            }
          });
        })
        .catch((error) => {
          console.error('Error listing databases:', error);
        });
    } else {
      // Fallback method for browsers that do not support indexedDB.databases()
      console.log('indexedDB.databases() is not supported in this browser.');
    }
  }, []);

  return (
    <div className="items-center flex w-screen h-screen justify-center bg-white">
      {!searchParams.get('userName') && !searchParams.get('password') ? (
        <div className="w-auto  bg-white  h-auto ">
          <div className="h-full w-full ">
            <div className="h-full w-full grid grid-cols-1 t:grid-cols-2 ">
              <div className="h-full w-full flex   ">
                <div className="h-full items-center justify-center t:justify-end t:mr-4 w-full flex  ">
                  <RoutbillingLogo />
                </div>
                <div className="w-4 hidden t:flex  justify-center  h-full items-center ">
                  <div
                    style={{
                      borderLeft: '1px solid #e5e7eb',
                    }}
                    className=" h-full "
                  >
                    {' '}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-center t:items-start justify-start t:ml-4 ">
                <div className="mt-1 font-semibold  flex justify-start  text-[20px] h-fit items-center">
                  Login
                </div>

                <div className="mt-2  ">
                  <div>
                    <div className=" flex justify-between  items-center">
                      <div
                        style={{
                          width: windowWiidt >= 768 ? '300px' : '300px',
                        }}
                      >
                        <div
                          style={{
                            border: `0.5px solid ${emailBordered}`,
                            borderRadius: '3px',
                            overflow: 'hideen',
                          }}
                        >
                          <input
                            autoSave={initialValues.Email}
                            onFocus={() => setEmailLoginBordered('#211adb')}
                            onBlur={() => setEmailLoginBordered('gray')}
                            size={'small'}
                            onKeyDown={(event) => {
                              if (event.key === 'Enter') {
                                setPasswordFocus();
                              }
                            }}
                            value={email}
                            style={{
                              fontSize: '13px',
                              borderRadius: '3px',
                              overflow: 'hideen',
                            }}
                            // pattern="/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/"
                            placeholder="Email Address"
                            name="Email"
                            id="Email"
                            onChange={(t) => {
                              setEmailLogin(t.target.value.toLowerCase());
                            }}
                            className="outline-0 w-full py-2 pl-3 "
                          />
                        </div>
                      </div>
                    </div>

                    <div className=" justify-between  mt-4  items-center ">
                      <div className="flex items-center justify-between  ">
                        <div
                          style={{
                            width: windowWiidt >= 768 ? '300px' : '300px',
                          }}
                        >
                          <div
                            style={{
                              border: `0.5px solid ${PasswordBordered}`,
                              borderRadius: '3px',
                              overflow: 'hideen',
                            }}
                            className="flex  items-center w-full justify-between "
                          >
                            <input
                              size={'small'}
                              //  autoSave={remember}
                              onFocus={() => setPasswordBordered('#211adb')}
                              onBlur={() => {
                                setPasswordBordered('gray');
                              }}
                              onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  loginApi();
                                }
                              }}
                              ref={passRef}
                              className="outline-0 bg-white w-full pl-3"
                              type={'password'}
                              value={password}
                              name="login_password"
                              id="login_password"
                              placeholder="Password"
                              datatype={'password'}
                              // error={
                              //   touched.login_password ? errors.login_password : null
                              // }
                              style={{
                                fontSize: '13px',
                                borderRadius: '3px',
                                overflow: 'hideen',
                                paddingTop: '8px',
                                paddingBottom: '8px',
                              }}
                              onChange={(p) => {
                                setpassword(p.target.value);
                              }}
                            />

                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" flex  items-center justify-between mt-3">
                      <div className="flex items-center">
                        <div>
                          <label htmlFor="checkbox">
                            {!remember && (
                              <BsSquare
                                fill={'grey'}
                                size={12}
                                onClick={() => {
                                  setRember(true);
                                }}
                              />
                            )}
                            {remember && (
                              <BsCheckSquare
                                fill={'#f5874f'}
                                size={12}
                                onClick={() => {
                                  setRember(false);
                                }}
                              />
                            )}
                          </label>
                        </div>{' '}
                        <div
                          style={{ fontSize: 12 }}
                          className=" text-slate-500 pl-1  flex whitespace-nowrap  items-center"
                        >
                          Remember me
                        </div>
                      </div>
                      <div
                        style={{
                          fontSize: '11px',
                        }}
                        className="w-full text-slate-500 flex items-center justify-end pr-1 "
                      ></div>
                    </div>

                    <div className="w-full pt-5 flex items-center justify-center ">
                      <UserLogin onClick={loginApi} />
                    </div>
                  </div>
                  <div className="flex mt-5 justify-between">
                    <div
                      style={{
                        fontSize: '13px',
                      }}
                      className="flex  justify-center "
                    >
                      New User ?{' '}
                      <button
                        onClick={() => {
                          move('/auth/signup');
                        }}
                      >
                        {' '}
                        <label className="pl-1  hover:cursor-pointer text-orange">
                          SignUp
                        </label>
                      </button>
                    </div>
                    <div className=" flex justify-end">
                      <button
                        onClick={() => {
                          if (email) {
                            forgetModel(email);
                          } else {
                            dispatch(
                              setAlertMsg(
                                'Please enter user email address for change password'
                              )
                            );
                            dispatch(setALertStatus(true));
                          }
                        }}
                      >
                        <div
                          style={{ fontSize: 13 }}
                          className="  flex justify-center whitespace-nowrap     text-slate-500"
                        >
                          Forgot
                          <label className="pl-1 font-medium  text-brown">
                            Password ?
                          </label>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button
              onClick={() => {
                window.open('https://geniusoffice.com', '_blank');
              }}
              className="mt-10 text-[13px] w-full justify-center items-center text-brown flex"
            >
              A product of{' '}
              <div className="pl-1 flex items-center">
                <img
                  alt=""
                  src={Gicon}
                  style={{
                    width: '14px',
                    height: '14px',
                  }}
                />
              </div>
              <label
                onClick={() => {
                  window.open('https://geniusoffice.com', '_blank');
                }}
                className="font-semibold hover:cursor-pointer pl-1 pr-1"
              >
                {' '}
                genius office
              </label>
              2023 All rights reserved.
            </button>
          </div>
        </div>
      ) : (
        <div>{subDomain}</div>
      )}
      <AlertModal2 />
      <AlertModal />
      <ForgetModel
        forget={forget}
        setForget={setForget}
        forgetPassword={forgetPassword}
        email={email}
      />
      <CheckEmailModall />

      <EmailVerify email={location.state} />
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <EmailVerified />
      <SpinnerLoading />
    </div>
  );
};
