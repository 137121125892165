import axios from 'axios';
import React from 'react';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { setSpinnerLoading } from '../../Redux/modal';
const emailEncrpted = localStorage.getItem('email');
const secretPass = process.env.REACT_APP_SECRET_KEY;
// const companyAddress = JSON.parse(localStorage.getItem('companyDetails'));

const getemail = () => {
  if (emailEncrpted) {
    const bytes = CryptoJS.AES.decrypt(emailEncrpted, secretPass);
    if (bytes.sigBytes < 0) {
      localStorage.removeItem('prefix');
      localStorage.removeItem('expiresAt');
    } else {
      const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      var email = data;

      return email;
    }
  }

  return null;
};

const APIErrorBug = async (header, api, msg, token, dispatch) => {
  const companyAddress = await JSON.parse(
    localStorage.getItem('companyDetails')
  );
  const data = {
    header: header,
    api_name: api,
    error_message: msg,
    user_email: getemail(),
    user_mobile: '',
    company_email: companyAddress.email,
    date: moment().format('YYYY-MM-DD HH:mm:ss'),
  };
  console.log(api);
  console.log('BUG REPORTED');
  dispatch(setSpinnerLoading('Sending bug report'));
  axios
    .post(
      `/api/erroLogs/saveErrorLogs`,

      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export default APIErrorBug;
