import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';

export const DeleteItem = async (
  mfgDetails,
  prefix,
  token,
  dispatch,
  setAlertTwoButtonModal,
  alertTwoButtonModal,

  setlist
) => {
  setAlertTwoButtonModal({
    ...alertTwoButtonModal,
    status: false,
  });

  dispatch(setSpinnerLoading('Deleting item'));
  const data = {
    table_prefix: prefix,
    SERIAL_NO: mfgDetails.SERIAL_NO,
    ICODE: mfgDetails.ICODE,
  };
  axios
    .post('api/saveInHouseMfg/deleteMfgItem', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((p) => {
      if (!p.data.error) {
        setlist((p) => p.filter((i) => i.ICODE !== mfgDetails.ICODE));
        dispatch(
          setAlertWithTitle({
            title: 'Success',
            msg: 'Succesfully deleted item',
            lottie: 'success',
          })
        );
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something went wrong',
          })
        );
      }
    })
    .catch((error) => {
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something went wrong',
          })
        );
      }
      console.log(error);
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
