import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { useState } from 'react';
import { toProperCase } from '../utility/component/format';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import axios from 'axios';
import { useEffect } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/buttons';
import { useFocus } from '../utility/hooks/hooks';

const GeniusDepartmentMaster = () => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const windowWidth = useSelector(selectWidth);
  const [id, setId] = useState();
  const [deptName, setDeptName] = useState();
  const [rows, setRows] = useState([]);
  const [deptError, setDeptError] = useFocus();

  const columns = [
    {
      field: 'DEPARTMENT_NAME',
      type: 'string',
      renderHeader: (params) => <div>Department Name</div>,
      width: windowWidth > 764 ? 600 : 300,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const getListDepartmentMaster = async () => {
    await axios
      .post(
        '/api/issueMasterRoute/listDepartmentMaster',
        {
          table_prefix: prefix,
          DEPARTMENT_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      });
  };

  useEffect(() => {
    getListDepartmentMaster();
  }, []);

  const saveDepartmentMasterApi = () => {
    if (deptName) {
      const isDuplicateMobile = rows.some(
        (row) => row.DEPARTMENT_NAME === deptName
      );

      if (isDuplicateMobile) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This department is already exists. Please choose a different department.',
          })
        );
        return;
      }

      const data = {
        table_prefix: prefix,
        CODE: id ? id : '',
        DEPARTMENT_NAME: deptName,
        REGIONAL_INAME: '',
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/issueMasterRoute/saveDepartmentMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
            console.log(error.response);
          }
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          setDeptName('');
          setId('');
          getListDepartmentMaster();
        });
    } else {
      if (!deptName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Department Name.',
            lottie: 'reject',
          })
        );
        setDeptError();
      }
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === 's') {
        e.preventDefault();
        saveDepartmentMasterApi();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [id, deptName]);

  return (
    <div className="flex-grow  bg-white    border-brown px-5 max-sm:px-2">
      <div id="input" className="flex mt-4">
        <div>
          <GeniustextInput
            title={'Department Name'}
            width={windowWidth > 764 ? '600px' : '300px'}
            type={'text'}
            //   search={handleOpenDeptHelp}
            value={deptName}
            onChange={(e) => {
              setDeptName(e.target.value);
            }}
            refrance={deptError}
            autofocus={true}
          />
        </div>
      </div>

      <div
        style={{
          width: columns[0].width,
        }}
        className="w-full h-[400px] mt-1 flex"
      >
        <GeniusDatagrid
          rowID={(t) => t.CODE}
          onRowClick={(p) => {
            setId(p.row.CODE);
            setDeptName(p.row.DEPARTMENT_NAME);
          }}
          columns={columns}
          list={rows}
        />
      </div>

      <div className="flex w-full justify-end items-center py-5">
        {id ? (
          <ModifyButton onClick={saveDepartmentMasterApi} />
        ) : (
          <SaveButton onClick={saveDepartmentMasterApi} />
        )}

        <UndoButton
          onClick={() => {
            setDeptName('');
            setId('');
          }}
        />
      </div>
    </div>
  );
};

export default GeniusDepartmentMaster;
