import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectAccountDatagridList,
  setAccountDatagridList,
} from '../../../../../utility/Redux/list';

import {
  setAlertMsg,
  setALertStatus,
  setBillingAccountMasterModalStatus,
  setloading,
  setSessionExpired,
} from '../../../../../utility/Redux/modal';
import {
  selectDefultProfileSetting,
  selectPrefix,
  selectStationId,
  setBillingAccountDetails,
  setlatitudeRedux,
  setlongitudeRedux,
} from '../../../../../utility/Redux/profile';
import { selectToken } from '../../../../../utility/Redux/security';
import { clearOrderBillingList } from '../../../../../utility/Redux/tableOrder';
import DistanceCalculator from '../../../../../utility/functions/getDistance';
import { FormatNumber2 } from '../../../../../utility/component/tools';
import { BackButton } from '../../../../../utility/component/buttons';
import { useNavigate } from 'react-router-dom';

const width = window.innerWidth / 100;

const BillingAccountGrid = () => {
  const dispatch = useDispatch();
  const [searchText, SetSearchText] = useState('');
  const [searchCode, SetSearchCode] = useState('');
  const [filterdList, setFilteredList] = useState([]);
  const [latitude, setLattitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const navigate = useNavigate();
  const userDefaultSetting = useSelector(selectDefultProfileSetting);
  const isProduction = process.env.NODE_ENV === 'production';

  const getDriverLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        dispatch(setlatitudeRedux(position.coords.latitude));
        dispatch(setlongitudeRedux(position.coords.longitude));
        setLattitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      },
      (error) => {
        if (isProduction) {
          navigator.geolocation.getCurrentPosition((position) => {
            dispatch(setlatitudeRedux(position.coords.latitude));
            dispatch(setlongitudeRedux(position.coords.longitude));
          });
          dispatch(
            setAlertMsg(
              'To continue using this app, please enable location services from site setting and phone location.'
            )
          );
          dispatch(setALertStatus(true));
        }
        console.error(error);
      }
    );
  };

  const columns = React.useMemo(
    () => [
      {
        field: 'ACCOUNT_NO',
        type: 'string',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <div
            style={{
              fontSize: '14px',
            }}
          >
            {'Acc. No.'}
          </div>
        ),
        className: 'super-app-theme--cellWithRightBorder',
      },
      {
        field: 'NAME',
        type: 'string',
        width: 250,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => (
          <div
            style={{
              fontSize: '14px',
            }}
          >
            {'Party Name'}
          </div>
        ),
      },
      {
        field: 'MOBILE',
        type: 'number',
        width: 100,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        className: 'super-app-theme--cellWithRightBorder',
        renderHeader: (params) => (
          <div
            style={{
              fontSize: '14px',
            }}
          >
            {'Mobile'}
          </div>
        ),
      },
      {
        field: 'ADDRESS',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        className: 'super-app-theme--cellWithotRightBorder',
        renderHeader: (params) => (
          <div
            className={'px-28'}
            style={{
              fontSize: '14px',
            }}
          >
            {'Address'}
          </div>
        ),
      },
    ],
    []
  );

  const columnsMobile = React.useMemo(
    () => [
      {
        field: 'ACCOUNT_NO',
        type: 'string',
        width: 55,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'Acc. No.'}</div>,
      },
      {
        field: 'NAME',
        type: 'string',
        width: width * 30,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div>{'Party Name'}</div>,
      },
      {
        field: 'MOBILE',
        type: 'number',
        width: width * 20,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <div>{'Mobile'}</div>,
      },
      {
        field: 'ADDRESS',
        type: 'string',
        flex: 1,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <div className="px-5">{'Address'}</div>,
      },
    ],
    []
  );

  const token = useSelector(selectToken);

  const prefix = useSelector(selectPrefix);

  const station_id = useSelector(selectStationId);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getAccountData = () => {
    dispatch(setloading(true));

    axios
      .post(
        `/api/masters/listAccountMaster`,
        {
          table_prefix: prefix,
          mobile: '',
          name: '',
          sid: station_id,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;

          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
          }
          dispatch(setAccountDatagridList(rowList));

          getDriverLocation();
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  const rows = useSelector(selectAccountDatagridList);

  useEffect(() => {
    getAccountData();
  }, []);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = rows.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchText);
        } else {
          return item.NAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });
      setFilteredList(searchItems);
    } else {
      if (searchCode) {
        const regex = /\d+/;

        const searchItems = rows.filter((item) => {
          if (regex.test(searchCode.substring(0, 1))) {
            return item.MOBILE.toString().includes(searchCode);
          } else {
            return item.NAME.toLowerCase().includes(searchCode.toLowerCase());
          }
        });

        setFilteredList(searchItems);
      }
    }

    if (!searchCode && !searchText) {
      setFilteredList([]);
    }
  }, [searchText, searchCode]);

  const onRowClick = (params) => {
    // const name = params.data.NAME;
    const mobile = params.row.MOBILE;

    if (mobile) {
      if (
        userDefaultSetting?.check_bill_location === 'YES' &&
        params.row.LATITUDE !== null
      ) {
        // const shopKeeperLat = params.row.
        const distance = DistanceCalculator(
          latitude,
          longitude,
          params.row.LATITUDE,
          params.row.LONGITUDE
        );

        if (distance < 0.1) {
          const name = params.row.NAME;
          const mobile = params.row.MOBILE;
          const accNumber = params.row.ACCOUNT_NO;
          const address = params.row.ADDRESS;

          dispatch(clearOrderBillingList());
          dispatch(
            setBillingAccountDetails({
              accNumber: accNumber,
              accMobile: mobile,
              accName: name,
              accAddress: address,
            })
          );

          dispatch(setBillingAccountMasterModalStatus(false));
        } else {
          dispatch(
            setAlertMsg(
              `Kindly proceed to the address of the shopkeeper to generate a bill.Currently, you are approximately   ${FormatNumber2(
                distance
              )}  kilometers away from the shop. If you are currently connected to a Wi-Fi network, please disable it and connect to your personal internet connection.`
            )
          );
          dispatch(setALertStatus(true));
        }
      } else {
        const name = params.row.NAME;
        const mobile = params.row.MOBILE;
        const accNumber = params.row.ACCOUNT_NO;
        const address = params.row.ADDRESS;

        dispatch(clearOrderBillingList());
        dispatch(
          setBillingAccountDetails({
            accNumber: accNumber,
            accMobile: mobile,
            accName: name,
            accAddress: address,
          })
        );

        dispatch(setBillingAccountMasterModalStatus(false));
      }
    } else {
      dispatch(
        setAlertMsg('Please update party mobile number to continue billing')
      );
      dispatch(setALertStatus(true));
    }
  };
  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = rows.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchText);
        } else {
          return item.NAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });
      setFilteredList(searchItems);
    } else {
      if (searchCode) {
        const regex = /\d+/;

        const searchItems = rows.filter((item) => {
          if (regex.test(searchCode.substring(0, 1))) {
            return item.ACCOUNT_NO.toString().includes(searchCode);
          } else {
            return item.NAME.toLowerCase().includes(searchCode.toLowerCase());
          }
        });

        setFilteredList(searchItems);
      }
    }

    if (!searchCode && !searchText) {
      setFilteredList([]);
    }
  }, [searchText, searchCode]);
  return (
    <div className=" h-full  overflow-hidden  w-full flex flex-col ">
      <div
        style={{
          border: '1px solid black',
          borderTopRightRadius: '8px',
          borderTopLeftRadius: '8px',
          borderBottom: '1px solid black',
          overflow: 'hidden',
        }}
        className="flex flex-col grow"
      >
        <div
          style={{
            fontSize: '14px',
            // border: '1px solid black',
            // borderBottom: '0px',

            // borderTopRightRadius: '8px',
            // borderTopLeftRadius: '8px',
            // borderBottom: '1px solid black',
          }}
          className="font-semibold bg-[#f5f0e8] font-arial p-3"
        >
          Account Master | Help
        </div>
        <DataGrid
          hideFooterPagination
          hideFooter
          components={{
            Header: () => {
              const headerDetails =
                window.innerWidth < 770 ? columnsMobile : columns;
              return (
                <div className="  bg-[#f5f0e8] flex ">
                  {headerDetails?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="  text-black flex justify-center items-center py-2  "
                        style={
                          item.flex
                            ? index < headerDetails.length - 1
                              ? {
                                  flex: item.flex,
                                  borderRight: '1px solid #4a4948',
                                  borderBottom: '1px solid #4a4948',
                                }
                              : {
                                  flex: item.flex,
                                  borderBottom: '1px solid #4a4948',
                                }
                            : index < headerDetails.length - 1
                            ? {
                                width: item.width,

                                borderRight: '1px solid #4a4948',
                                borderBottom: '1px solid #4a4948',
                              }
                            : {
                                width: item.width,
                                borderBottom: '1px solid #4a4948',
                              }
                        }
                      >
                        {item.renderHeader()}
                      </div>
                    );
                  })}
                </div>
              );
            },
          }}
          headerHeight={0}
          sx={
            window.innerWidth < 770
              ? {
                  backgroundColor: 'white',

                  fontSize: 9,
                  '& .super-app-theme--header': {
                    backgroundColor: '#f5f0e8',
                    display: 'none',
                  },
                  borderTop: '1px solid black',
                  borderRadius: 0,
                }
              : {
                  backgroundColor: 'white',
                  fontSize: 13,
                  borderTop: '1px solid black',
                  borderRadius: 0,
                  '& .super-app-theme--header': {
                    backgroundColor: '#f5f0e8',
                    borderRight: `1px solid #4a4948`,

                    display: 'none',
                  },
                }
          }
          rowHeight={33}
          columns={window.innerWidth < 770 ? columnsMobile : columns}
          rows={searchText || searchCode ? filterdList : rows}
          onRowClick={onRowClick}
          pageSize={100}
          rowsPerPageOptions={[100]}
          experimentalFeatures={{ newEditingApi: true }}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          disableColumnMenu={true}
        />
      </div>

      <div
        style={{
          borderLeft: '1px solid black',
          borderRight: '1px solid black',
          fontSize: '14px',
          backgroundColor: '#f5f0e8',
        }}
        className="flex p-2 "
      >
        <i className="bi bi-search pr-2"></i>
        <input
          type={'text'}
          placeholder="Search"
          value={searchCode}
          onChange={(t) => {
            SetSearchCode(t.target.value);
          }}
          className="w-full outline-0 bg-[#f5f0e8]"
        />
      </div>

      <div
        style={{
          borderTop: '1px dotted #4a4948',
          borderLeft: '1px dotted #4a4948',
          borderRight: '1px dotted #4a4948',
          borderBottom: '1px dotted #4a4948',
          borderBottomRightRadius: '8px',
          borderBottomLeftRadius: '8px',
        }}
        className="w-full flex justify-end p-2 "
      >
        <BackButton
          onClick={() => {
            navigate('/');
          }}
        />
      </div>
    </div>
  );
};

export default BillingAccountGrid;
