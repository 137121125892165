import axios from 'axios';
import moment from 'moment';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';

export const LoadMfg = async (date, prefix, token, setlist, dispatch) => {
  const data = {
    table_prefix: prefix,
    DATE: moment(date).format('YYYY-MM-DD'),
  };
  dispatch(setSpinnerLoading('Loading data'));
  axios
    .post('/api/saveInHouseMfg/loadMfg', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (!res.data.error) {
        const data = res.data.response;

        setlist(data);
      }
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Something went wrong',
            lottie: 'reject',
          })
        );
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
