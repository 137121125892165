import React, { useEffect, useRef, useState } from 'react';
import {
  ModifyButton,
  PrintButton,
  SaveButton,
  UndoButton,
} from '../utility/component/buttons';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { Country, State, City } from 'country-state-city';
import { toProperCase } from '../utility/component/format';
import {
  setAlertWithTitle,
  setModEmployeeMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import axios from 'axios';
import ModEmployeeMasterHelp from '../utility/component/modals/modEmployeeMasterHelp';
import ProfilePicFrame from '../utility/component/styles/profilePicFrame';
import GeniustextInput from '../utility/component/mastertext';
import { DownloadFile, UploadFile } from '../utility/functions/firebase';
import UploadImageButtons from '../utility/component/UploadImageButtons';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import { useFocus } from '../utility/hooks/hooks';

const EmployeeMaster = () => {
  const styleInput = {
    height: '42px',
    width: '100%',
    border: '1px solid #b5b3b3',
    borderRadius: '5px',
    fontSize: '14px',
    color: '#4a4a4a',
  };

  const dispatch = useDispatch();

  const [mobile, setMobile] = useState('');
  const [workerName, setWorkerName] = useState('');
  const [Status, setStatus] = useState('');
  const [fatherName, setFatherName] = useState('');
  const [email, setEmail] = useState('');
  const windowWidth = useSelector(selectWidth);
  const [firebsaePic, setFirebasePic] = useState('');
  const [path, setPath] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [sewingRate, setSewingRate] = useState('');
  const [cuttingRate, setCuttingRate] = useState('');
  const [idNo, setIdNo] = useState('');
  const [vehicleName, setvehicleName] = useState('');
  const [workerData, setWorkerData] = useState([]);
  const [id, setId] = useState('');

  const webcamRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState('');
  const [webcamStatus, setWebcamStatus] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  const [selectedState, setSelectedState] = useState('');
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);
  const [vehicleNo, setVehicleNo] = useState('');
  const [designation, setDesignation] = useState('');
  const prefix = useSelector(selectPrefix);

  const token = useSelector(selectToken);

  const [mobileRef, setMobileFocus] = useFocus();
  const [employeeRef, setEmployeeFocus] = useFocus();
  const [emailRef, setEmailFocus] = useFocus();

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const countries = Country.getAllCountries();

  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  useEffect(() => {
    if (selectedCountry) {
      const sCountry = countries.filter(
        (country) => country.name === selectedCountry
      );
      if (sCountry[0]) {
        setStatelist(State.getStatesOfCountry(sCountry[0].isoCode));
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && stateList[0]) {
      const sState = stateList.filter((state) => state.name === selectedState);
      if (sState[0]) {
        setCitylist(
          City.getCitiesOfState(sState[0].countryCode, sState[0].isoCode)
        );
      }
    }
  }, [selectedState, stateList]);

  const [isValidEmail, setIsValidEmail] = useState(true);

  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e) => {
    const newEmail = e.target.value.toLowerCase();
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const saveEmployeeMasterApi = () => {
    if (workerName && mobile && isValidEmail) {
      // Check for duplicate mobile numbers before saving
      const isDuplicateMobile = workerData.some((row) => row.mobile === mobile);

      if (isDuplicateMobile && !id) {
        // Handle the case where the mobile number already exists for new items
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Mobile Number already exists. Please choose a different number.',
          })
        );
        return; // Exit the function without saving
      }

      const data = {
        table_prefix: prefix,
        CODE: id ? id : '',
        EMPLOYEE_NAME: workerName,
        FNAME: fatherName,
        EMAIL: email,
        MOBILE: mobile,
        PHONE: phone,
        ADDRESS: address,
        CITY: city,
        STATE: selectedState,
        COUNTRY: selectedCountry,
        PIN_CODE: pinCode,
        ID_NO: idNo,
        STOP_YN: Status ? Status : 'NO',
        VEHICLE_NAME: vehicleName,
        VEHICLE_NO: vehicleNo,
        DESIGNATION: designation,
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/masters/saveEmployeeMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
            if (capturedImage || selectedFile) {
              const camera = capturedImage
                ? await fetch(capturedImage).then((response) => response.blob())
                : '';
              const file = selectedFile ? selectedFile : camera;

              const name = prefix + mobile;
              const path = 'rootbilling/employee/';
              UploadFile(file, path, name);
            }
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
            console.log(error.response);
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          clearState();
        });
    } else {
      if (!workerName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Worker Name.',
            lottie: 'reject',
          })
        );
        setEmployeeFocus();
      }

      if (!mobile) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Mobile Number.',
            lottie: 'reject',
          })
        );
        setMobileFocus();
      }

      if (email && !isValidEmail) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter valid Email.',
            lottie: 'reject',
          })
        );
        setEmailFocus();
      }
    }
  };
  const headerRef = useRef();
  const navigate = useNavigate();
  const clearState = () => {
    setId('');
    setMobile('');
    setWorkerName('');
    setFatherName('');
    setEmail('');
    setPhone('');
    setAddress('');
    setCity('');
    setSelectedCountry('');
    setSelectedState('');
    setCity('');
    setPinCode('');
    setSewingRate('');
    setCuttingRate('');
    setIdNo('');
    setStatus('');
    setFirebasePic('');
    setSelectedFile('');
    setCapturedImage('');
    setvehicleName('');
    setVehicleNo('');
    setDesignation('');
  };

  const formRef = useRef(null);

  useEffect(() => {
    const inputFields = formRef.current.querySelectorAll('input, select');

    // Add a keydown event listener to each input and select field
    inputFields.forEach((field, index) => {
      field.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          event.preventDefault(); // Prevent form submission
          const nextIndex = (index + 1) % inputFields.length;
          if (index === 2) {
            inputFields[4].focus();
          }
          if (index === 3) {
            inputFields[5].focus();
          }
          if (index === 11) {
            inputFields[0].focus();
          } else {
            inputFields[nextIndex].focus();
          }
        }
      });
    });

    // Cleanup event listeners on unmount
    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('keydown', () => {});
      });
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === 's') {
        e.preventDefault();
        saveEmployeeMasterApi();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    id,
    mobile,
    workerName,
    sewingRate,
    cuttingRate,
    isValidEmail,
    address,
    city,
    selectedCountry,
    selectedState,
    pinCode,
    idNo,
    Status,
    phone,
    fatherName,
  ]);

  return (
    <div
      ref={formRef}
      className="w-full h-full flex justify-center p-8 bg-[#f5f5f7] overflow-y-scroll"
    >
      <div style={{ borderRadius: '12px' }} className=" h-full">
        <div ref={headerRef}>
          <ModalHeaderStyle
            header={'Employee Master'}
            onClose={() => {
              navigate('/masters');
            }}
          />
        </div>

        <div className=" bg-white px-6 py-5 flex flex-col  max-sm:px-2">
          <div>
            <div
              style={{
                display: windowWidth <= 768 ? 'block' : 'none',
              }}
              className="ml-3 flex justify-end mb-5 "
            >
              <div className="w-full">
                <div
                  style={{
                    border: '1px solid #b5b3b3',
                    width: '150px',
                    height: '150px',
                  }}
                  className="p-2 rounded-sm"
                >
                  <ProfilePicFrame
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    webcamRef={webcamRef}
                    webcamStatus={webcamStatus}
                    capturedImage={capturedImage}
                    setCapturedImage={setCapturedImage}
                    setWebcamStatus={setWebcamStatus}
                    customerFirebasePic={firebsaePic}
                    path={path}
                    setFirebaseUrl={setFirebasePic}
                  />
                </div>

                <div
                  className="flex mt-2"
                  style={{
                    width: '150px',
                  }}
                >
                  <UploadImageButtons
                    title={'Employee Image'}
                    setSelectedFile={setSelectedFile}
                    webcamStatus={webcamStatus}
                    setWebcamStatus={setWebcamStatus}
                  />
                </div>
              </div>
            </div>
            <div className="  flex">
              <div>
                <GeniustextInput
                  title={'Mobile'}
                  search={() => {
                    dispatch(setModEmployeeMasterHelpStatus(true));
                  }}
                  width={'300px'}
                  type={'number'}
                  error={'mobile'}
                  autofocus={true}
                  value={mobile}
                  onChange={(t) => {
                    if (t.target.value.length < 16) {
                      setMobile(t.target.value);
                    } else {
                      dispatch(
                        setAlertWithTitle({
                          title: 'Mobile Number',
                          msg: 'Please enter a valid  mobile number.',
                        })
                      );
                    }
                  }}
                  refrance={mobileRef}
                />

                <GeniustextInput
                  title={'Employee Name'}
                  width={'300px'}
                  type={'text'}
                  value={workerName}
                  onChange={(e) => {
                    setWorkerName(toProperCase(e.target.value));
                  }}
                  refrance={employeeRef}
                />
                <GeniustextInput
                  title={'Father Name'}
                  width={'300px'}
                  type={'text'}
                  style={styleInput}
                  value={fatherName}
                  onChange={(e) => {
                    setFatherName(toProperCase(e.target.value));
                  }}
                />
              </div>

              <div
                style={{
                  display: windowWidth > 768 ? 'block' : 'none',
                }}
                className="ml-3 flex justify-end "
              >
                <div className="w-full">
                  <div
                    style={{
                      border: '1px solid #b5b3b3',
                      width: '150px',
                      height: '150px',
                    }}
                    className="p-2 rounded-sm"
                  >
                    <ProfilePicFrame
                      selectedFile={selectedFile}
                      setSelectedFile={setSelectedFile}
                      webcamRef={webcamRef}
                      webcamStatus={webcamStatus}
                      capturedImage={capturedImage}
                      setCapturedImage={setCapturedImage}
                      setWebcamStatus={setWebcamStatus}
                      customerFirebasePic={firebsaePic}
                      setFirebaseUrl={setFirebasePic}
                      path={path}
                    />
                  </div>
                  <div
                    className="flex mt-2"
                    style={{
                      width: '150px',
                    }}
                  >
                    <div className="flex w-[100%] items-center mr-1">
                      <UploadImageButtons
                        title={'Employee Image'}
                        setSelectedFile={setSelectedFile}
                        webcamStatus={webcamStatus}
                        setWebcamStatus={setWebcamStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className=" flex ">
              <GeniustextInput
                title={'Email'}
                width={windowWidth > 764 ? '300px' : '150px'}
                type={'text'}
                value={email}
                error={'email'}
                onChange={handleEmailChange}
                refrance={emailRef}
              />
              <div className="ml-2">
                <GeniustextInput
                  title={'Phone'}
                  width={windowWidth > 764 ? '308px' : '150px'}
                  type={'text'}
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className=" flex">
              <GeniustextInput
                title={'Address'}
                width={windowWidth > 764 ? '300px' : '150px'}
                type={'text'}
                value={address}
                onChange={(e) => {
                  setAddress(toProperCase(e.target.value));
                }}
              />
              <div className="ml-2">
                <GeniustextInput
                  title={'Pincode'}
                  width={windowWidth > 764 ? '308px' : '150px'}
                  type={'text'}
                  value={pinCode}
                  onChange={(e) => {
                    setPinCode(e.target.value);
                  }}
                />
              </div>
            </div>

            <div className=" grid grid-cols-2 t:grid-cols-3 gap-2">
              <GeniustextInput
                title={'Country'}
                width={windowWidth >= 768 ? '200px' : '150px'}
                option={countries}
                value={selectedCountry}
                onChange={handleCountryChange}
              />
              <div>
                <GeniustextInput
                  title={'State'}
                  width={windowWidth >= 768 ? '200px' : '150px'}
                  value={selectedState}
                  option={stateList}
                  onChange={handleStateChange}
                />
              </div>
              <div>
                <GeniustextInput
                  title={'City'}
                  width={windowWidth >= 768 ? '200px' : '150px'}
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  option={cityList}
                />
              </div>
            </div>

            <div className=" grid grid-cols-2 t:grid-cols-3 gap-2 ">
              <GeniustextInput
                title={'Id No.'}
                width={windowWidth >= 768 ? '200px' : '150px'}
                type={'text'}
                value={idNo}
                onChange={(e) => {
                  setIdNo(e.target.value);
                }}
              />
              <div>
                <GeniustextInput
                  title={'Active'}
                  width={windowWidth >= 768 ? '200px' : '150px'}
                  option={[
                    {
                      name: 'Select Option',
                    },
                    {
                      name: 'NO',
                      value: 'NO',
                    },
                    {
                      name: 'YES',
                      value: 'YES',
                    },
                  ]}
                  value={Status}
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 t:grid-cols-3 gap-2">
              <GeniustextInput
                title={'Vehicle Name'}
                width={windowWidth >= 768 ? '200px' : '150px'}
                type={'text'}
                value={vehicleName}
                onChange={(e) => {
                  setvehicleName(e.target.value);
                }}
              />
              <div>
                <GeniustextInput
                  title={'Vehicle Number'}
                  width={windowWidth >= 768 ? '200px' : '150px'}
                  type={'text'}
                  value={vehicleNo}
                  onChange={(e) => {
                    setVehicleNo(e.target.value);
                  }}
                />
              </div>
              <div>
                <GeniustextInput
                  title={'Designation'}
                  width={windowWidth >= 768 ? '200px' : '150px'}
                  type={'text'}
                  value={designation}
                  onChange={(e) => {
                    setDesignation(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            borderTop: '1px solid #D2D2D7',
          }}
          className="flex justify-end bg-white py-5 rounded-b-sm  px-4 "
        >
          <div className="flex justify-end items-center h-full ">
            <div className="mr-1 ">
              {id ? (
                <ModifyButton onClick={saveEmployeeMasterApi} />
              ) : (
                <SaveButton onClick={saveEmployeeMasterApi} />
              )}
            </div>
            <PrintButton />
            <UndoButton onClick={clearState} />
          </div>
        </div>
        {/* // </div> */}

        <ModEmployeeMasterHelp
          onRowClick={(p) => {
            const path = `rootbilling/employee/${prefix + p.row.mobile}`;
            DownloadFile(path)
              .then((e) => {
                if (!e.includes('FirebaseError')) {
                  setFirebasePic(e);
                  setPath(e);
                } else {
                  setFirebasePic('');
                  setPath('');
                }
              })
              .catch((e) => {
                // setCapturedImage('');
                setFirebasePic('');
              });
            setId(p.row.code);
            setMobile(p.row.mobile);
            setWorkerName(p.row.employee_name);
            setFatherName(p.row.fname);
            setEmail(p.row.email);
            setPhone(p.row.phone);
            setAddress(p.row.address);
            setCity(p.row.city);
            setSelectedState(p.row.state);
            setSelectedCountry(toProperCase(p.row.country));
            setPinCode(p.row.pin_code);
            setSewingRate(p.row.sewing_rate);
            setCuttingRate(p.row.cutting_rate);
            setvehicleName(p.row.vehicle_name);
            setVehicleNo(p.row.vehicle_no);
            setDesignation(p.row.designation);
            setIdNo(p.row.id_no);
            setStatus(p.row.stop_yn);
            dispatch(setModEmployeeMasterHelpStatus(false));
          }}
        />
      </div>
    </div>
  );
};

export default EmployeeMaster;
