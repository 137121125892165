import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertWithTitle,
  setIssueStockItem,
  setSessionExpired,
  setSpinnerLoading,
  setloading,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from '../../Redux/modal';
import axios from 'axios';
import { selectPrefix, selectWidth } from '../../Redux/profile';
import moment from 'moment';
import { selectToken } from '../../Redux/security';
import { FormatNumber2 } from '../tools';
import DebitFooter from '../debitFooter';
import { Close } from '@mui/icons-material';
import AlertModalTwoButton from './alertWithTwoButton';
import ModNumericKeyboard from '../../../routeBilling/menu/dashboard/biiling/modal/modalNumericKeyBoard';
import GeniusDatagrid from '../datagrid/geniusDatagrid';

const DebitList = ({ stationId, stationName, setDebit, setStatus, date }) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });
  const [voucherList, setVoucherList] = useState([]);
  const [searchCode, setSearchCode] = useState('');
  const [filterdList, SetFilterdList] = useState([]);
  const windoWidth = useSelector(selectWidth);
  const columns = [
    {
      width: windoWidth > 768 ? 100 : 60,
      headerAlign: 'center',
      align: 'center',
      field: 'ACCOUNT_NO',
      headerName: 'Code',
      renderHeader: (params) => <div>Acc. No.</div>,
    },
    {
      width: windoWidth > 768 ? 300 : 250,

      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width: windoWidth > 768 ? 120 : 80,

      field: 'AMOUNT_PAID',
      renderHeader: (params) => <div>BTC Amount</div>,
      renderCell: (param) => {
        if (param.value) {
          return (
            <div className="flex  ">
              <div> {FormatNumber2(param.value)}</div>
              {param.row.VOUCHER_NO > 0 && (
                <button>
                  <Close
                    onClick={() => {
                      setAlertTwoButtonModal({
                        title: 'Alert',
                        msg: 'Are you sure you want to delete this Item',
                        status: true,
                        button1: 'Yes',
                        button1Click: () => {
                          deleteVoucher(param.row.VOUCHER_NO);
                        },
                        button2: 'Cancel',
                        button2Click: () =>
                          setAlertTwoButtonModal({
                            ...alertTwoButtonModal,
                            status: false,
                          }),
                      });
                    }}
                    color="red"
                    sx={{
                      color: 'red',
                      marginLeft: '8px',
                      fontSize: '18px',
                    }}
                  />
                </button>
              )}
            </div>
          );
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      align: 'right',
      editable: true,
    },
  ];

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const getCollection = () => {
    const data = {
      table_prefix: prefix,
      SID: stationId,
      DATE: moment(date).format('YYYY-MM-DD'),
    };
    dispatch(setloading('Loading'));
    axios
      .post(
        `/api/dayEndSumRoute/debitBTCList`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        console.log(response);
        if (!response.data.error) {
          const data = response.data.response;
          setList(data);
        }

        if (!response.data.error) {
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const cellEdit = (e) => {
    if (e.field === 'AMOUNT_PAID') {
      setList((l) =>
        l.map((column) => {
          if (column.ACCOUNT_NO === e.id) {
            return {
              ...column,
              AMOUNT_PAID: Number(e.value),
            };
          } else {
            return column;
          }
        })
      );
    }
  };
  const deleteVoucher = (voucher) => {
    setAlertTwoButtonModal({
      ...alertTwoButtonModal,
      status: false,
    });
    const data = {
      table_prefix: prefix,
      VOUCHER_NO: voucher,
    };
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        `/api/voucherRoute/deleteRowVoucherGrid`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (!response.data.error) {
          setList((p) =>
            p.map((p) => {
              if (p.VOUCHER_NO === voucher) {
                return {
                  ...p,
                  AMOUNT_PAID: 0,
                };
              } else {
                return {
                  ...p,
                };
              }
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    getCollection();
  }, []);

  useEffect(() => {
    if (searchCode) {
      const regex = /\d+/;

      const searchItems = list.filter((item) => {
        if (regex.test(searchCode.substring(0, 1))) {
          return item.ACCOUNT_NO.toString().includes(searchCode);
        } else {
          return item.NAME.toLowerCase().includes(searchCode.toLowerCase());
        }
      });
      SetFilterdList(searchItems);
    }

    if (!searchCode) {
      SetFilterdList([]);
    }
  }, [searchCode]);

  const handleSave = () => {
    if (voucherList[0]) {
      dispatch(setSpinnerLoading('Saving'));

      const data = {
        table_prefix: prefix,
        cart_item: voucherList,
      };
      console.log(data);
      axios
        .post(`/api/voucherRoute/saveVoucher`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          console.log(response);
          if (response.status === 200) {
            dispatch(
              setAlertWithTitle({
                title: 'success',
                msg: 'Item saved successfully',
                lottie: 'success',
              })
            );
            setDebit(
              voucherList.reduce((acc, curr) => acc + curr.AMOUNT_PAID, 0)
            );
            setStatus(false);
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Soemthing went wrong',
                lottie: 'reject',
              })
            );
          }
        })
        .catch(function (error) {
          if (error?.response.data?.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Soemthing went wrong',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      setDebit(voucherList.reduce((acc, curr) => acc + curr.AMOUNT_PAID, 0));
      setStatus(false);
    }
  };

  useEffect(() => {
    let v = [];
    list.map((item, i) => {
      if (item.AMOUNT_PAID > 0) {
        const l = {
          VOUCHER_NO: item.VOUCHER_NO ? item.VOUCHER_NO : '',
          DATE: moment(date).format('YYYY-MM-DD'),
          PAY_MODE: 'BTC',
          CARD_NO: '',
          BANK_NAME: '',
          EPAYMENT_BANK: '',
          ACCOUNT_NO: item.ACCOUNT_NO,
          NAME: item.NAME,
          ADDRESS: item.ADDRESS ? item.ADDRESS : '',
          MOBILE: item.MOBILE ? item.MOBILE : '',
          AMOUNT_RECEIVED: item.AMOUNT_RECEIVED ? item.AMOUNT_RECEIVED : 0,
          AMOUNT_PAID: item.AMOUNT_PAID ? item.AMOUNT_PAID : 0,
          AMOUNT_DESC: 'BTC',
          INVNO: '0',
          SID: stationId,
          SNAME: stationName,
          LATITUDE: '38.8951',
          LONGITUDE: '-77.0364',
          APPROVED: 'NO',
        };
        v.push(l);
      }
    });

    setVoucherList(v);
  }, [list]);

  return (
    <div className="h-full p-2 ">
      <div
        style={{
          border: '1px solid #D7D5D5',
          fontSize: '14px',
        }}
        className="flex p-2  rounded-sm"
      >
        <i className="bi bi-search pr-2"></i>
        <input
          type={'text'}
          placeholder="Search"
          value={searchCode}
          onChange={(t) => {
            setSearchCode(t.target.value);
          }}
          className="w-full outline-0 "
        />
      </div>
      <div
        style={{
          height: window.innerHeight * 0.6,
          width: columns[0].width + columns[1].width + columns[2].width + 20,
        }}
        className="mt-1"
      >
        {' '}
        <GeniusDatagrid
          rowID={(row) => row.ACCOUNT_NO}
          columns={columns}
          list={filterdList[0] ? filterdList : list}
          editCell={cellEdit}
          showPagination={true}
          onDoubleClick={(e) => {
            if (e.field === 'AMOUNT_PAID') {
              dispatch(setnumericKeyboardType(e.field));
              dispatch(
                setIssueStockItem({
                  title: e.row.NAME,
                  id: e.row.ACCOUNT_NO,
                  value: e.row.AMOUNT_PAID,
                })
              );
              dispatch(setnumericKeyboardStatus(true));
            }
          }}
        />
      </div>
      <DebitFooter
        rows={list}
        columns={columns}
        handleSave={handleSave}
        handleUndo={() => console.log('UNDO')}
      />

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ModNumericKeyboard onChange={cellEdit} mode={'dark'} />
    </div>
  );
};

export default DebitList;
