import axios from 'axios';
import {
  setAlertWithTitle,
  setSessionExpired,
} from '../../../utility/Redux/modal';

export const GetSectionName = async (
  setSectionList,
  prefix,
  token,
  dispatch
) => {
  try {
    await axios
      .post(
        '/api/issueMasterRoute/listSectionMaster',
        {
          table_prefix: prefix,
          SECTION_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setSectionList(p.data.response);
      })
      .catch((error) => {
        console.log(error);

        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  } catch (error) {
    dispatch(
      setAlertWithTitle({
        title: 'Error 101',
        msg: 'Something Went Wrong!',
      })
    );
  }
};
