import { Close } from '@mui/icons-material';

import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  BackButton,
  SaveButton,
} from '../../../../../utility/component/buttons';
import { FormatNumber2 } from '../../../../../utility/component/tools';
import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setBillSattalmentModalStatus,
  setConfirmSaveBillstatus,
  setSessionExpired,
} from '../../../../../utility/Redux/modal';

import { selectToken } from '../../../../../utility/Redux/security';

import {
  SelectBillingDiscount,
  SelectBillSettlementBalance,
  SelectBillSettlementDetails,
  SelectOrdersList,
  setBillSettlementBalnce,
  setBillSettlementDetails,
} from '../../../../../utility/Redux/tableOrder';

import { styles5 } from '../../../../../utility/component/styles/react-select';

import SaveALertBillSettlement from '../../../../../utility/component/modals/confirmSaveBill';

import {
  selectAccountDetails,
  selectPrefix,
  selectWidth,
} from '../../../../../utility/Redux/profile';
import axios from 'axios';
import { TextField } from '@mui/material';
import { useFocus } from '../../../../../utility/hooks/hooks';
import ModGeniusKeyboard from '../../../../../utility/component/keyboard';
import ModalEPayment from './ModEpayment';

const SettleBill = () => {
  const billingItemsList = useSelector(SelectOrdersList);
  const [ePayementRef, setFocusEpayment] = useFocus();
  const selectDiscountedAmount = useSelector(SelectBillingDiscount);
  const accDetails = useSelector(selectAccountDetails);
  const BillSettelmentBalance = useSelector(SelectBillSettlementBalance);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [prevBal, setPrevBal] = useState(0);
  const [collectionPaymode, setCollectionPaymode] = useState('CASH');
  const [ePaymentStatus, setEPayemntStatus] = useState(false);
  const prefix = useSelector(selectPrefix);
  const width = useSelector(selectWidth);
  const totalAmount = Math.round(
    billingItemsList?.reduce((acc, curr) => acc + curr.AMOUNT, 0) -
      selectDiscountedAmount
  );

  const billSettelmentDetails = useSelector(SelectBillSettlementDetails);

  const getPrevBalance = () => {
    axios
      .post(
        '/api/reports/partyBalance',
        {
          table_prefix: prefix,
          ACCOUNT_NO: accDetails.accNumber,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const list = res.data.response;
          const prevBal =
            list[0].OP_DEBIT +
            list[0].DEBIT -
            (list[0].OP_CREDIT + list[0].CREDIT);

          setPrevBal(prevBal);
        }
        // const prevBal = res.data.response[0].BTC_AMOUNT - res.data.response[0].AMOUNT_RECEIVED
        // setPrevBal(prevBal)
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  useEffect(() => {
    if (
      Number(totalAmount) -
        (Number(billSettelmentDetails.cash) +
          Number(billSettelmentDetails.card) +
          Number(billSettelmentDetails.btc) +
          Number(billSettelmentDetails.coupanAmount) +
          Number(billSettelmentDetails.epaymentAmount)) >=
      0
    ) {
      dispatch(
        setBillSettlementBalnce(
          Number(totalAmount) -
            (Number(billSettelmentDetails.cash) +
              Number(billSettelmentDetails.card) +
              Number(billSettelmentDetails.btc) +
              Number(billSettelmentDetails.coupanAmount) +
              Number(billSettelmentDetails.epaymentAmount))
        )
      );
    }
  }, [billSettelmentDetails]);

  const confirmSave = () => {
    if (BillSettelmentBalance === 0) {
      if (
        (billSettelmentDetails.card && !billSettelmentDetails.cardNumber) ||
        (billSettelmentDetails.coupanAmount &&
          !billSettelmentDetails.coupanNumber) ||
        (billSettelmentDetails.epaymentAmount &&
          !billSettelmentDetails.epayment)
      ) {
        dispatch(
          setAlertMsg(
            'Please ensure that you have filled in both the payment amount and payment method fields to complete your transaction'
          )
        );
        dispatch(setALertStatus(true));
      } else {
        if (
          (!billSettelmentDetails.card && billSettelmentDetails.cardNumber) ||
          (!billSettelmentDetails.coupanAmount &&
            billSettelmentDetails.coupanNumber) ||
          (!billSettelmentDetails.epaymentAmount &&
            billSettelmentDetails.epayment)
        ) {
          dispatch(
            setAlertMsg(
              'Please fill in both fields of payment to complete your transaction.'
            )
          );
          dispatch(setALertStatus(true));
        } else {
          if (BillSettelmentBalance === 0) {
            dispatch(setConfirmSaveBillstatus(true));
          }
        }
      }
    } else {
      dispatch(setAlertMsg('Your bill settlement has not been cleared.'));
      dispatch(setALertStatus('true'));
    }
  };

  useEffect(() => {
    getPrevBalance();
  }, []);

  const BackHandle = () => {
    dispatch(
      setBillSettlementDetails({
        cash: '',
        card: '',
        cardNumber: '',
        coupanAmount: '',
        coupanNumber: '',
        epayment: '',
        epaymentAmount: '',
        btc: '',
        btcName: '',
        disc: '',
        btcMobile: '',
        btcAccNumber: '0',
        btcAddress: ' ',
        TOTAL_COLLECTION: 0,
      })
    );
    dispatch(setBillSattalmentModalStatus(false));
  };

  return (
    <div className="p-4 text-sm ">
      <div className=" flex flex-col w-full">
        <div className="w-full flex ">
          <div
            style={{
              width: width < 1000 ? 220 : 220,
            }}
            className="border flex rounded-sm  "
          >
            <div className=" text-[#f44336] border-r font-semibold ml-2 pt-2 pr-2 pb-2">
              Previous Balance
            </div>
            <div className="text-[#f44336] font-semibold p-2">
              {prevBal.toFixed(2)}
            </div>
          </div>

          <div
            style={{
              width: width < 1000 ? 220 : 220,
            }}
            className=" flex border rounded-sm  ml-2 "
          >
            <div className=" border-r text-[#f44336] font-semibold p-2 ">
              Bill Amount
            </div>
            <div className="text-[#f44336] font-semibold p-2">
              {' '}
              {FormatNumber2(totalAmount)}
            </div>
          </div>

          <div
            style={{
              width: width < 1000 ? 220 : 220,
            }}
            className=" flex border bg-[#ecf7c5] rounded-sm  ml-2 "
          >
            <div className=" border-r text-[#0e2e3b]  font-semibold p-2">
              Balance
            </div>
            <div className="text-[#073308] font-semibold p-2">
              {' '}
              {FormatNumber2(BillSettelmentBalance)}
            </div>
          </div>
        </div>
      </div>

      <div className="w-full flex mt-5 ">
        <div
          style={{
            width: width < 1000 ? 220 : 220,
          }}
        >
          <TextField
            id="outlined-controlled"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            type="number"
            size="small"
            label="Cash Amount"
            onChange={(t) => {
              dispatch(
                setBillSettlementDetails({
                  ...billSettelmentDetails,
                  cash: t.target.value,
                })
              );

              if (
                Number(totalAmount) -
                  (Number(t.target.value) +
                    Number(billSettelmentDetails.card) +
                    Number(billSettelmentDetails.btc) +
                    Number(billSettelmentDetails.coupanAmount) +
                    Number(billSettelmentDetails.epaymentAmount)) <
                0
              ) {
                dispatch(setAlertMsg('Invaild amount'));
                dispatch(setALertStatus(true));
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    cash: '',
                  })
                );
              }
            }}
            value={billSettelmentDetails.cash}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #ebedf0', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>

        <div className="flex items-center ml-2 ">
          <div
            style={{
              fontSize: '14px',

              // Adjust the font size as per your preference

              // Change to your desired color on
              width: width < 1000 ? 220 : 220,
            }}
            className=" border py-[7px] rounded-sm flex items-center "
          >
            <div className="pl-4 text-[#6a7376] ">ePayment Type</div>
            <select
              value={billSettelmentDetails.epayment}
              style={{
                outline: 'none',
                color: '#6a7376',
              }}
              onChange={(e) => {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    epayment: e.value,
                  })
                );
              }}
            >
              {[
                {
                  name: 'Select',
                  value: '',
                },

                {
                  name: 'Paytm',
                  value: 'Paytm',
                },
                {
                  name: 'PhonePe',
                  value: 'PhonePe',
                },
                {
                  name: 'GPAY',
                  value: 'GPAY',
                },
                {
                  name: 'Other UPI',
                  value: 'Other UPI',
                },
              ].map((e, i) => {
                return (
                  <option key={i} value={e.value}>
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
      </div>

      <div className="w-full flex mt-5">
        <div className=" flex-1">
          <TextField
            id="outlined-controlled"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            type="number"
            size="small"
            label="eAmount"
            onChange={(t) => {
              dispatch(
                setBillSettlementDetails({
                  ...billSettelmentDetails,
                  epaymentAmount: t.target.value,
                })
              );
              if (
                Number(totalAmount) -
                  (Number(t.target.value) +
                    Number(billSettelmentDetails.card) +
                    Number(billSettelmentDetails.btc) +
                    Number(billSettelmentDetails.cash) +
                    Number(billSettelmentDetails.coupanAmount)) <
                0
              ) {
                dispatch(setAlertMsg('Invaild amount'));
                dispatch(setALertStatus(true));
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    epaymentAmount: '',
                  })
                );
              }
            }}
            value={billSettelmentDetails.epaymentAmount}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #ebedf0', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>
        <div
          style={{
            width: width < 1000 ? 220 : 220,
          }}
          className="ml-2"
        >
          <TextField
            id="outlined-controlled"
            type="number"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            size="small"
            label="Card Amount"
            onChange={(t) => {
              dispatch(
                setBillSettlementDetails({
                  ...billSettelmentDetails,
                  card: t.target.value,
                })
              );
              if (
                Number(totalAmount) -
                  (Number(t.target.value) +
                    Number(billSettelmentDetails.cash) +
                    Number(billSettelmentDetails.btc) +
                    Number(billSettelmentDetails.coupanAmount) +
                    Number(billSettelmentDetails.epaymentAmount)) <
                0
              ) {
                dispatch(setAlertMsg('Invaild amount'));
                dispatch(setALertStatus(true));
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    card: '',
                  })
                );
              }
            }}
            value={billSettelmentDetails.card}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #ebedf0', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>

        <div
          style={{
            width: width < 1000 ? 220 : 220,
          }}
          className="ml-2 "
        >
          <TextField
            id="outlined-controlled"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            size="small"
            label="BTC Amount"
            type="number"
            onChange={(t) => {
              // don't forget to change mobile bill settelemet value if changes made
              if (t.target.value) {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    btcName: accDetails.accName,
                    btc: t.target.value,
                  })
                );
              } else {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    btcName: '',
                    btc: t.target.value,
                  })
                );
              }

              if (
                Number(totalAmount) -
                  (Number(t.target.value) +
                    Number(billSettelmentDetails.card) +
                    Number(billSettelmentDetails.coupanAmount) +
                    Number(billSettelmentDetails.cash) +
                    Number(billSettelmentDetails.epaymentAmount)) <
                0
              ) {
                dispatch(setAlertMsg('Invaild amount'));
                dispatch(setALertStatus(true));
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    btc: '',
                  })
                );
              }
            }}
            value={billSettelmentDetails.btc}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #ebedf0', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>
      </div>

      <div className="w-full flex mt-5">
        <div
          style={{
            width: width < 1000 ? 220 : 220,
          }}
        >
          <TextField
            id="outlined-controlled"
            type="number"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            size="small"
            label="Card No."
            onChange={(t) =>
              dispatch(
                setBillSettlementDetails({
                  ...billSettelmentDetails,
                  cardNumber: t.target.value,
                })
              )
            }
            value={billSettelmentDetails.cardNumber}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #ebedf0', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>

        <div style={{}} className="ml-2 flex-1 ">
          <TextField
            id="outlined-controlled"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            size="small"
            label="BTC Name"
            readOnly
            value={billSettelmentDetails.btcName}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #ebedf0', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>
      </div>

      <div className="w-full flex mt-5">
        <div className="flex-1">
          <TextField
            id="outlined-controlled"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            type="text"
            size="small"
            label="Remarks"
            onChange={(t) => {
              dispatch(
                setBillSettlementDetails({
                  ...billSettelmentDetails,
                  disc: t.target.value,
                })
              );
            }}
            value={billSettelmentDetails.disc}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ebedf0', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #ebedf0', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>
      </div>

      <div className="flex justify-between mt-3">
        <div className="flex">
          <div
            style={{
              width: width < 1000 ? 220 : 220,
            }}
          >
            <TextField
              id="outlined-controlled"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              type="number"
              size="small"
              label="Collection"
              onChange={(t) => {
                if (prevBal && prevBal > 0) {
                  if (t.target.value <= prevBal) {
                    dispatch(
                      setBillSettlementDetails({
                        ...billSettelmentDetails,
                        TOTAL_COLLECTION:
                          billSettelmentDetails.TOTAL_COLLECTION === 0
                            ? t.target.value.replace(0, '')
                            : t.target.value,
                      })
                    );
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Invalid amount',
                      })
                    );
                  }
                }
              }}
              value={
                billSettelmentDetails.TOTAL_COLLECTION === 0
                  ? ''
                  : billSettelmentDetails.TOTAL_COLLECTION
              }
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid black', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  fontWeight: 'bold',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>

          <div className="flex items-center ml-2 ">
            <div
              style={{
                fontSize: '14px',

                // Adjust the font size as per your preference

                // Change to your desired color on
                width: width < 1000 ? 220 : 220,
                border: '1px solid black',
              }}
              className="  py-[7px] rounded-[5px] flex items-center "
            >
              <div className="pl-4 text-[#6a7376] ">Pay Mode</div>
              <select
                value={collectionPaymode}
                style={{
                  outline: 'none',
                  color: '#6a7376',
                }}
                onChange={(e) => {
                  setCollectionPaymode(e.target.value);
                }}
              >
                {[
                  {
                    name: 'CASH',
                    value: 'CASH',
                  },
                  {
                    name: 'CARD',
                    value: 'CARD',
                  },
                  {
                    name: 'CHEQUE',
                    value: 'CHEQUE',
                  },
                  {
                    name: 'EPAYMENT',
                    value: 'EPAYMENT',
                  },
                ].map((e, i) => {
                  return (
                    <option key={i} value={e.value}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div>
          <SaveButton onClick={confirmSave} />
          <BackButton onClick={BackHandle} />
        </div>
      </div>
      <SaveALertBillSettlement collectionPaymode={collectionPaymode} />
      <ModGeniusKeyboard />
    </div>
  );
};

export default SettleBill;
