import React, { useEffect, useRef } from 'react';

import moment from 'moment';
import { useState } from 'react';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setreportsFromDate,
  setreportsTodate,
} from '../../utility/Redux/profile';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../utility/Redux/security';

import {
  selectCollectionAccountDetails,
  setCollectionAccountDetails,
  setItemMasterHelpStatus,
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
  setSessionExpired,
  setloading,
} from '../../utility/Redux/modal';

import axios from 'axios';
import { FormatNumber2 } from '../../utility/component/tools';

import { setStationID } from '../../utility/Redux/profile';
import { setStationName } from '../../utility/Redux/profile';
import ModListStationMaster from '../../utility/component/modals/modListStationMaster';
import { FromToDate } from '../../utility/component/fromToDate';
import ItemMasterHelp from '../../master/iteemMasterHelp';
import GeniusDatagrid from '../../utility/component/datagrid/geniusDatagrid';
import GeniusReportsDate from '../../utility/component/reportDate';
import { Skeleton } from '@mui/material';
import { ProfitFooter } from '../../utility/component/datagrid/profitFooter';
import ModPartyMasterHelp from '../../master/partyMasterHelp';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase';
import dataToLocal from '../../utility/functions/summaryToLocal';
import IsLocalData from '../../utility/functions/ceckLocalData';

const ProfitReports = () => {
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const ReduxStation_id = useSelector(selectStationId);
  const ReduxStationName = useSelector(selectStationName);
  const dispatch = useDispatch();

  const userType = useSelector(selectUserType);
  const firstDate = useSelector(selectreportFromDate);
  const SecondDate = useSelector(selectreportToDate);
  const [dataLoading, setDataLoading] = useState(false);
  const [list, setList] = useState([]);
  const [itemCode, setItemCode] = useState('');
  const accDetails = useSelector(selectCollectionAccountDetails);

  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const width = useSelector(selectWidth);
  const getItemWiseReport = () => {
    setDataLoading(true);
    deleteWholeDatabase('Profit_report');

    const data = {
      table_prefix: prefix,
      from_date: firstDate,
      to_date: SecondDate,
      SID: ReduxStation_id,
      ICODE: itemCode ? itemCode : '',
      ACCOUNT_NO: accDetails.accNumber ? accDetails.accNumber : '',
    };

    
    axios
      .post(`/api/reports/profitReport`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res.data.response);
        if (!res.data.error) {
          const data = res.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
            });
          }
          if (rowList[0]) {
            dataToLocal('Profit_report', rowList, [
              {
                from_date: firstDate,
                to_date: SecondDate,
                SID: ReduxStation_id,
                ICODE: itemCode ? itemCode : '',
                ACCOUNT_NO: accDetails.accNumber ? accDetails.accNumber : '',
                accName: accDetails.accName,
                accAddress: accDetails.accAddress,
                accMobile: accDetails.accMobile,
              },
            ]);
          }
          setList(rowList);
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
        setDataLoading(false);
      });
    // };
  };

  const okButton = () => {
    getItemWiseReport();
  };

  const columns = [
    {
      field: 'DATE',
      accessor: 'DATE',
      type: 'date',
      type: 'string',
      width: width <= 1280 ? 100 : 130,

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      valueFormatter: (params) => {
        if (params.value) {
          return moment(params.value).format('DD-MM-YYYY');
        } else {
          return '';
        }
      },

      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Date'}</div>,
    },

    {
      field: 'ITEMNAME',
      accessor: 'ITEMNAME',
      type: 'string',
      width: width <= 1280 ? 190 : width < 1650 ? 190 : 200,

      headerAlign: 'center',
      align: 'left',

      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Item Name'}
        </div>
      ),
    },

    {
      field: 'ACCOUNT_NO',
      accessor: 'ACCOUNT_NO',
      type: 'string',
      width: width <= 1280 ? 80 : 120,

      headerAlign: 'center',
      align: 'left',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4 flex text-center"
        >
          {'Acc. No.'}
        </div>
      ),
    },

    {
      field: 'NAME',
      accessor: 'NAME',
      type: 'string',
      width: width <= 1280 ? 180 : width < 1650 ? 250 : 330,

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Party Name'}</div>
      ),
    },
    {
      field: 'SID',
      accessor: 'SID',
      type: 'string',
      width: width <= 1280 ? 130 : 140,

      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'SID'}</div>,
    },
    {
      field: 'ISSUE_RATE',
      accessor: 'ISSUE_RATE',
      type: 'string',
      width: width <= 1280 ? 100 : 100,

      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Issue Rate'}</div>
      ),
    },
    {
      field: 'ISSUE_AMOUNT',
      accessor: 'ISSUE_AMOUNT',
      type: 'string',
      width: width <= 1280 ? 130 : 140,

      align: 'right',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Issue Amount'}</div>
      ),
    },
    {
      field: 'SALE_QNTY',
      accessor: 'SALE_QNTY',
      type: 'string',
      width: width <= 1280 ? 130 : 140,

      align: 'right',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Sales Qnty'}</div>
      ),
    },
    {
      field: 'SALE_RATE',
      accessor: 'SALE_RATE',
      type: 'string',
      width: width <= 1280 ? 100 : 100,

      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Sale Rate'}</div>
      ),
    },
    {
      field: 'SALE_AMOUNT',
      accessor: 'SALE_AMOUNT',
      type: 'string',
      width: width <= 1280 ? 130 : 140,

      align: 'right',
      valueFormatter: (params) => {
        if (params.value) {
          return FormatNumber2(params.value);
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Sale Amount'}</div>
      ),
    },

    {
      field: 'PROFIT',
      accessor: 'PROFIT',
      type: 'string',
      width: width <= 1280 ? 130 : 140,

      align: 'right',
      renderCell: (p) => {
        if (parseFloat(p.value.replace(/,/g, '')) !== 0) {
          return p.value;
        } else {
          return '';
        }
      },
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Profit'}</div>,
    },
  ];

  useEffect(() => {
    const elseFunc = () => {
      dispatch(
        setCollectionAccountDetails({
          accName: '',
          accAddress: '',
          accMobile: '',
          accNumber: '',
          stationId: '',
        })
      );

      if (userType !== 'USER') {
        dispatch(setStationID(''));
        dispatch(setStationName(''));
      }
    };
    const callback = (details, data) => {
      setList(data);
      dispatch(setStationID(details[0].SID));
      dispatch(
        setreportsFromDate(moment(details[0].from_date).format('YYYY-MM-DD'))
      );
      dispatch(
        setreportsTodate(moment(details[0].to_date).format('YYYY-MM-DD'))
      );
      dispatch(setItemCode(details[0].ICODE));
      dispatch(
        setCollectionAccountDetails({
          accName: details[0].accName,
          accAddress: details[0].accAddress,
          accMobile: details[0].accMobile,
          accNumber: details[0].ACCOUNT_NO,
          stationId: '',
        })
      );
    };
    IsLocalData('Profit_report', callback, elseFunc);
  }, []);
  const c = columns;

  return (
    <div className="p-1 w-full h-full items-center">
      {window.innerWidth <= 500 ? (
        <>
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="p-1 py-3 rounded-t-sm  flex items-center "
          >
            <div className="px-1 ">From</div>

            <GeniusReportsDate
              date={firstDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="px-1 pl-2 ">To</div>
            <GeniusReportsDate
              date={SecondDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>
          <div style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}>
            {userType === 'USER' ? null : (
              <div className="flex  text-[#4B4545] p-2  ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  style={{ fontSize: 13 }}
                  className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                >
                  <div className="flex items-center ">
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                    >
                      Station ID
                    </span>
                  </div>

                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="flex text-[#a6a2a2]  pl-2 ml-3  "
                  >
                    <input
                      size={'small'}
                      placeholder="All"
                      value={ReduxStation_id ? ReduxStation_id : 'All'}
                      style={{
                        // width: '20%',
                        fontSize: 13,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: 'transparent',
                      }}
                      readOnly
                      className=" outline-0 w-6"
                    />

                    <div
                      style={{
                        borderLeft: '1px dotted #a6a2a2 ',
                      }}
                      className="px-2 p-1 w-52 whitespace-nowrap print:hidden"
                    >
                      {ReduxStationName ? `${ReduxStationName}` : null}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {
              <div className="flex   text-[#4B4545]  border-t border-[#b5b3b3] py-2 px-2">
                <div
                  style={{ fontSize: 13 }}
                  className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                >
                  <div
                    onClick={() => dispatch(setItemMasterHelpStatus(true))}
                    className="flex items-center "
                  >
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                    >
                      Item Code
                    </span>
                  </div>
                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="px-2 p-1 ml-3 w-auto text-[#a6a2a2] whitespace-nowrap print:hidden  flex justify-center"
                  >
                    <div>{itemCode ? itemCode : 'All'}</div>
                  </div>
                </div>
                <div>
                  <button
                    onClick={okButton}
                    className="flex items-center rounded-sm ml-2"
                    style={{
                      backgroundColor: '#c1c1c1',
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingTop: 4,
                      paddingBottom: 4,
                      color: 'black',
                    }}
                  >
                    <div className="flex items-center text-sm">Ok</div>
                  </button>
                </div>
              </div>
            }
          </div>
        </>
      ) : (
        <FromToDate
          submit={okButton}
          itemCode={{
            itemCode: itemCode,
            setItemCode: setItemCode,
            status: true,
          }}
          partyName={true}
          reset={() => {
            if (userType === 'USER') {
              dispatch(
                setCollectionAccountDetails({
                  accName: '',
                  accAddress: '',
                  accMobile: '',
                  accNumber: '',
                  stationId: '',
                })
              );
              setItemCode('');
            } else {
              setItemCode('');
              dispatch(setStationID(''));
              dispatch(setStationName(''));
              dispatch(
                setCollectionAccountDetails({
                  accName: '',
                  accAddress: '',
                  accMobile: '',
                  accNumber: '',
                  stationId: '',
                })
              );
            }
          }}
        />
      )}

      <div
        style={{
          marginTop: 10,
          height: `calc(100% - 200px)`,
        }}
      >
        {dataLoading ? (
          <div className="w-full ">
            <div className="flex w-full ">
              {c.map((e, k) => {
                return (
                  <Skeleton
                    animation={'wave'}
                    key={k}
                    width={e.width ? e.width : 100}
                    height={60}
                    style={{
                      marginLeft: 3,
                    }}
                  />
                );
              })}
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, f) => {
                return (
                  <div key={f} className="flex ">
                    {c.map((e, kf) => {
                      return (
                        <Skeleton
                          key={kf}
                          width={e.width ? e.width : 100}
                          height={50}
                          style={{
                            marginLeft: 3,
                          }}
                        >
                          {' '}
                        </Skeleton>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <GeniusDatagrid
            list={list}
            dataGridHeader={true}
            columns={columns}
            rowID={(r) => r.id}
            showPagination={true}
          />
        )}
      </div>
      {!dataLoading && (
        <div className="w-full flex overflow-x-scroll">
          <ProfitFooter rows={list} columns={columns} />
        </div>
      )}

      <ModPartyMasterHelp
        SID={ReduxStation_id}
        onClose={() => {
          dispatch(setModPartyMasterHelpStatus(false));
        }}
        onRowClick={(e) => {
          setList([]);
          dispatch(
            setCollectionAccountDetails({
              accName: e.row.NAME,
              accAddress: e.row.ADDRESS,
              accMobile: e.row.MOBILE,
              accNumber: e.row.ACCOUNT_NO,
              stationId: e.row.SID,
            })
          );
          dispatch(setStationID(e.row.SID));
          dispatch(setStationName(e.row.SNAME));
          dispatch(setModPartyMasterHelpStatus(false));
        }}
      />
      <ModListStationMaster
        onRowClick={(p) => {
          setList([]);

          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
      <ItemMasterHelp
        onRowClick={(p) => {
          setList([]);

          setItemCode(p.row.ICODE);
          dispatch(setItemMasterHelpStatus(false));
        }}
        setItemCode={setItemCode}
      />
    </div>
  );
};

export default ProfitReports;
