import axios from 'axios';
import moment from 'moment';
import { setAlertWithTitle, setSpinnerLoading } from '../utility/Redux/modal';
import deleteWholeDatabase from '../utility/functions/deleteWholeDatabase';
import dataToLocal from '../utility/functions/summaryToLocal';

const GetStationReport = (
  prefix,
  dispatch,
  token,
  setList,
  fromDate,
  toDate
) => {
  dispatch(setSpinnerLoading('Loading'));
  deleteWholeDatabase('All_Stations_Balance_Report');

  const data = {
    table_prefix: prefix,
    FDATE: moment(fromDate).format('YYYY-MM-DD'),
    TDATE: moment(toDate).format('YYYY-MM-DD'),
  };
  axios
    .post(
      `/api/issueStockRoute/allStationBalanceReport`,
      data,

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async function (response) {
      if (!response.data.error) {
        const list = response.data.response;
        setList(response.data.response);
        if (list[0]) {
          dataToLocal('All_Stations_Balance_Report', list, [
            {
              FDATE: moment(fromDate).format('YYYY-MM-DD'),
              TDATE: moment(toDate).format('YYYY-MM-DD'),
              ICODE: 1,
            },
          ]);
        }
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error ',
            msg: 'Something Went Wrong!',
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Something Went Wrong!',
        })
      );
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
const GetStationDayEndReport = (
  prefix,
  dispatch,
  token,
  setList,
  fromDate,
  toDate,
  SID,
  ReduxStationName
) => {
  dispatch(setSpinnerLoading('Loading'));
  deleteWholeDatabase('Station_day_end_reports');

  const data = {
    table_prefix: prefix,
    FDATE: moment(fromDate).format('YYYY-MM-DD'),
    TDATE: moment(toDate).format('YYYY-MM-DD'),
    SID: SID,
  };
  axios
    .post(
      `/api/issueStockRoute/stationDayEndReport`,
      data,

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async function (response) {
      if (!response.data.error) {
        const record = response.data.response;
        let list = [];
        if (record[0]) {
          for (let i = 0; i < record.length; i++) {
            list.push({
              ...record[i],
              NET_DEBIT:
                record[i].DEBIT > record[i].COLLECTION
                  ? record[i].DEBIT - record[i].COLLECTION
                  : 0,
              NET_COLLECTION:
                record[i].COLLECTION > record[i].DEBIT
                  ? record[i].COLLECTION - record[i].DEBIT
                  : 0,
            });
          }
        }
        if (list[0]) {
          dataToLocal('Station_day_end_reports', list, [
            {
              FDATE: moment(fromDate).format('YYYY-MM-DD'),
              TDATE: moment(toDate).format('YYYY-MM-DD'),
              ICODE: 1,
              SID: SID,
              SNAME: ReduxStationName,
            },
          ]);
        }
        console.log(list);

        setList(list);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error ',
            msg: 'Something Went Wrong!',
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Something Went Wrong!',
        })
      );
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

const GetDifferenceReport = (
  prefix,
  dispatch,
  token,
  setList,
  fromDate,
  toDate,
  SID,
  ReduxStationName
) => {
  dispatch(setSpinnerLoading('Loading'));
  deleteWholeDatabase('Difference_report');
  const data = {
    table_prefix: prefix,
    FDATE: moment(fromDate).format('YYYY-MM-DD'),
    TDATE: moment(toDate).format('YYYY-MM-DD'),
    SID: SID,
  };
  axios
    .post(
      `/api/dayEndSumRoute/differenceReport`,
      data,

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(async function (response) {
      if (!response.data.error) {
        const record = response.data.response;
        let list = [];
        if (record[0]) {
          for (let i = 0; i < record.length; i++) {
            list.push({
              ...record[i],
              INVNO: i,
            });
          }
        }
        if (list[0]) {
          dataToLocal('Difference_report', list, [
            {
              FDATE: moment(fromDate).format('YYYY-MM-DD'),
              TDATE: moment(toDate).format('YYYY-MM-DD'),
              SID: SID,
              ICODE: 1,
              SNAME: ReduxStationName,
            },
          ]);
        }

        setList(list);
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error ',
            msg: 'Something Went Wrong!',
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);
      dispatch(
        setAlertWithTitle({
          title: 'Error',
          msg: 'Something Went Wrong!',
        })
      );
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
export { GetStationReport, GetStationDayEndReport, GetDifferenceReport };
