import { useDispatch, useSelector } from 'react-redux';
import {
  SelectSectionMasterHelpStatus,
  setSectionMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import { useState } from 'react';
import { selectToken } from '../../../utility/Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { selectPrefix, selectWidth } from '../../../utility/Redux/profile';
import MasterHelp from './masterHelp';

const SectionMasterHelp = ({ onRowClick }) => {
  const dispatch = useDispatch();

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const status = useSelector(SelectSectionMasterHelpStatus);
  const [rows, setRows] = useState([]);
  const token = useSelector(selectToken);
  const [fullList, setFullList] = useState([]);
  const windowWidth = useSelector(selectWidth);
  const columns = [
    {
      width:
        windowWidth <= 400
          ? 70
          : windowWidth <= 940
          ? 70
          : windowWidth <= 1156
          ? 80
          : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      field: 'SECTION_NAME',
      renderHeader: (params) => <div>Section Name</div>,
      width:
        windowWidth <= 400
          ? 200
          : windowWidth <= 940
          ? 250
          : windowWidth <= 1156
          ? 300
          : 300,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.CODE.toString().includes(searchQuery);
        } else {
          return item.SECTION_NAME.toLowerCase().includes(
            searchQuery.toLowerCase()
          );
        }
      });

      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);
  const prefix = useSelector(selectPrefix);
  const getData = async () => {
    dispatch(setSpinnerLoading('Loading section'));
    try {
      axios
        .post(
          '/api/issueMasterRoute/listSectionMaster',
          {
            table_prefix: prefix,
            SECTION_NAME: '',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((p) => {
          setRows(p.data.response);
          setFullList(p.data.response);
        })
        .catch((error) => {
          console.log(error.response.data.name);
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  const handleClose = () => {
    dispatch(setSectionMasterHelpStatus(false));
  };

  return (
    <div>
      <MasterHelp
        status={status}
        style={style}
        onClose={() => {
          handleClose();
          setRows([]);
        }}
        title={'Section Master - Help'}
        columns={columns}
        list={rows}
        rowId={(r) => r.CODE}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={(p) => {
          onRowClick(p);
          setRows([]);
        }}
      />
    </div>
  );
};

export default SectionMasterHelp;
