import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModListStationMaster from '../../../utility/component/modals/modListStationMaster';
import {
  setListStationMasterModalStatus,
  setSessionExpired,
} from '../../Redux/modal';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserCode,
  selectUserType,
  selectreportFromDate,
  selectreportToDate,
  setStationID,
  setStationName,
  setreportsFromDate,
  setreportsTodate,
} from '../../Redux/profile';
import moment from 'moment';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import Lottie from 'lottie-react';
import spinner from '../../../utility/json/Spinner.json';
import { Mobilecolumns, columns } from '../../columns/dayendSummary';
import { selectToken } from '../../Redux/security';
import axios from 'axios';
import { useEffect } from 'react';
import { DayEndFooter } from '../datagrid/fotrDayEndSummary';
import { FormatNumber2 } from '../tools';
import { DayEndHeader } from '../datagrid/headerDayEndSummary';
import { FromToDate } from '../fromToDate';
import { setFirstDate, setSecondDate } from '../../Redux/refresh';
import { DayEndHeaderMobile } from '../datagrid/headerDayendMobile';
import GeniusSelectDate from '../selectDate';
import GeniusReportsDate from '../reportDate';
import { Skeleton } from '@mui/material';
import HandleIndexedDB from '../../functions/indexDbDatabase';
import deleteWholeDatabase from '../../functions/deleteWholeDatabase';

const width = window.innerWidth / 100;

const DayendSummary = () => {
  const token = useSelector(selectToken);
  const ReduxStation_id = useSelector(selectStationId);
  const firstDate = useSelector(selectreportFromDate);
  const SecondDate = useSelector(selectreportToDate);
  const userType = useSelector(selectUserType);
  const [dataLoading, setdataloading] = useState(false);
  const [list, setList] = useState([]);
  const prefix = useSelector(selectPrefix);

  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDateFocus, setToDateFocus] = useState(false);
  const ReduxStationName = useSelector(selectStationName);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const c = window.innerWidth <= 500 ? Mobilecolumns : columns;
  const userCode = useSelector(selectUserCode);
  const getAccountData = () => {
    setdataloading(true);
    deleteWholeDatabase('day_end_summary');

    axios
      .post(
        `/api/voucherRoute/dayEndSummary`,
        {
          table_prefix: prefix,
          from_date: firstDate,
          to_date: SecondDate,
          station_id: ReduxStation_id ? ReduxStation_id : '',
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          if (data[0]) {
            let rowList = [];

            for (let index = 0; index < data.length; index++) {
              rowList.push({
                ...data[index],
                id: index,
              });
            }
            setList(rowList);
            const databaseName = 'day_end_summary';
            const storeName = 'Data';

            const dataToSave = rowList;
            HandleIndexedDB({
              databaseName,
              storeName,
              dataToSave,
              callback: () => {
                const storeName = 'Details';
                const dataToSave = [
                  {
                    SID: ReduxStation_id,
                    SNAME: ReduxStationName,
                    FDATE: moment(firstDate).format('YYYY-MM-DD'),
                    TDATE: moment(SecondDate).format('YYYY-MM-DD'),

                    ICODE: 1,
                    USERCODE: userCode,
                  },
                ];
                HandleIndexedDB({
                  databaseName,
                  storeName,
                  dataToSave,
                });
              },
            });
          } else {
            setList([]);
          }
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      })
      .finally(() => {
        setdataloading(false);
      });
    // };
  };
  const okButton = () => {
    getAccountData();
  };
  const dispatch = useDispatch();

  useEffect(() => {
    let db;
    let database = 'day_end_summary';
    const request = indexedDB.open(database);
    request.onsuccess = function (event) {
      // Assign the opened database instance to the db variable
      db = event.target.result;
      if (db.objectStoreNames.length > 0) {
        const transaction = db.transaction(db.objectStoreNames, 'readonly');

        const objectStoreNames = transaction.objectStoreNames;
        db.close();
        const databaseName = 'day_end_summary';
        const storeNamesArray = Array.from(objectStoreNames);
        const storeName = 'Data';
        if (storeNamesArray[0]) {
          HandleIndexedDB({
            databaseName,
            storeName,
            callback: (data) => {
              const storeName = 'Details';
              HandleIndexedDB({
                databaseName,
                storeName,
                callback: (d) => {
                  setList(data);
                  dispatch(setStationID(d[0].SID));
                  dispatch(setStationName(d[0].SNAME));
                  dispatch(
                    setreportsFromDate(moment(d[0].FDATE).format('YYYY-MM-DD'))
                  );
                  dispatch(
                    setreportsTodate(moment(d[0].TDATE).format('YYYY-MM-DD'))
                  );
                },
              });
            },
          });
        } else {
          if (userType !== 'USER') {
            dispatch(setStationID(''));
            dispatch(setStationName(''));
          }
        }
      } else {
        db.close();
        if (userType !== 'USER') {
          dispatch(setStationID(''));
          dispatch(setStationName(''));
        }
        const deleteRequest = indexedDB.deleteDatabase(database);

        deleteRequest.onsuccess = function () {
          console.log('Database deleted successfully');
        };

        deleteRequest.onerror = function (event) {
          console.error('Error deleting database:', event.target.error);
        };
        // Delete the database if no object stores are available
      }
    };
  }, []);

  return (
    <div className="p-4 w-full h-full items-center overflow-y-scroll">
      {window.innerWidth <= 500 ? (
        <>
          <div
            style={{
              border: '1px solid #b5b3b3',
            }}
            className="pr-2 py-3 rounded-t-sm  flex items-center "
          >
            <div className="px-1 ">From</div>

            <GeniusReportsDate
              date={firstDate}
              onChange={(e) => {
                dispatch(setreportsFromDate(moment(e).format('YYYY-MM-DD')));
                setFromDateFocus(false);
              }}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="px-1  ">To</div>
            <GeniusReportsDate
              date={SecondDate}
              onChange={(e) => {
                dispatch(setreportsTodate(moment(e).format('YYYY-MM-DD')));
                setToDateFocus(false);
              }}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>
          <div style={{ border: '1px solid #b5b3b3', borderTop: '0px' }}>
            {userType === 'USER' ? (
              <div className="py-2">
                <button
                  onClick={okButton}
                  className="flex items-center rounded-sm ml-2"
                  style={{
                    backgroundColor: '#c1c1c1',
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 4,
                    paddingBottom: 4,
                    color: 'black',
                  }}
                >
                  <div className="flex items-center text-sm">Ok</div>
                </button>
              </div>
            ) : (
              <div className="flex  text-[#4B4545] p-2  ">
                <div
                  onClick={() => {
                    if (userType === 'MANAGER') {
                      dispatch(setListStationMasterModalStatus(true));
                    }
                  }}
                  style={{ fontSize: 13 }}
                  className=" flex whitespace-normal  justify-center items-center hover:cursor-pointer "
                >
                  <div className="flex items-center ">
                    <div>
                      <i
                        className="bi bi-search"
                        style={{ color: '#f44336', fontSize: 15 }}
                      ></i>
                    </div>

                    <span
                      style={{ fontSize: 12, fontWeight: 600 }}
                      className=" text-[#5c5b5b] pl-2 whitespace-nowrap  items-center"
                    >
                      Station ID
                    </span>
                  </div>

                  <div
                    style={{
                      border: '1px dotted #a6a2a2 ',
                      borderRadius: '3px',
                    }}
                    className="flex text-[#a6a2a2]  pl-2 ml-2  "
                  >
                    <input
                      size={'small'}
                      placeholder="All"
                      value={ReduxStation_id ? ReduxStation_id : 'All'}
                      style={{
                        // width: '20%',
                        fontSize: 12,
                        paddingTop: '2px',
                        paddingBottom: '2px',
                        backgroundColor: 'transparent',
                      }}
                      readOnly
                      className=" outline-0 w-6"
                    />

                    <div
                      style={{
                        borderLeft: '1px dotted #a6a2a2 ',
                      }}
                      className="px-2 p-1 w-36 overflow-x-hidden whitespace-nowrap print:hidden"
                    >
                      {ReduxStationName ? `${ReduxStationName}` : null}
                    </div>
                  </div>
                </div>

                <div>
                  <button
                    onClick={okButton}
                    className="flex items-center rounded-sm ml-2"
                    style={{
                      backgroundColor: '#c1c1c1',
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingTop: 4,
                      paddingBottom: 4,
                      color: 'black',
                    }}
                  >
                    <div className="flex items-center text-sm">Ok</div>
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <FromToDate
          submit={okButton}
          partyName={false}
          reset={() => {
            dispatch(setreportsFromDate(moment().format('YYYY-MM-DD')));
            dispatch(setreportsTodate(moment().format('YYYY-MM-DD')));
            if (userType === 'MANAGER') {
              dispatch(setStationID(''));
              dispatch(setStationName(''));
            }
          }}
        />
      )}

      <div
        style={{
          height: `calc(100% - 58px)`,
          width: '100%',
        }}
        className="mt-3 rounded-sm"
      >
        {dataLoading ? (
          <div className="w-full ">
            <div className="flex w-full ">
              {c.map((e, k) => {
                return (
                  <Skeleton
                    animation={'wave'}
                    key={k}
                    width={e.width ? e.width : 100}
                    height={60}
                    style={{
                      marginLeft: 3,
                    }}
                  />
                );
              })}
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, f) => {
                return (
                  <div key={f} className="flex ">
                    {c.map((e, kf) => {
                      return (
                        <Skeleton
                          key={kf}
                          width={e.width ? e.width : 100}
                          height={50}
                          style={{
                            marginLeft: 3,
                          }}
                        >
                          {' '}
                        </Skeleton>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <DataGrid
            // hideFooterPagination

            headerHeight={0}
            scrollbarSize={0}
            sx={
              window.innerWidth <= 425
                ? {
                    backgroundColor: 'white',
                    borderRadius: '7px', // Set border radius to 7px
                    fontSize: 9,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                    },
                  }
                : {
                    backgroundColor: 'white',
                    overflow: 'hidden  ',
                    borderRadius: '7px', // Set border radius to 7px
                    fontSize: 13,
                    '& .super-app-theme--header': {
                      backgroundColor: '#E6E6E3',
                      display: 'none',
                      height: '0px',
                    },
                    '& .super-app-theme--collection': {
                      backgroundColor: '#e5f1e3',
                      color: '#122907',
                    },
                    '& .super-app-theme--expense': {
                      backgroundColor: '#fdfaf6',
                    },
                  }
            }
            rowHeight={33}
            columns={window.innerWidth <= 500 ? Mobilecolumns : columns}
            rows={list}
            components={{
              // Header: () => <GeniusHeader columns={columns} />,
              Header: () =>
                window.innerWidth <= 500 ? (
                  <DayEndHeaderMobile />
                ) : (
                  <DayEndHeader />
                ),
              Pagination: GridPagination,
              Footer: () => (
                <DayEndFooter
                  rows={list}
                  columns={window.innerWidth <= 500 ? Mobilecolumns : columns}
                />
              ),
              NoRowsOverlay: () => {
                return (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    No Records Found
                  </div>
                );
              },
            }}
            pageSize={100}
            rowsPerPageOptions={[100]}
            experimentalFeatures={{ newEditingApi: true }}
            showCellRightBorder={true}
            pagination={true}
            showColumnRightBorder={true}
            disableColumnSelector
            disableColumnReorder
            disableColumnMenu={true}
          />
        )}
      </div>
      {/* 
      <div style={{ fontSize: 14 }} className="flex flex-col items-end pt-4 ">
        <div className="flex flex-col items-end   ">
          <div className="flex pb-0.5  ">
            <div>Total Collection :</div>
            <div
              className="flex justify-end"
              style={{
                width: window.innerWidth <= 500 ? width * 20 : width * 7.5,
              }}
            >
              {FormatNumber2(TotalCashAmt + Totalcollection_amt)}
            </div>
          </div>

          <div className="flex pb-0.5   ">
            <div>Total Expense :</div>
            <div
              className="flex justify-end"
              style={{
                width: window.innerWidth <= 500 ? width * 20 : width * 7.5,
              }}
            >
              {FormatNumber2(Totaldiscount + TotalamountPaid)}
            </div>
          </div>

          <div className="flex   ">
            <div style={{ fontWeight: 'bold' }} className="text-[14px]">
              Balance :
            </div>
            <div
              className="flex justify-end text-[14px]"
              style={{
                width: window.innerWidth <= 500 ? width * 20 : width * 7.5,
                fontWeight: 600,
              }}
            >
              {FormatNumber2(
                TotalCashAmt +
                  Totalcollection_amt -
                  (Totaldiscount + TotalamountPaid)
              )}
            </div>
          </div>
        </div>
      </div> */}

      <ModListStationMaster
        onRowClick={(p) => {
          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
    </div>
  );
};

export default DayendSummary;
