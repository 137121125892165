import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { useState } from 'react';
import { toProperCase } from '../utility/component/format';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import axios from 'axios';
import { useEffect } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/buttons';
import { useFocus } from '../utility/hooks/hooks';

const GeniusStationMaster = () => {
  const dispatch = useDispatch();

  const [id, setId] = useState('');
  const [rows, setRows] = useState([]);

  const [stationName, setStationName] = useState('');
  const [code, setCode] = useState('');
  const [distance, setDistance] = useState('');
  const [city, setCity] = useState('');

  const windowWidth = useSelector(selectWidth);

  const columns = [
    {
      width: 100,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      renderHeader: (params) => <div className="text-md">Code</div>,
    },
    {
      field: 'STATION_NAME',
      renderHeader: (params) => <div className="text-md">Station Name</div>,
      renderCell: (params) => {
        const runningCaseValue = params.value.toUpperCase();
        return <div>{runningCaseValue}</div>;
      },
      width: 250,

      headerAlign: 'center',
      align: 'left',
    },
    {
      width: 100,

      headerAlign: 'center',
      align: 'left',
      field: 'DISTANCE_KM',
      renderHeader: (params) => <div className="text-md">Distance</div>,
    },
    {
      width: 120,

      headerAlign: 'center',
      align: 'left',
      field: 'CITY_NAME',
      renderHeader: (params) => <div className="text-md">City</div>,
      renderCell: (params) => {
        const runningCaseValue = params.value.toUpperCase();
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const columnsMobile = [
    {
      width: 50,
      headerAlign: 'center',
      align: 'left',
      field: 'CODE',
      renderHeader: (params) => <div className="text-md">Code</div>,
    },
    {
      field: 'STATION_NAME',
      renderHeader: (params) => <div className="text-md">Station Name</div>,
      renderCell: (params) => {
        const runningCaseValue = params.value.toUpperCase();
        return <div>{runningCaseValue}</div>;
      },
      width: 120,

      headerAlign: 'center',
      align: 'left',
    },
    {
      width: 50,

      headerAlign: 'center',
      align: 'left',
      field: 'DISTANCE_KM',
      renderHeader: (params) => <div className="text-md">Distance</div>,
    },
    {
      width: 60,

      headerAlign: 'center',
      align: 'left',
      field: 'CITY_NAME',
      renderHeader: (params) => <div className="text-md">City</div>,
      renderCell: (params) => {
        const runningCaseValue = params.value.toUpperCase();
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const getListStationMaster = async () => {
    // try {
    await axios
      .post(
        '/api/masters/listStationMaster',
        {
          table_prefix: prefix,
          CODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )

      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      });
  };

  useEffect(() => {
    getListStationMaster();
  }, []);

  const [stationError, setstationError] = useFocus();
  const [cityError, setCityError] = useFocus();

  const saveStationMaster = () => {
    if (stationName && city) {
      // Check for duplicate station names before saving
      const isDuplicateStationName = rows.some(
        (row) => row.STATION_NAME === stationName.toUpperCase()
      );

      if (isDuplicateStationName && !id) {
        // Handle the case where the station name already exists
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This station name already exists. Please choose a different name.',
          })
        );
      } else {
        const data = {
          table_prefix: prefix,
          CODE: id ? id : '',
          STATION_NAME: stationName.toUpperCase().replace('-', ' '),
          DISTANCE_KM: distance ? distance : 0,
          CITY_NAME: city.toUpperCase(),
        };
        dispatch(setSpinnerLoading(id ? 'Modifying' : 'Saving'));

        axios
          .post(`/api/issueMasterRoute/saveStationMaster`, data, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          })
          .then(async function (response) {
            if (!response.data.error) {
              if (id) {
                setAlertWithTitle({
                  title: 'Success.',
                  msg: 'Data Updated successfully.',
                  lottie: 'success',
                });
              } else {
                dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
              }
              clearState();
              getListStationMaster();
              setstationError();
            } else {
              dispatch(
                setAlertWithTitle({
                  title: 'Error 112',
                  msg: response.data.error,
                })
              );
              console.log(!response.data.error);
            }
          })
          .catch(function (error) {
            if (error.response.data.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
              console.log(error.response);
            }
            console.log(error);
            dispatch(
              setAlertWithTitle({
                title: 'ERROR',
                msg: 'Something went wrong.',
                lottie: 'reject',
              })
            );
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
          });
      }
    } else {
      if (!city) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter City.',
            lottie: 'reject',
          })
        );
        setCityError();
      }
      if (!stationName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Station Name.',
            lottie: 'reject',
          })
        );
        setstationError();
      }
    }
  };

  const clearState = () => {
    setStationName('');
    setCode('');
    setDistance('');
    setCity('');
    setId('');
    setstationError();
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === 's') {
        e.preventDefault();
        saveStationMaster();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [stationName, code, city, distance]);

  return (
    <div className="flex-grow  bg-white    border-brown px-5 max-sm:px-2">
      <div id="input" className="flex mt-4">
        <div>
          <GeniustextInput
            title={'Station Name'}
            width={windowWidth > 764 ? '610px' : '300px'}
            type={'text'}
            value={stationName}
            onChange={(e) => {
              setStationName(toProperCase(e.target.value));
            }}
            refrance={stationError}
            autofocus={true}
          />
        </div>
      </div>

      <div className=" flex ">
        <GeniustextInput
          title={'City'}
          width={windowWidth > 764 ? '300px' : '150'}
          type={'text'}
          value={city}
          onChange={(e) => {
            setCity(toProperCase(e.target.value));
          }}
          refrance={cityError}
        />
        <div className="ml-3">
          <GeniustextInput
            title={'Distance'}
            width={windowWidth > 764 ? '300px' : '150'}
            type={'number'}
            value={distance}
            onChange={(e) => {
              setDistance(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="w-full ll:h-[400px] h-[300px] mt-1 flex">
        <GeniusDatagrid
          rowID={(t) => t.CODE}
          onRowClick={(p) => {
            setStationName(p.row.STATION_NAME);
            setId(p.row.CODE);
            setCode(p.row.CODE);
            setCity(p.row.CITY_NAME);
            setDistance(p.row.DISTANCE_KM);
          }}
          columns={windowWidth > 764 ? columns : columnsMobile}
          list={rows}
        />
      </div>

      <div className="flex w-full justify-end items-center py-5">
        {id ? (
          <ModifyButton onClick={saveStationMaster} />
        ) : (
          <SaveButton onClick={saveStationMaster} />
        )}

        <UndoButton onClick={clearState} />
      </div>
    </div>
  );
};

export default GeniusStationMaster;
