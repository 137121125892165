import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import GeniustextInput from '../utility/component/mastertext';
import axios from 'axios';
import {
  setALertStatus,
  setAlertMsg,
  setAlertWithTitle,
  setExpenseMasterModalStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { selectToken } from '../utility/Redux/security';
import { FormatNumber2 } from '../utility/component/tools';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import { useEffect } from 'react';
import {
  ExitButton,
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/buttons';
import { toProperCase } from '../utility/component/format';
import { Switch } from '@mui/material';

const GeniusExpenseMaster = () => {
  const windowWidth = useSelector(selectWidth);
  const [expenseName, setExpenseName] = useState('');
  const [expenseAmount, setExpenseAmount] = useState();
  const [isActive, SetActive] = useState(false);
  const [list, setList] = useState([]);
  const [editCode, setEditCode] = useState('');
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const dispatch = useDispatch();

  const columns = React.useMemo(
    () => [
      {
        field: 'CODE',
        type: 'string',
        width: 80,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{'Code'}</strong>
        ),
      },

      {
        field: 'EXPENCE_NAME',
        type: 'string',
        width: 300,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{'Expense Name'}</strong>
        ),
      },
      {
        field: 'EXPENCE_AMOUNT',
        type: 'string',
        width: 150,
        align: 'right',
        renderCell: (param) => {
          if (param.value === 0) {
            return <div> {''}</div>;
          } else {
            return FormatNumber2(param.value);
          }
        },
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{'Expense Amount'}</strong>
        ),
      },
      {
        field: 'EXPENCE_STATUS',
        type: 'number',
        width: 100,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        valueFormatter: (params) => {
          if (params.value) {
            return params.value === 'Yes' ? 'Active' : 'Inactive';
          } else {
            return '';
          }
        },
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{'Status'}</strong>
        ),
      },
    ],
    []
  );

  const columnsMobile = React.useMemo(
    () => [
      {
        field: 'CODE',
        type: 'string',
        width: 50,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{'Code'}</strong>
        ),
      },

      {
        field: 'EXPENCE_NAME',
        type: 'string',
        width: 110,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{'Expense Name'}</strong>
        ),
      },
      {
        field: 'EXPENCE_AMOUNT',
        type: 'string',
        width: 70,
        align: 'right',
        renderCell: (param) => {
          if (param.value === 0) {
            return <div> {''}</div>;
          } else {
            return FormatNumber2(param.value);
          }
        },
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>
            {windowWidth > 764 ? 'Expense Amount' : 'Exp Amt.'}
          </strong>
        ),
      },
      {
        field: 'EXPENCE_STATUS',
        type: 'number',
        width: 60,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        valueFormatter: (params) => {
          if (params.value) {
            return params.value === 'Yes' ? 'Active' : 'Inactive';
          } else {
            return '';
          }
        },
        renderHeader: (params) => (
          <strong style={{ fontSize: 13 }}>{'Status'}</strong>
        ),
      },
    ],
    []
  );

  const SaveExpense = () => {
    if (expenseName !== '') {
      dispatch(setSpinnerLoading('Saving Expense'));
      axios
        .post(
          '/api/masters/saveExpenceMaster',
          {
            table_prefix: prefix,
            EXPENCE_NAME: expenseName,
            EXPENCE_STATUS: isActive ? 'Yes' : 'No',
            EXPENCE_AMOUNT: expenseAmount ? expenseAmount : 0,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          if (!res.data.error) {
            setExpenseName('');
            setExpenseAmount();
            SetActive(false);
            dispatch(
              setAlertWithTitle({
                title: 'Success',
                msg: 'Data saved successfully',
                lottie: 'success',
              })
            );
          }
          getListExpense();
        })
        .catch((e) => {
          if (e.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          } else {
            dispatch(setAlertMsg(e.message));
            dispatch(setALertStatus(true));
          }
          console.log(e);
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please Fill Expense Name',
        })
      );
    }
  };

  const getListExpense = () => {
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        '/api/masters/listExpenceMaster',
        {
          table_prefix: prefix,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          setList(res.data.response);
          undo();
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(setAlertMsg(e.message));
          dispatch(setALertStatus(true));
        }
        console.log(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const handleClose = () => {
    dispatch(setExpenseMasterModalStatus(false));
  };

  const undo = () => {
    setExpenseName('');
    SetActive(false);
    setEditCode('');
    setExpenseAmount('');
  };

  useEffect(() => {
    getListExpense();
  }, []);

  const handleModify = () => {
    dispatch(setSpinnerLoading('Modifying'));
    axios
      .post(
        '/api/masters/modifyExpenceMaster',
        {
          table_prefix: prefix,
          EXPENCE_NAME: expenseName,
          EXPENCE_STATUS: isActive ? 'Yes' : 'No',
          CODE: editCode,
          EXPENCE_AMOUNT: expenseAmount ? expenseAmount : 0,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          undo();
          dispatch(
            setAlertWithTitle({
              title: 'Succes',
              msg: 'Expense is Modified',
              lottie: 'success',
            })
          );
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(e.response);
        } else {
          dispatch(setAlertMsg(e.message));
          dispatch(setALertStatus(true));
        }
        console.log(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  return (
    <div className="flex-grow  bg-white border-brown px-5 max-sm:px-2">
      <div className=" flex mt-4">
        <GeniustextInput
          title={'Expense Name'}
          width={windowWidth > 764 ? '300px' : '150'}
          type={'text'}
          autofocus={true}
          value={expenseName}
          onChange={(t) => setExpenseName(toProperCase(t.target.value))}
        />
        <div className="ml-3">
          <GeniustextInput
            title={'Expense Amount'}
            width={windowWidth > 764 ? '180px' : '150px'}
            type={'number'}
            value={expenseAmount}
            onChange={(t) => setExpenseAmount(t.target.value)}
          />
        </div>
        <div className="ml-3 max-sm:hidden">
          <div className="flex" style={{ width: '150px' }}>
            Status
          </div>
          <div className="border border-[#b5b3b3] flex justify-center items-center p-[2px] text-sm rounded-sm">
            {isActive ? 'Activated' : 'Deactivated'}
            <Switch
              size="small"
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: isActive ? 'green' : 'red',
                  fontSize: '10px',
                  padding: 0,
                },
              }}
              checked={isActive}
              color={isActive ? 'success' : 'error'}
              onClick={() => SetActive(!isActive)}
            />
            {/* <div className="switch-container">
              <input type="checkbox" checked={true} className="switch-input" />
              <label className="switch-label">{true ? 'ON' : 'OFF'}</label>
            </div> */}
          </div>
        </div>
      </div>

      <div className=" max-sm:block hidden">
        <div className="flex" style={{ width: '150px' }}>
          Status
        </div>
        <div
          className="border border-[#b5b3b3] flex justify-center items-center p-2 rounded-sm"
          style={{ width: '130px' }}
        >
          {isActive ? 'Activated' : 'Deactivated'}
          <Switch
            size="small"
            sx={{
              '& .MuiSwitch-thumb': {
                backgroundColor: isActive ? 'green' : 'red',
              },
            }}
            checked={isActive}
            color={isActive ? 'success' : 'error'}
            onClick={() => SetActive(!isActive)}
          />
        </div>
      </div>

      <div className="w-full ll:h-[400px] h-[300px] mt-1 flex">
        <GeniusDatagrid
          rowID={(t) => t.CODE}
          onRowClick={(params) => {
            setEditCode(params.row.CODE);
            setExpenseName(params.row.EXPENCE_NAME);
            setExpenseAmount(params.row.EXPENCE_AMOUNT);
            SetActive(params.row.EXPENCE_STATUS === 'Yes' ? true : false);
          }}
          columns={windowWidth > 764 ? columns : columnsMobile}
          list={list}
        />
      </div>

      <div className="flex w-full justify-end items-center py-5">
        {editCode ? <ModifyButton onClick={handleModify} /> : null}
        {!editCode ? <SaveButton onClick={SaveExpense} /> : null}
        <UndoButton onClick={undo} />
        <ExitButton onClick={handleClose} />
      </div>
    </div>
  );
};

export default GeniusExpenseMaster;
