import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRouteAlertReport,
  setRouteAlertReport,
} from '../../utility/Redux/list';
import { selectToken } from '../../utility/Redux/security';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  setStationID,
  setStationName,
} from '../../utility/Redux/profile';
import {
  columns,
  columnsMobileView,
} from '../../utility/columns/alertReportCol';
import {
  setCollectionAccountDetails,
  setListStationMasterModalStatus,
  setPartyLedgerModalStatus,
  setviewBillModalStatus,
} from '../../utility/Redux/modal';
import ViewBillModal from '../../utility/component/modals/modBillInvoice';
import { selectStatus } from '../../utility/Redux/modal';
import ModListStationMaster from '../../utility/component/modals/modListStationMaster';
import { FromToDate } from '../../utility/component/fromToDate';
import { getRouteAlertReport } from '../../utility/list/routeAlertReportList';
import ReactToPrint from 'react-to-print';
import GeniusTableForReports from '../../utility/component/geniusComponents/geniusTableforReports';
import { Skeleton } from '@mui/material';
import IsLocalData from '../../utility/functions/ceckLocalData';

const RouteAlert = () => {
  const userType = useSelector(selectUserType);
  const ReduxStationName = useSelector(selectStationName);
  const ReduxStation_id = useSelector(selectStationId);
  const printRef = useRef();

  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const dispatch = useDispatch();
  const routeAlertList = useSelector(selectRouteAlertReport);
  const loading = useSelector(selectStatus);

  const okButton = () => {
    getRouteAlertReport(
      dispatch,
      prefix,
      headers,
      ReduxStation_id,
      ReduxStationName
    );

    dispatch(setviewBillModalStatus(false));
  };

  useEffect(() => {
    const callback = (details, data) => {
      dispatch(setRouteAlertReport(data));
      dispatch(setStationID(details[0].SID));
      dispatch(setStationName(details[0].SNAME));
    };

    const elseFunc = () => {
      if (userType !== 'USER') {
        dispatch(setStationID(''));
        dispatch(setStationName(''));
      }
    };

    IsLocalData('Route_Alert_Report', callback, elseFunc);
  }, []);
  return (
    <div className="h-full bg-white flex flex-col justify-evenly">
      <div className="h-full  p-1  pb-4">
        <div className="pb-2 ">
          <FromToDate
            submit={okButton}
            reset={() => {
              if (userType === 'USER') {
                dispatch(
                  setCollectionAccountDetails({
                    accName: '',
                    accAddress: '',
                    accMobile: '',
                    accNumber: '',
                    stationId: '',
                  })
                );
              } else {
                dispatch(setStationID(''));
                dispatch(setStationName(''));
                dispatch(
                  setCollectionAccountDetails({
                    accName: '',
                    accAddress: '',
                    accMobile: '',
                    accNumber: '',
                    stationId: '',
                  })
                );
              }
            }}
            date={{
              status: false,
            }}
            partyName={false}
            otherButton={
              routeAlertList[0] ? (
                <ReactToPrint
                  trigger={() => {
                    return (
                      <button
                        style={{
                          width: 'auto',
                          borderRadius: '7px',
                          height: 'auto',
                          paddingLeft: 30,
                          paddingRight: 30,
                          paddingTop: 4,
                          paddingBottom: 4,
                          backgroundColor: '#bad4f9',
                          color: 'black',
                          marginLeft: 10,
                        }}
                      >
                        <div className="text-sm">Print</div>
                      </button>
                    );
                  }}
                  content={() => printRef.current}
                />
              ) : (
                false
              )
            }
          />
        </div>
        <ModListStationMaster
          onRowClick={(p) => {
            dispatch(setStationID(p.row.CODE));
            dispatch(setStationName(p.row.STATION_NAME));
            dispatch(setListStationMasterModalStatus(false));
          }}
        />
        <ViewBillModal />

        {loading ? (
          <div className="w-full ">
            <div className="flex w-full ">
              {columns.map((e, k) => {
                return (
                  <Skeleton
                    animation={'wave'}
                    key={k}
                    width={e.width ? e.width : 200}
                    height={60}
                    style={{
                      marginLeft: 3,
                    }}
                  />
                );
              })}
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, f) => {
                return (
                  <div key={f} className="flex ">
                    {columns.map((e, kf) => {
                      return (
                        <Skeleton
                          key={kf}
                          width={e.width ? e.width : 200}
                          height={50}
                          style={{
                            marginLeft: 3,
                          }}
                        >
                          {' '}
                        </Skeleton>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        ) : (
          <div className=" mb-2" style={{ height: '85%' }}>
            <DataGrid
              sx={{
                backgroundColor: 'white',

                fontSize: 12,
                '& .super-app-theme--header': {
                  backgroundColor: '#0D0D0D',
                  color: '#ffffff',
                },
              }}
              getRowId={(i) => i.ACCOUNT_NO}
              rowHeight={33}
              headerHeight={40}
              columns={window.innerWidth < 770 ? columnsMobileView : columns}
              rows={routeAlertList ? routeAlertList : []}
              onRowDoubleClick={(p) => {
                dispatch(setPartyLedgerModalStatus(true));
                dispatch(
                  setCollectionAccountDetails({
                    accName: p.row.NAME,
                    accAddress: p.row.ADDRESS,
                    accMobile: p.row.MOBILE,
                    accNumber: p.row.ACCOUNT_NO,
                    stationId: p.row.SID,
                  })
                );
              }}
              pageSize={100}
              scrollbarSize={0}
              rowsPerPageOptions={[100]}
              experimentalFeatures={{ newEditingApi: true }}
              pagination
              showCellRightBorder={true}
              showColumnRightBorder={true}
              disableColumnSelector
              disableColumnReorder
              disableColumnMenu={true}
            />
          </div>
        )}
        <div className="flex justify-end">
          {/* <ReactToPrint
            trigger={() => {
              return (
                <button
                  style={{
                    width: 'auto',
                    borderRadius: '7px',
                    height: 'auto',
                    padding: 10,
                    backgroundColor: '#bad4f9',
                    color: 'black',
                  }}
                >
                  Print Report
                </button>
              );
            }}
            content={() => printRef.current}
          /> */}
        </div>

        <div
          ref={printRef}
          style={{ width: '100%', display: 'block', visibility: 'hidden' }}
        >
          <GeniusTableForReports
            stationId={ReduxStation_id}
            columns={columns}
            data={routeAlertList}
            title={'Route Alert Reports'}
          />
        </div>
      </div>
    </div>
  );
};

export default RouteAlert;
