import axios from 'axios';

import {
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';

export const GetItemList = async (
  setItemList,
  prefix,
  token,
  dispatch,
  sname,
  setFullItemList
) => {
  dispatch(setSpinnerLoading('Loading'));
  await axios
    .post(
      '/api/issueMasterRoute/listItemMaster',
      {
        table_prefix: prefix,
        sname: sname,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((p) => {
      setItemList(p.data.response);
      setFullItemList(p.data.response);
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
