import axios from 'axios';
import { setSessionExpired, setloading } from '../Redux/modal';
import { setSaleSummaryList } from '../Redux/list';

import deleteWholeDatabase from '../functions/deleteWholeDatabase';

export function getSaleSummaryDetails(
  dispatch,
  prefix,
  firstDate,
  SecondDate,
  accNumber,
  headers,
  usertype,
  ReduxStation_id,
  userCode,
  callback
) {
  dispatch(setloading(true));
  deleteWholeDatabase('Sale-Summary');
  axios
    .post(
      `/api/reports/saleSummary`,
      {
        table_prefix: prefix,
        sid: ReduxStation_id,
        from_date: firstDate,
        to_date: SecondDate,
        account_no: accNumber ? accNumber : '',
      },

      {
        headers: headers,
      }
    )
    .then((res) => {
      if (!res.data.error) {
        if (res.data.response[0]) {
          const data = res.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
              ICODE: index + 1,
            });
          }
          dispatch(setSaleSummaryList(rowList));
          if (rowList[0]) {
            callback(rowList);
          }
        } else {
          dispatch(setSaleSummaryList([]));
        }
      }
    })
    .catch((e) => {
      console.log(e);

      if (e.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setloading(false));
    });
}
