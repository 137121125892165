import { Button } from '@mui/material';
import axios from 'axios';
import html2canvas from 'html2canvas';
import { toJpeg } from 'html-to-image';
import React, { useRef } from 'react';
import Lottie from 'lottie-react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaWhatsappSquare, FaSms, FaWhatsapp } from 'react-icons/fa';
import { TiArrowBack, TiMessage } from 'react-icons/ti';
import whitSpinner from '../json/spinerWhite.json';
import {
  selectVoucherNumber,
  setAlertMsg,
  setALertStatus,
  setCollectionAccountDetails,
  setCollectionVoucherPrintModalStatus,
  setloading,
  setSessionExpired,
  setVocherModalStatus,
} from '../Redux/modal';

import jsPDF from 'jspdf';
import spinner from '../json/Spinner.json';

import { FcPrint } from 'react-icons/fc';
import { selectToken } from '../Redux/security';
import { useFocus } from '../hooks/hooks';
import { BiCloudDownload } from 'react-icons/bi';

import CollPrintView from './collPrintView';
import { IoPrintOutline } from 'react-icons/io5';
import ReactToPrint from 'react-to-print';

const CollectionVoucherPrint = ({ data, paymode }) => {
  const { MOBILE, AMOUNT_RECEIVED } = data;
  const dispatch = useDispatch();

  const [numberRef, setNumberFocous] = useFocus();
  const componentRef = useRef(null);
  const [waDisable, setWadisable] = useState(false);
  const [smsDisable, setsmsdisable] = useState(false);
  const voucherNumber = useSelector(selectVoucherNumber);

  const token = useSelector(selectToken);

  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const [sentdo, seSendTo] = useState(MOBILE);

  const handleSendImage = () => {
    dispatch(setloading(true));
    setWadisable(true);
    toJpeg(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        dispatch(setloading(true));
        axios
          .post(
            '/api/sendDigitalColVoucher/saveColVoucherImage',
            {
              dataUrl: JSON.stringify({ dataUrl }),
              VOUCHER_NO: voucherNumber,
              mobile: sentdo,
            },

            {
              headers: headers,
            }
          )

          .then((response) => {
            setWadisable(false);
            dispatch(setAlertMsg('Message sent successfully'));
            dispatch(setALertStatus(true));
          })
          .catch((err) => {
            console.log(err);
            if (err?.response.data?.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
            }
          })
          .finally(() => {
            dispatch(setloading(false));
            setWadisable(false);
          });
      })
      .catch((err) => console.log(err));
  };

  const handleSendSms = () => {
    dispatch(setloading(true));
    setsmsdisable(true);
    axios
      .post(
        '/api/sendDigitalColVoucher/sendColVoucherSms',
        {
          VOUCHER_NO: voucherNumber,
          MOBILE: sentdo,
          AMOUNT: AMOUNT_RECEIVED,
        },

        {
          headers: headers,
        }
      )

      .then((response) => {
        dispatch(setAlertMsg('Message sent successfully'));
        dispatch(setALertStatus(true));
      })
      .catch((err) => {
        console.log(err);

        if (err?.response.data?.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(setAlertMsg('Message not sent'));
          dispatch(setALertStatus(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
        setsmsdisable(false);
      });
  };

  const handleToPrint = () => {
    html2canvas(componentRef.current).then((canvas) => {
      var doc = new jsPDF();
      doc.addImage(canvas.toDataURL(), 'PNG', 0, 0, 70, 180);
      // Convert the PDF to a Blob object
      const pdfBlob = new Blob([doc.output('blob')], {
        type: 'application/pdf',
      });
      const formData = new FormData();
      formData.append('pdf', pdfBlob, 'file.pdf');
      formData.append('email', sentdo);

      axios
        .post(
          'http://localhost:8080/api/sendBill',

          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        )

        .then((response) => {
          console.log('');
        })
        .catch((err) => console.log(err));
    });
  };

  const downloadPdf = () => {
    toJpeg(
      componentRef.current,
      window.innerWidth < 768 ? { quality: 0.25 } : { quality: 0.95 }
    )
      .then((dataUrl) => {
        const pdf = new jsPDF();
        pdf.addImage(dataUrl, 'PNG', 0, 0);
        pdf.save(`Voucher_${voucherNumber}`);
      })
      .catch((err) => console.log(err));
  };

  const handleClose = () => {
    dispatch(setCollectionVoucherPrintModalStatus(false));
    dispatch(setVocherModalStatus(false));
    dispatch(
      setCollectionAccountDetails({
        accName: '',
        accAddress: '',
        accMobile: '',
        accNumber: '',
        stationId: '',
      })
    );
  };

  return (
    <div className="h-full">
      <div className="w-full h-full flex flex-col md:flex md:flex-row ">
        <div
          style={window.innerWidth < 770 ? { width: '100%' } : { width: '60%' }}
          className=" bg-[#EDEDF2]  flex justify-center p-5 h-full"
        >
          <div className="bg-white w-full flex justify-center shadow-md border h-full   ">
            <CollPrintView ref={componentRef} data={data} paymode={paymode} />
          </div>
        </div>
        <div style={{ width: '40%' }} className="p-5  md:flex flex-col hidden ">
          <div className="w-full">
            <div className="flex justify-between w-full  "></div>

            <div className="flex justify-start items-center w-full pt-6 ">
              <div style={{ fontSize: 13 }}>Mobile</div>
              <div
                style={{
                  height: 27,
                  backgroundColor: '#EDEDF2',
                  overflow: 'hidden',
                  marginLeft: 10,
                }}
                className=" rounded-sm  w-16 md:w-40 border-2 border-slate-300 items-center "
              >
                <input
                  type={'Number'}
                  ref={numberRef}
                  autoFocus={true}
                  style={{
                    fontSize: 13,
                    backgroundColor: '#EDEDF2',
                    paddingLeft: 10,

                    fontWeight: 400,
                    outline: 0,
                  }}
                  placeholder={'123'}
                  value={sentdo}
                  onChange={(t) => seSendTo(t.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col mt-5 justify-center">
            <Button
              size={'small'}
              onClick={() => {
                if (!waDisable && sentdo) {
                  handleSendImage();
                }
              }}
              style={{
                backgroundColor: '#A8CF45',
                color: 'white',
                textTransform: 'none',
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,
              }}
              variant="contained"
            >
              <FaWhatsapp size={20} className="bg-green text-white" />
              <div className="px-2">Whatsapp</div>{' '}
              {waDisable ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6">
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                      color="white"
                    />
                  </div>
                </div>
              ) : null}
            </Button>

            <Button
              size={'small'}
              onClick={() => {
                if (!smsDisable && sentdo) {
                  handleSendSms();
                }
              }}
              style={{
                backgroundColor: '#F5874F',
                color: 'white',
                marginTop: 10,
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,

                textTransform: 'none',
              }}
              variant="contained"
            >
              <TiMessage size={20} className="bg-orange text-white" />
              <div className="px-2">SMS</div>{' '}
              {smsDisable ? (
                <div className="w-full flex justify-center items-center">
                  <div className="w-6 h-6">
                    <Lottie
                      animationData={whitSpinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                      color="white"
                    />
                  </div>
                </div>
              ) : null}
            </Button>

            <Button
              size={'small'}
              onClick={() => {
                downloadPdf();
              }}
              style={{
                backgroundColor: '#F5874F5',
                color: 'white',
                marginTop: 10,
                textTransform: 'none',
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,
              }}
              variant="contained"
            >
              <BiCloudDownload size={20} className="bg-brown text-white" />
              <div className="px-2">Download</div>{' '}
            </Button>

            <ReactToPrint
              trigger={() => (
                <Button
                  size={'small'}
                  // onClick={() => {
                  //   downloadPdf();
                  // }}
                  style={{
                    backgroundColor: '#2892F2',
                    color: 'white',
                    marginTop: 10,
                    textTransform: 'none',
                    width: '100%',
                    justifyContent: 'start',
                    paddingLeft: 20,
                  }}
                  variant="contained"
                >
                  <IoPrintOutline size={20} className="b text-white" />
                  <div className="px-2">Print</div>{' '}
                </Button>
              )}
              content={() => componentRef.current}
              // onAfterPrint={async () => {

              // }}
            />

            <Button
              size={'small'}
              // onClick={handleShare}
              onClick={handleClose}
              style={{
                color: '#383535',
                marginTop: 10,
                borderColor: '#383535',
                textTransform: 'none',
                width: '100%',
                justifyContent: 'start',
                paddingLeft: 20,
              }}
              variant="outlined"
            >
              <TiArrowBack size={20} className=" text-red-500" />
              <div className="px-2 text-red-500">Back </div>{' '}
            </Button>
          </div>
          <div className="w-full flex justify-center mt-3"></div>
        </div>
        <div className="fixed bottom-0 w-full flex md:hidden p-3 bg-[#EDEDF2] items-center border-t-2 border-t-slate-200 ">
          <div
            style={{
              height: 35,
              backgroundColor: '#c9c5c5',
              overflow: 'hidden',
              width: '45%',
            }}
            className=" rounded-md mx-2 border-2  items-center "
          >
            <input
              type={'Number'}
              ref={numberRef}
              autoFocus={true}
              style={{
                fontSize: 14,
                backgroundColor: '#c9c5c5',
                paddingLeft: 10,
                height: '100%',
                fontWeight: 400,
                outline: 0,
              }}
              placeholder={'Mobile Number'}
              value={sentdo}
              onChange={(t) => seSendTo(t.target.value)}
            />
          </div>
          <div
            style={{ width: '55%' }}
            className=" rounded-sm flex justify-evenly "
          >
            <FaWhatsappSquare
              size={30}
              className="text-green"
              onClick={() => {
                if (!waDisable && sentdo) {
                  handleSendImage();
                }
              }}
            />

            <FaSms
              size={30}
              className="text-orange"
              onClick={() => {
                if (!smsDisable && sentdo) {
                  handleSendSms();
                }
              }}
            />

            <ReactToPrint
              trigger={() => <FcPrint size={30} className="text-orange" />}
              content={() => componentRef.current}
              // onAfterPrint={async () => {

              // }}
            />

            <TiArrowBack
              size={30}
              className="text-red-500"
              onClick={() => {
                dispatch(setCollectionVoucherPrintModalStatus(false));
                dispatch(setVocherModalStatus(false));
              }}
            />

            {/* <Button
            size={"small"}
            onClick={() => {
              dispatch(setCollectionVoucherPrintModalStatus(false));
            }}
            style={{
              backgroundColor: "#383535",
              color: "white",
              textTransform: "none",
            }}
            variant="contained"
          >
            <div className="px-2">Back</div>{" "}
          </Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CollectionVoucherPrint;
