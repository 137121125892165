import { Cancel, Search } from '@mui/icons-material';

import axios from 'axios';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import spinner from '../../../../utility/json/Spinner.json';
import { useDispatch, useSelector } from 'react-redux';
import GetAllSection from '../../../../utility/list/allSection';
import { SelectSectionList } from '../../../../utility/Redux/masters';

import {
  clearOrderBillingList,
  deletOrderItem,
  SelectAllItemsList,
  SelectBillingDiscount,
  SelectedSection,
  SelectOrdersList,
  setAllItemsList,
  setBillingDiscount,
  setEditQntyStatus,
  setOrderList,
  setSelectedSection,
  updateOrderlist,
} from '../../../../utility/Redux/tableOrder';
import {
  BillingBackButton,
  BillingSave,
} from '../../../../utility/component/buttons';
import { useNavigate } from 'react-router-dom';
import { toProperCase } from '../../../../utility/component/format';
import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setBillingAccountMasterModalStatus,
  setBillSattalmentModalStatus,
  setNoIssueAlert,
  setSessionExpired,
  setSpinnerLoading,
} from '../../../../utility/Redux/modal';
import {
  selectAccountDetails,
  selectDefultProfileSetting,
  selectPrefix,
  selectStationId,
} from '../../../../utility/Redux/profile';
import BillSettelmentModal from './modal/modSetBill';
import { selectToken } from '../../../../utility/Redux/security';
import { FormatNumber2 } from '../../../../utility/component/tools';
import BillhistoryModal from './modal/mBillHistry';
import ConfirmPrintAlert from '../../../../utility/component/modals/confirmPrintBill';
import ShareBillModal from './modal/modShareBill';
import BillingAccountMasterDatagridModal from './modal/modBillingAccMaster';
import moment from 'moment';
import Lottie from 'lottie-react';
import { selectStatus } from '../../../../utility/Redux/modal';
import ModalQntyEdit from './modal/modQntyEdit';

import { BillingSubHeader } from '../../../../utility/component/styles/billingSubHeader';
import ItemCart from './itemCart';
import { BillingCalculation } from './billingCalculation';
import ItemBox from './itemBox';
import { Button } from '@mui/material';
import ViewBillModal from '../../../../utility/component/modals/modBillInvoice';

const height = window.innerHeight / 100;
const Billing = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchText, SetSearchText] = useState('');
  const [prevBal, setPrevBal] = useState();

  const AccProfile = useSelector(selectAccountDetails);

  const { accMobile, accNumber } = AccProfile;
  const selectDiscountedAmount = useSelector(SelectBillingDiscount);

  function deleteItem(id) {
    const updatedList = [...orderList];
    const indexToDelete = updatedList.findIndex((item) => item.id === id);
    if (indexToDelete !== -1) {
      updatedList.splice(indexToDelete, 1);
      dispatch(deletOrderItem(updatedList));
    }
  }

  const defaultProfile = useSelector(selectDefultProfileSetting);

  const SectionList = useSelector(SelectSectionList);
  const selected_Section = useSelector(SelectedSection);
  const orderList = useSelector(SelectOrdersList);
  const [filterdItemList, setFilterdfilterdItemList] = useState();
  const allItemList = useSelector(SelectAllItemsList);
  const stationId = useSelector(selectStationId);
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const [salePriceLoading, setSalePriceLoading] = useState(false);

  const loading = useSelector(selectStatus);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getPrevBalance = () => {
    axios
      .post(
        '/api/reports/partyBalance',
        {
          table_prefix: prefix,
          ACCOUNT_NO: accNumber,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const list = res.data.response;
          const prevBal =
            list[0].OP_DEBIT +
            list[0].DEBIT -
            (list[0].OP_CREDIT + list[0].CREDIT);

          setPrevBal(prevBal.toFixed(2));
        }
      })
      .catch((e) => console.log(e));
  };

  const columns = [
    {
      field: 'ITEMNAME',
      type: 'string',
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex">
            <Cancel
              onClick={() => {
                deleteItem(params.row.id);
              }}
              color="error"
              sx={{ fontSize: 16, marginRight: 1 }}
            />
            <div>{toProperCase(params.value)}</div>
          </div>
        );
      },
      // valueFormatter: ({ value }) => value.toUpperCase(),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 14, color: 'white' }}> {'Item Name'} </div>
      ),
    },
    {
      field: 'QNTY',
      type: 'number',
      width: 110,
      editable: false,
      align: 'center',
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ fontSize: 14, color: 'white' }}> Qnty </div>
      ),
    },
    {
      field: 'SALE_PRICE',
      type: 'number',
      width: 80,
      editable: true,
      align: 'right',
      valueFormatter: (params) => FormatNumber2(params.value),
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ fontSize: 14, color: 'white' }}> Rate </div>
      ),
    },
    {
      field: 'AMOUNT',
      type: 'number',
      align: 'right',
      type: 'number',
      valueFormatter: (params) => FormatNumber2(params.value),
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      width: 100,
      renderHeader: (params) => (
        <div style={{ fontSize: 14, color: 'white' }}>Amount </div>
      ),
    },
  ];

  const columnsMobileView = [
    {
      field: 'ITEMNAME',
      type: 'string',
      width: 100,
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <div className="flex">
            <Cancel
              onClick={() => {
                deleteItem(params.row.id);
              }}
              color="secondary"
              sx={{ fontSize: 11, marginRight: 1 }}
            />
            <div>{toProperCase(params.value)}</div>
          </div>
        );
      },
      // valueFormatter: ({ value }) => value.toUpperCase(),
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12, color: 'white' }}> {'Item'} </div>
      ),
    },
    {
      field: 'QNTY',
      type: 'number',
      width: 50,
      editable: false,
      align: 'center',

      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ fontSize: 11, color: 'white' }}> Qnty </div>
      ),
    },
    {
      field: 'SALE_PRICE',
      type: 'number',
      flex: 1,

      editable: true,
      align: 'right',
      valueFormatter: (params) => FormatNumber2(params.value),
      headerClassName: 'super-app-theme--header',
      headerAlign: 'center',
      renderHeader: (params) => (
        <div style={{ fontSize: 11, color: 'white' }}> Rate </div>
      ),
    },
  ];
  const getFilterdItemList = () => {
    dispatch(setSpinnerLoading('Loading item'));
    axios
      .post(
        `/api/itemsToSaleRoute/itemsToSale`,
        {
          table_prefix: prefix,
          account_no: accNumber,
          sid: stationId,
          section_name: selected_Section,

          from_date: moment().format('YYYY-MM-DD'),
          // from_date: "2023-04-03",
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;

          if (!data[0]) {
            dispatch(setNoIssueAlert(true));
          }

          let rowList = [];

          //  -----to check stock available
          for (let index = 0; index < data.length; index++) {
            const balQnty =
              data[index].PRV_BAL + data[index].IQNTY - data[index].SQNTY;
            if (balQnty > 0) {
              if (data[index].SALE_PRICE === null) {
                rowList.push({
                  ...data[index],
                  SALE_PRICE: 0,
                  BQNTY: balQnty,
                  id: index + 1,
                });
              } else {
                rowList.push({
                  ...data[index],
                  BQNTY: balQnty,
                  id: index + 1,
                });
              }
            }
          }

          setFilterdfilterdItemList(rowList);
          if (!selected_Section) {
            dispatch(setAllItemsList(rowList));
          }
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(setAlertMsg(e.message));
          dispatch(setALertStatus(true));
        }
        console.log(e);
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const addItem = (ICODE, qnty) => {
    const index = orderList.findIndex((item) => item.ICODE === ICODE);

    if (index >= 0) {
      const liveQnty = orderList[index].QNTY;
      if (orderList[index].BQNTY > 0) {
        const updatedItem = {
          ...orderList[index],
          QNTY: Number(liveQnty) + 1,
          AMOUNT: (Number(qnty) + 1) * orderList[index].SALE_PRICE,
          BQNTY: orderList[index].BQNTY - 1,
        };
        const updatedItems = [...orderList];
        updatedItems[index] = updatedItem;
        dispatch(updateOrderlist(updatedItems));
        dispatch(setEditQntyStatus(false));
      } else {
        dispatch(setAlertMsg('insufficient balance'));
        dispatch(setALertStatus(true));
      }
    } else {
      dispatch(setAlertMsg(' item index not founded'));
      dispatch(setALertStatus(true));
    }
  };

  const totalAmount = orderList?.reduce((acc, curr) => acc + curr.AMOUNT, 0);
  const TotalQnty = orderList?.reduce((acc, curr) => acc + curr.QNTY, 0);
  const NetAmountw = Number(totalAmount) - Number(selectDiscountedAmount);
  const NetAmount = NetAmountw.toFixed(2);

  const getSalePrice = (box) => {
    setSalePriceLoading(true);
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        `/api/itemsToSaleRoute/partyItemSaleRate`,
        {
          table_prefix: prefix,
          ACCOUNT_NO: accNumber,
          ICODE: box.ICODE,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.response[0]) {
          dispatch(
            setOrderList({
              QNTY: 1,
              AMOUNT: res.data.response[0].SALE_PRICE,
              ICODE: box.ICODE,
              ITEMNAME: box.INAME,
              SECTION_NAME: box.SECTION_NAME,
              SALE_PRICE: res.data.response[0].SALE_PRICE,
              TAX1_RATE: box.TAX1_RATE,
              TAX2_RATE: box.TAX2_RATE,
              TAX3_RATE: box.TAX3_RATE,
              HSN_CODE: box.HSN_CODE,
              CESS_RATE: box.CESS_RATE,
              BASIC_RT: res.data.response[0].SALE_PRICE,
              BQNTY: box.BQNTY - 1,
              TotalBQnty: box.BQNTY,
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Sale Price Not Found.',
            })
          );
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        } else {
          dispatch(setAlertMsg(e.message));
          dispatch(setALertStatus(true));
        }
      })
      .finally(() => {
        setSalePriceLoading(false);
        dispatch(setSpinnerLoading(false));
      });
  };

  useEffect(() => {
    dispatch(clearOrderBillingList());
  }, []);

  useEffect(() => {
    dispatch(setBillingAccountMasterModalStatus(true));
  }, []);

  useEffect(() => {
    if (accNumber) {
      if (!accMobile) {
        dispatch(setAlertMsg('Update mobile number of Party'));
        dispatch(setALertStatus(true));
      }
      dispatch(setBillingDiscount(''));
      getPrevBalance();
    }
  }, [accNumber]);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = allItemList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.ICODE.toString().includes(searchText);
        } else {
          return item.INAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setFilterdfilterdItemList(searchItems);
    }
  }, [searchText]);

  useEffect(() => {
    if (accNumber) {
      GetAllSection(dispatch, headers, stationId, prefix);
    }
  }, [accNumber]);

  useEffect(() => {
    if (accNumber) {
      if (!searchText) {
        getFilterdItemList();
      }
    }
  }, [selected_Section, searchText, accNumber]);

  return (
    <div className=" h-full  flex flex-col   w-full px-2   bg-white ">
      <div className="flex-col mt-2 ">
        <BillingSubHeader />
      </div>

      <div
        style={
          window.innerWidth <= 768 // mobile
            ? {
                height: '75%',
                width: '100%',
                paddingTop: '8px',
              }
            : window.innerWidth <= 1024 // tab laptop
            ? {
                height: `calc(100% - 120px)`,
                width: '100%',
                paddingTop: '8px',
              }
            : {
                // desktop
                height: `calc(100% - 120px)`,
                width: '100%',
                paddingTop: '8px',
              }
        }
        className="flex  "
      >
        {/* 1st columns */}

        <div
          className={
            ' h-full   md:w-1/2  d:w-1/3 w-4/6 overflow-hidden flex flex-col'
          }
        >
          {/* cart items */}
          <div
            style={{
              border: '1px solid #bab5ad ',
              borderRadius: '3px',
            }}
            className="  overflow-hidden flex-1 mb-2"
          >
            <ItemCart
              columns={columns}
              columnsMobileView={columnsMobileView}
              orderList={orderList}
            />
          </div>

          <BillingCalculation
            TotalQnty={TotalQnty}
            totalAmount={totalAmount}
            defaultProfile={defaultProfile}
            dispatch={dispatch}
            NetAmount={NetAmount}
            prevBal={prevBal}
          />
        </div>

        {/* 2nd coloumns */}

        {window.innerWidth >= 764 ? (
          <div className=" flex  flex-1  ">
            <div
              className=" 
          w-full
          l:w-3/4
          d:w-1/2
            pl-2   h-full   flex flex-col   "
            >
              <div className="text-sm text-[#4a4646]   w-full flex justify-between   items-center">
                <div
                  style={{ fontSize: 14 }}
                  className=" text-[#4a4646]  w-full"
                >
                  <div
                    style={{
                      borderWidth: '1px',
                      borderRadius: '3px',
                      padding: '7px',
                    }}
                    className="flex  items-center border-[#bab5ad] bg-white mb-2 w-full  "
                  >
                    <Search
                      sx={{
                        fontSize: '20px',
                        color: 'white',
                      }}
                    />
                    <input
                      type={'text'}
                      value={searchText}
                      style={{ fontSize: 14 }}
                      className="w-full  outline-0 ml-1 bg-white"
                      placeholder="Search Items"
                      onChange={(t) => {
                        SetSearchText(t.target.value);
                      }}
                    ></input>
                  </div>
                </div>
              </div>

              {/* itemsbox */}
              <div className="overflow-hidden h-full  ">
                <ItemBox
                  itemboxLoading={false}
                  accNumber={accNumber}
                  Lottie={Lottie}
                  spinner={spinner}
                  filterdItemList={filterdItemList}
                  salePriceLoading={salePriceLoading}
                  orderList={orderList}
                  addItem={addItem}
                  dispatch={dispatch}
                  getSalePrice={getSalePrice}
                />
              </div>
            </div>
            {/* section */}
            <div
              className="
             md:w-1/2
             l:w-1/4
             d:w-1/2
             w-full  ml-2 -  md:h-full h-1/3  "
              style={{
                border: '1px solid #bab5ad',
                borderRadius: window.innerWidth <= 768 ? '0px' : '3px',
              }}
            >
              <div
                style={{
                  borderRadius: '3px',
                }}
                className="  bg-white shadow-sm border-slate-200 border-b-0 h-full flex flex-col "
              >
                <div
                  style={{ fontSize: 12 }}
                  className=" p-2 grid grid-cols-1 md:grid-cols-1 d:grid-cols-2 gap-1 overflow-y-scroll   scrollbar-hidden  "
                >
                  {SectionList?.map((d, i) => {
                    return (
                      <Button
                        key={d.key}
                        variant={'contained'}
                        style={{
                          textTransform: 'none',
                          color: 'black',
                          letterSpacing: '1px',
                          backgroundColor: '#f5eb82',
                          border:
                            selected_Section === d.Name
                              ? '1px solid black'
                              : '0px solid black',
                        }}
                        onClick={() => {
                          dispatch(setSelectedSection(d.Name));
                        }}
                        className={` text-black    hover:bg-[#ebaf81]  hover:cursor-pointer ${
                          selected_Section === d.Name
                            ? 'bg-[#ebaf81] text-black'
                            : 'bg-[#f5eb82] text-black '
                        } flex items-center p-2  h-12   `}
                      >
                        <p
                          style={
                            window.innerWidth < 770
                              ? { fontSize: 11 }
                              : { fontSize: 15 }
                          }
                          className="pl-2"
                        >
                          {' '}
                          {toProperCase(d.Name)}
                        </p>
                      </Button>
                    );
                  })}
                </div>
                {/* </Collapse> */}
              </div>
            </div>
          </div>
        ) : (
          <div className=" w-2/6  h-full  ml-2   ">
            <div
              style={{
                border: '1px solid #bab5ad',
                borderTopLeftRadius: '3px',
                borderTopRightRadius: '3px',
                borderBottom: '0px solid #bab5ad',
              }}
              className="
        
            h-1/3  "
            >
              {loading ? (
                <div className=" w-full h-full  flex justify-center items-center">
                  <div className="w-10 h-10">
                    <Lottie
                      animationData={spinner}
                      loop={true}
                      style={{ fontSize: 15 }}
                    />
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    borderRadius: '3px',
                  }}
                  className="  bg-white shadow-sm border-slate-200 border-b-0 h-full flex flex-col "
                >
                  <div
                    style={{ fontSize: 12 }}
                    className="  p-2 grid grid-cols-1  gap-1 overflow-y-scroll   scrollbar-hidden  "
                  >
                    {SectionList?.map((d, i) => {
                      return (
                        <div
                          key={d.key}
                          style={{
                            borderRadius: '3px',
                          }}
                          onClick={() => {
                            dispatch(setSelectedSection(d.Name));
                          }}
                          className={` hover:text-black    hover:bg-[#ebaf81]  hover:cursor-pointer ${
                            selected_Section === d.Name
                              ? 'bg-[#ebaf81] text-black'
                              : 'bg-[#f5eb82] text-black '
                          } flex items-center p-2  h-12   `}
                        >
                          <p
                            style={
                              window.innerWidth < 770
                                ? { fontSize: 11 }
                                : { fontSize: 15 }
                            }
                            className="pl-2"
                          >
                            {' '}
                            {toProperCase(d.Name)}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                  {/* </Collapse> */}
                </div>
              )}
            </div>

            {/* // items */}

            <div
              className=" 
      
      h-2/3   flex flex-col   "
            >
              <div className=" text-[#4a4646]    flex justify-between   items-center">
                <div
                  style={{ fontSize: 14 }}
                  className=" text-[#4a4646]  "
                ></div>
              </div>

              {/* itemsbox */}
              <ItemBox
                itemboxLoading={false}
                Lottie={Lottie}
                accNumber={accNumber}
                spinner={spinner}
                filterdItemList={filterdItemList}
                salePriceLoading={salePriceLoading}
                orderList={orderList}
                addItem={addItem}
                dispatch={dispatch}
                getSalePrice={getSalePrice}
              />
            </div>

            {/* section */}
          </div>
        )}
      </div>

      {/* // save Buutone  */}

      <div
        style={{
          height: '86px',
        }}
        className=" w-full  flex  pt-2 pb-1 items-end  flex-col "
      >
        <div className="flex ">
          <div>
            <BillingSave
              onClick={() => {
                if (accNumber) {
                  if (orderList[0]) {
                    dispatch(setBillSattalmentModalStatus(true));
                  } else {
                    dispatch(
                      setAlertMsg(
                        'Please add items to the bill before attempting to save.'
                      )
                    );
                    dispatch(setALertStatus(true));
                  }
                } else {
                  dispatch(setBillingAccountMasterModalStatus(true));
                }
              }}
            />
          </div>

          <div className="w-full pl-1">
            <BillingBackButton
              onClick={() => {
                dispatch(clearOrderBillingList());
                navigate('/');
              }}
            />
          </div>
        </div>
      </div>
      <BillSettelmentModal />
      <BillingAccountMasterDatagridModal />
      <ViewBillModal />
      <ConfirmPrintAlert />
      <BillhistoryModal />
      <ShareBillModal />
      <ModalQntyEdit />
    </div>
  );
};

export default Billing;
