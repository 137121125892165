import { DataGrid, GridPagination } from '@mui/x-data-grid';
import axios from 'axios';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import spinner from '../../../../../utility/json/Spinner.json';

import {
  FormatNumber2,
  FormatNumber3,
} from '../../../../../utility/component/tools';
import { setSessionExpired } from '../../../../../utility/Redux/modal';
import { TotalFooter } from '../../../../../utility/component/FooterDashboard';

import { selectToken } from '../../../../../utility/Redux/security';
import { selectPrefix } from '../../../../../utility/Redux/profile';

import { selectStationWiseIssueApiDetails } from '../../../../../utility/Redux/tableOrder';

const width = (window.innerWidth * 1) / 100;
const columns = [
  {
    field: 'ICODE',
    type: 'string',
    width: width * 4,
    headerClassName: 'super-app-theme--header',
    renderHeader: (params) => (
      <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
        {' '}
        {'Code'}{' '}
      </strong>
    ),
  },
  {
    field: 'INAME',
    type: 'string',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderHeader: (params) => (
      <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
        {' '}
        Item Name{' '}
      </strong>
    ),
  },
  {
    field: 'PRV_BAL',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 9,
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    align: 'right',

    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        Prev. Balance
      </strong>
    ),
  },
  {
    field: 'IQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 9,
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-10 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        Issued Qnty
      </strong>
    ),
  },

  {
    field: 'IAMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 9,
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-12 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        Amount
      </strong>
    ),
  },

  {
    field: 'SQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 9,

    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66]  w-16 leading-4 whitespace-normal "
        style={{ fontSize: 13 }}
      >
        {' '}
        Sales Qnty
      </strong>
    ),
  },
  {
    field: 'SAMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 9,
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    align: 'right',

    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        Sales Amount
      </strong>
    ),
  },

  {
    field: 'BQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 9,
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',

    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        Balance Qnty
      </strong>
    ),
  },
  {
    field: 'BAMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    align: 'right',
    headerAlign: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        Balance Amount
      </strong>
    ),
  },
];
const columnsMobileView = [
  {
    field: 'INAME',
    type: 'string',
    flex: 2,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderHeader: (params) => (
      <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
        {' '}
        Item Name{' '}
      </strong>
    ),
  },

  {
    field: 'IQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    renderHeader: () => (
      <strong
        className="text-[#555a66] w-10 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        Issued Qnty
      </strong>
    ),
  },

  {
    field: 'BQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return params.value;
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    renderCell: (params) => {
      if (params.row.SQNTY) {
        return FormatNumber3(params.row.IQNTY - params.row.SQNTY);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        Balance Qnty
      </strong>
    ),
  },
];

const StationWiseIssueReport = () => {
  const [loading, setloading] = useState(false);
  const stationwiseApiDetails = useSelector(selectStationWiseIssueApiDetails);
  const { stationId, date } = stationwiseApiDetails;
  // const sid = localStorage.getItem("stationId");
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const dispatch = useDispatch();

  const [issueList, SetIssueList] = useState([]);
  const getIssuedData = () => {
    setloading(true);

    axios
      .post(
        `/api/dashBoradReports/issuedToStation`,
        {
          table_prefix: prefix,
          station_id: stationId,
          from_date: date,
          to_date: date,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          let rowList = [];

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
              BQNTY:
                data[index].PRV_BAL + data[index].IQNTY - data[index].SQNTY,
              BAMOUNT:
                (data[index].PRV_BAL + data[index].IQNTY - data[index].SQNTY) *
                data[index].SALE_PRICE,
            });
          }
          SetIssueList(rowList);
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        setloading(false);
      });
  };

  useEffect(() => {
    getIssuedData();
  }, []);
  return (
    <div className="h-full w-full p-3  pb-4 flex  ">
      <div className="w-full ">
        {loading ? (
          <div className=" w-full h-full  flex justify-center items-center">
            <div className="w-10 h-10">
              <Lottie
                animationData={spinner}
                loop={true}
                style={{ fontSize: 15 }}
              />
            </div>
          </div>
        ) : (
          <div className="p-1 mb-2" style={{ height: '100%' }}>
            <DataGrid
              sx={{
                backgroundColor: 'white',
                fontFamily: 'sans-serif',
                fontSize: 13,
                '& .super-app-theme--header': {
                  backgroundColor: '#E6E6E3',
                },
              }}
              rowHeight={33}
              scrollbarSize={0}
              columns={window.innerWidth < 770 ? columnsMobileView : columns}
              rows={issueList ? issueList : []}
              pageSize={100}
              rowsPerPageOptions={[100]}
              experimentalFeatures={{ newEditingApi: true }}
              pagination
              showCellRightBorder={true}
              showColumnRightBorder={true}
              disableColumnSelector
              disableColumnReorder
              disableColumnMenu={true}
              components={{
                Pagination: GridPagination,
                Footer: () => (
                  <TotalFooter
                    rows={issueList ? issueList : []}
                    columns={columns}
                  />
                ),
                NoRowsOverlay: () => {
                  return (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      No Records Found
                    </div>
                  );
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default StationWiseIssueReport;
