import React, { useRef } from 'react';
import {
  ModifyButton,
  PrintButton,
  SaveButton,
  UndoButton,
} from '../utility/component/buttons';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BasicInformation from './basicinformation';
import AddressInformation from './addressInformation';
import { useEffect } from 'react';
import { Country, State, City } from 'country-state-city';
import {
  setAlertWithTitle,
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import ModPartyMasterHelp from './partyMasterHelp';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';
import axios from 'axios';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { useFocus } from '../utility/hooks/hooks';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import PartyList from './partyList';
import { PartyColumns } from './partyColumns';
import GeniusTableForReports from '../utility/component/geniusComponents/geniusTableforReports';
import ReactToPrint from 'react-to-print';

const Party_Master = () => {
  const opBalTypeOption = [
    {
      name: 'Dr',
      value: 'Dr',
    },
    {
      name: 'Cr',
      value: 'Cr',
    },
  ];
  const windowWidth = useSelector(selectWidth);
  const headerRef = useRef();
  const formRef = useRef(null);
  const partyCol = PartyColumns(windowWidth);
  const [id, setId] = useState('');
  const printRef = useRef(null);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [stationName, setStationName] = useState('');
  const prefix = useSelector(selectPrefix);
  const [tabScreen, setTabScreen] = useState(1);
  const [partyName, setPartyName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [gstNo, setGstNo] = useState('');
  const [panNo, setPanNo] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [website, setWebsite] = useState('');
  const [creditAllow, setCreditAllow] = useState('');
  const [creditPeriod, setCreditPeriod] = useState('');
  const [stationId, setStationId] = useState('');
  const [latitude, setlatitude] = useState('123');
  const [longitude, setLongitude] = useState('123');
  const [address, setAddress] = useState('');
  const [pinCode, setPinCode] = useState('');
  const [city, setCity] = useState('');
  const [opening, setOpening] = useState('');
  const [openingType, setOpeningType] = useState(opBalTypeOption[0].value);
  const [currencySymbol, setCurrencySymbol] = useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [fullList, setFullList] = useState([]);
  const [parties, setParties] = useState([]);
  const [stop, setStop] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [stateList, setStatelist] = useState([]);
  const [cityList, setCitylist] = useState([]);

  const handleStateChange = (e) => {
    setSelectedState(e.target.value);
  };

  const countries = Country.getAllCountries();

  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
  };

  useEffect(() => {
    if (selectedCountry) {
      const sCountry = countries.filter(
        (country) => country.name === selectedCountry
      );
      if (sCountry[0]) {
        setStatelist(State.getStatesOfCountry(sCountry[0].isoCode));
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState && stateList[0]) {
      const sState = stateList.filter((state) => state.name === selectedState);
      if (sState[0]) {
        setCitylist(
          City.getCitiesOfState(sState[0].countryCode, sState[0].isoCode)
        );
      }
    }
  }, [selectedState, stateList]);

  const [partyRef, setPartyFocus] = useFocus();
  const [mobileRef, setMobileFocus] = useFocus();
  const [openingRef, setOpeningFocus] = useFocus();
  const [stationRef, setStationFocus] = useFocus();
  const [addressRef, setAddressFocus] = useFocus();
  const phoneRegex = /^[6-9]\d{9}$/;
  const savePartyMasterApi = () => {
    if (
      partyName &&
      mobile &&
      phoneRegex.test(mobile) &&
      address &&
      stationId
    ) {
      const data = {
        table_prefix: prefix,
        ACCOUNT_NO: id ? id : '',
        NAME: partyName,
        TAX_NUMBER: gstNo,
        PAN: panNo,
        ADDRESS: address,
        COUNTRY: selectedCountry,
        CURRENCY_SYMBOL: currencySymbol,
        CURRENCY_NAME: currencyName,
        STATE: selectedState,
        CITY: city,
        PIN_CODE: pinCode,
        PHONE: phoneNo,
        MOBILE: mobile,
        EMAIL: email,
        WEBSITE: website,
        CREDIT_LIMIT_PERIOD: creditPeriod ? Number(creditPeriod) : 0,
        CREDIT_LIMIT_ALLOWED: Number(creditAllow),
        LATITUDE: latitude,
        LONGITITUDE: longitude,
        OPENING_BALANCE: opening ? opening : 0,
        OPENING_BALANCE_TYPE: openingType,

        SID: stationId,
      };
      {
        id
          ? dispatch(setSpinnerLoading('Updating'))
          : dispatch(setSpinnerLoading('Saving'));
      }
      console.log(data);
      axios
        .post(`/api/issueMasterRoute/saveAccountMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          clearState();
        });
    } else {
      if (!address) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Address Choose Address & Contact.',
            lottie: 'reject',
          })
        );
        setAddressFocus();
      }

      if (!stationId) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Select Station Id.',
            lottie: 'reject',
          })
        );
        setStationFocus();
      }
      if (!mobile) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter mobile',
            lottie: 'reject',
          })
        );
        setMobileFocus();
      }
      if (mobile && !phoneRegex.test(mobile)) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please enter valid mobile',
            lottie: 'reject',
          })
        );
        setMobileFocus();
      }

      if (!partyName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Party.',
            lottie: 'reject',
          })
        );

        setPartyFocus();
      }
    }
  };

  const clearState = () => {
    setId('');
    setPartyName('');
    setGstNo('');
    setPanNo('');
    setPhoneNo('');
    setMobile('');
    setEmail('');
    setWebsite('');
    setCreditAllow('');
    setCreditPeriod('');
    setStationId('');
    setlatitude('');
    setLongitude('');
    setAddress('');
    setSelectedCountry('');
    setSelectedState('');
    setOpening('');
    setOpeningType(opBalTypeOption[0].value);
    setCity('');
    setPinCode('');
    setCurrencySymbol('');
    setCurrencyName('');
    setAccountNo('');
    setStationName('');
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.altKey && e.key === 's') {
        e.preventDefault();
        savePartyMasterApi();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    id,
    partyName,
    gstNo,
    panNo,
    phoneNo,
    mobile,
    email,
    website,
    creditAllow,
    creditPeriod,
    opening,
    openingType,
    stationId,
    address,
    city,
    selectedCountry,
    selectedState,
    stop,
    accountNo,
  ]);

  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/masters');
  };

  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7]  py-8 max-sm:py-2 overflow-y-scroll">
      <div style={{ borderRadius: '12px' }} className=" h-full">
        <div ref={headerRef}>
          <ModalHeaderStyle header={'Party Master'} onClose={handleClose} />
        </div>
        <div className="flex flex-col ">
          <div className=" h-full bg-white px-8 py-5 flex flex-col max-sm:px-2">
            <div className="h-full">
              <div
                style={{
                  border: '1px solid #b5b3b3',
                }}
                className=" w-full  rounded-sm flex p-[1px]"
              >
                <div
                  onClick={() => {
                    setTabScreen(1);
                  }}
                  style={{
                    backgroundColor: tabScreen === 1 ? '#0d0d0d' : 'white',
                    color: tabScreen === 1 ? 'white' : 'gray',
                  }}
                  className="flex-1 p-2   flex justify-center items-center rounded-sm "
                >
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: tabScreen === 1 ? 600 : 400,
                    }}
                    className=" h-full flex items-center"
                  >
                    Basic Info
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: tabScreen === 2 ? '#0d0d0d' : 'white',

                    color: tabScreen === 2 ? 'white' : 'gray',
                  }}
                  onClick={() => {
                    setTabScreen(2);
                  }}
                  className="flex-1 p-2 flex justify-center rounded-sm"
                >
                  <div
                    style={{
                      fontSize: '16px',
                      fontWeight: tabScreen === 2 ? 600 : 400,
                    }}
                    className="  flex items-center"
                  >
                    Address
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: tabScreen === 3 ? '#0d0d0d' : 'white',

                    color: tabScreen === 3 ? 'white' : 'gray',
                  }}
                  onClick={() => {
                    setTabScreen(3);
                  }}
                  className="flex-1 p-2 flex justify-center rounded-sm "
                >
                  <div
                    style={{
                      fontWeight: tabScreen === 3 ? 600 : 400,

                      fontSize: '16px',
                    }}
                    className="  flex items-center"
                  >
                    Party List
                  </div>
                </div>
              </div>
              <div className=" flex flex-col h-[470px]  ">
                <div className="h-full">
                  {tabScreen === 1 && (
                    <BasicInformation
                      partyName={partyName}
                      setPartyName={setPartyName}
                      accountNo={accountNo}
                      opBalTypeOption={opBalTypeOption}
                      setStationName={setStationName}
                      setAccountNo={setAccountNo}
                      gstNo={gstNo}
                      partyRef={partyRef}
                      mobileRef={mobileRef}
                      openingRef={openingRef}
                      stationRef={stationRef}
                      setGstNo={setGstNo}
                      panNo={panNo}
                      setPanNo={setPanNo}
                      phoneNo={phoneNo}
                      setPhoneNo={setPhoneNo}
                      mobile={mobile}
                      stationName={stationName}
                      setMobile={setMobile}
                      setStationId={setStationId}
                      email={email}
                      setEmail={setEmail}
                      website={website}
                      setWebsite={setWebsite}
                      creditAllow={creditAllow}
                      creditPeriod={creditPeriod}
                      setCreditAllow={setCreditAllow}
                      setCreditPeriod={setCreditPeriod}
                      stationId={stationId}
                      opening={opening}
                      setOpening={setOpening}
                      openingType={openingType}
                      setOpeningType={setOpeningType}
                      formRef={formRef}
                      setTabScreen={setTabScreen}
                    />
                  )}
                  {tabScreen === 2 && (
                    <AddressInformation
                      address={address}
                      setAddress={setAddress}
                      selectedState={selectedState}
                      setSelectedState={setSelectedState}
                      stateList={stateList}
                      setStatelist={setStatelist}
                      cityList={cityList}
                      city={city}
                      setCity={setCity}
                      setCitylist={setCitylist}
                      handleCountryChange={handleCountryChange}
                      countries={countries}
                      pinCode={pinCode}
                      setPinCode={setPinCode}
                      handleStateChange={handleStateChange}
                      selectedCountry={selectedCountry}
                      stop={stop}
                      setStop={setStop}
                      currencyName={currencyName}
                      currencySymbol={currencySymbol}
                      setCurrencyName={setCurrencyName}
                      setCurrencySymbol={setCurrencySymbol}
                      setTabScreen={setTabScreen}
                      formRef={formRef}
                      addressRef={addressRef}
                    />
                  )}
                  {tabScreen === 3 && (
                    <PartyList
                      fullList={fullList}
                      setFullList={setFullList}
                      parties={parties}
                      setParties={setParties}
                      stationId={stationId}
                      stationName={stationName}
                      setStationId={setStationId}
                      setStationName={setStationName}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              borderTop: '1px solid #D2D2D7',
            }}
            className="flex justify-end bg-white py-5 rounded-b-sm  px-4 "
          >
            <div className="flex justify-end items-center h-full max-sm:justify-between">
              <div className="mr-1 ">
                {id ? (
                  <ModifyButton onClick={savePartyMasterApi} />
                ) : (
                  <SaveButton onClick={savePartyMasterApi} />
                )}
              </div>
              {tabScreen === 3 && (
                <ReactToPrint
                  trigger={() => <PrintButton />}
                  content={() => printRef.current}
                />
              )}{' '}
              <div
                ref={printRef}
                className="  hidden print:flex justify-between  w-full h-full  "
              >
                <GeniusTableForReports
                  columns={partyCol}
                  data={fullList}
                  customeHeader={
                    <div>{`Party List of ${
                      stationId ? stationId : 'All'
                    } Station ID`}</div>
                  }
                />
              </div>
              <UndoButton onClick={clearState} />
            </div>

            <ModPartyMasterHelp
              onRowClick={(p) => {
                setPartyName(p.row.NAME);
                setAccountNo(p.row.ACCOUNT_NO);
                setGstNo(p.row.TAX_NUMBER);
                setMobile(p.row.MOBILE);
                setEmail(p.row.EMAIL);
                setWebsite(p.row.WEBSITE);
                setCreditAllow(p.row.CREDIT_LIMIT_ALLOWED);
                setCreditPeriod(p.row.CREDIT_LIMIT_PERIOD);
                setStationId(p.row.SID);
                setlatitude(p.row.LATITUDE);
                setLongitude(p.row.LONGITUDE);
                setAddress(p.row.ADDRESS);
                setStationName(p.row.SNAME);
                setCity(p.row.CITY);
                setSelectedState(p.row.STATE);
                setSelectedCountry(p.row.COUNTRY);
                setPinCode(p.row.PIN_CODE);
                setId(p.row.ACCOUNT_NO);
                setOpeningType(p.row.OPENING_BALANCE_TYPE);
                setOpening(p.row.OPENING_BALANCE);
                dispatch(setModPartyMasterHelpStatus(false));
              }}
              onClose={() => {
                dispatch(setModPartyMasterHelpStatus(false));
              }}
            />
            <ModListStationMaster
              onRowClick={(params) => {
                setStationId(params.row.CODE);
                setStationName(params.row.STATION_NAME);
                dispatch(setListStationMasterModalStatus(false));
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Party_Master;
