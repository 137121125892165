import { DataGrid } from '@mui/x-data-grid';
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertWithTitle,
  setIssueStockItem,
  setSessionExpired,
  setSpinnerLoading,
  setloading,
  setnumericKeyboardStatus,
  setnumericKeyboardType,
} from '../../Redux/modal';
import axios from 'axios';
import {
  selectLatitude,
  selectLongitude,
  selectPrefix,
  selectWidth,
} from '../../Redux/profile';
import moment from 'moment';
import { selectToken } from '../../Redux/security';
import { FormatNumber2 } from '../tools';
import ExpenseFooter from '../expenseFooter';
import AlertModalTwoButton from './alertWithTwoButton';
import { Close } from '@mui/icons-material';
import ModNumericKeyboard from '../../../routeBilling/menu/dashboard/biiling/modal/modalNumericKeyBoard';
import GeniusDatagrid from '../datagrid/geniusDatagrid';

const DailyExpense = ({
  stationId,
  stationName,
  setExpense,
  handleClose,
  date,
}) => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [editType, setEditType] = useState('');
  const windowWidth = useSelector(selectWidth);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const deleteVoucher = (voucher) => {
    setAlertTwoButtonModal({
      ...alertTwoButtonModal,
      status: false,
    });
    const data = {
      table_prefix: prefix,
      VOUCHER_NO: voucher,
    };
    dispatch(setloading('Loading'));
    axios
      .post(
        `/api/voucherRoute/deleteRowVoucherGrid`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (!response.data.error) {
          const data = response.data.response;
        }

        if (!response.data.error) {
          setList((p) =>
            p.map((p) => {
              if (p.VOUCHER_NO === voucher) {
                return {
                  ...p,
                  AMOUNT_PAID: 0,
                };
              } else {
                return {
                  ...p,
                };
              }
            })
          );
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const columns = [
    {
      width: windowWidth >= 768 ? 50 : 50,
      headerAlign: 'center',
      align: 'left',
      field: 'id',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width: windowWidth >= 768 ? 150 : 100,

      headerAlign: 'center',
      align: 'left',
      field: 'EXPENCE_NAME',
      renderHeader: (params) => <div>Expense</div>,
    },
    {
      width: windowWidth >= 768 ? 200 : 100,

      field: 'AMOUNT_DESC',
      renderHeader: (params) => <div>Description</div>,
      headerAlign: 'center',
      align: 'left',
      editable: true,

      hide: windowWidth < 768,
    },
    {
      width: windowWidth >= 768 ? 130 : 60,

      field: 'PAY_MODE',
      renderHeader: (params) => <div>Pay Mode</div>,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const index = list.findIndex((item) => item.id === params.row.id);
        return (
          <select
            id="countryDropdown"
            value={list[index].PAY_MODE}
            onChange={(e) => {
              const updatedItem = {
                ...list[index],
                PAY_MODE: e.target.value,
                AMOUNT_DESC: `Amount Paid by ${e.target.value}`,
              };
              const updatedItems = [...list];
              updatedItems[index] = updatedItem;
              setList(updatedItems);
            }}
            className="w-full text-sm t:text-t lg:text-sm pl-2   focus:outline-none border border-dotted border-[#b5b3b3] border-l-0 focus:border-solid focus:border-blue-300 text-[#4a4a4a]"
            style={{
              borderRadius: '0px 7px 7px 0px',
              border: '0px dotted #b5b3b3',
              backgroundColor: 'transparent',
              color: '#4a4a4a',
            }}
          >
            {['CASH', 'CARD', 'EPayment'].map((country, index) => (
              <option key={index} value={country} className="text-sm">
                {country}
              </option>
            ))}
          </select>
        );
      },
    },
    {
      width: windowWidth >= 768 ? 100 : 60,
      editable: true,

      field: 'AMOUNT_PAID',
      renderHeader: (params) => <div>Amount</div>,
      headerAlign: 'center',
      renderCell: (param) => {
        if (param.value) {
          return (
            <div className="flex  ">
              <div> {FormatNumber2(param.value)}</div>
              {param.row.VOUCHER_NO > 0 && (
                <button>
                  <Close
                    onClick={() => {
                      setAlertTwoButtonModal({
                        title: 'Alert',
                        msg: 'Are you sure you want to delete this Item',
                        status: true,
                        button1: 'Yes',
                        button1Click: () => {
                          deleteVoucher(param.row.VOUCHER_NO);
                        },
                        button2: 'Cancel',
                        button2Click: () =>
                          setAlertTwoButtonModal({
                            ...alertTwoButtonModal,
                            status: false,
                          }),
                      });
                    }}
                    color="red"
                    sx={{
                      color: 'red',
                      marginLeft: '8px',
                      fontSize: '18px',
                    }}
                  />
                </button>
              )}
            </div>
          );
        } else {
          return '';
        }
      },
      align: 'right',
      editable: true,
    },
  ];

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const getCollection = () => {
    const data = {
      table_prefix: prefix,
      SID: stationId,
      DATE: moment(date).format('YYYY-MM-DD'),
    };
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        `/api/dayEndSumRoute/dailyExpenceSum`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (!response.data.error) {
          let rowlist = [];
          const data = response.data.response;
          for (let index = 0; index < data.length; index++) {
            rowlist.push({
              ...data[index],
              id: index,
              PAY_MODE: 'CASH',
            });
          }
          setList(rowlist);
        }

        if (!response.data.error) {
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Something Went Wrong!',
          })
        );
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const latitude = useSelector(selectLatitude);
  const longitude = useSelector(selectLongitude);
  const handleSave = () => {
    const l = [];
    for (let index = 0; index < list.length; index++) {
      if (list[index].AMOUNT_PAID > 0) {
        l.push({
          DATE: moment(date).format('YYYY-MM-DD HH:mm:ss'),
          PAY_MODE: list[index].PAY_MODE,
          CARD_NO: '',
          VOUCHER_NO: list[index].VOUCHER_NO ? list[index].VOUCHER_NO : '',
          BANK_NAME: '',
          EPAYMENT_BANK: '',
          ACCOUNT_NO: 0,
          NAME: '',
          EXPENCE_CODE: list[index].CODE,
          ADDRESS: '',
          MOBILE: '',
          AMOUNT_RECEIVED: 0,
          AMOUNT_PAID: list[index].AMOUNT_PAID,
          AMOUNT_DESC: `Amount Paid by ${list[index].PAY_MODE}`,
          INVNO: '0',
          SID: stationId,
          SNAME: stationName,
          EXPENCE_NAME: list[index].EXPENCE_NAME,
          LATITUDE: latitude ? latitude : '38.8951',
          LONGITUDE: longitude ? longitude : '-77.0364',
          APPROVED: 'NO',
        });
      }
    }
    if (l[0]) {
      const data = {
        table_prefix: prefix,
        cart_item: l,
      };
      dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/voucherRoute/saveExpenceVoucher`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (!res.data.error) {
            dispatch(
              setAlertWithTitle({
                title: 'Success',
                msg: 'Data is Saved',
                lottie: 'success',
              })
            );
            setExpense(
              list.reduce(
                (total, row) => Number(total) + Number(row.AMOUNT_PAID),
                0
              )
            );
            handleClose();
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Something went wrong',
                lottie: 'reject',
              })
            );
          }
        })
        .catch((e) => {
          console.log(e);

          if (e.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Something went wrong',
                lottie: 'reject',
              })
            );
          }
          console.log(e);
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      setExpense(
        list.reduce((total, row) => Number(total) + Number(row.AMOUNT_PAID), 0)
      );
      handleClose();
    }
  };

  const cellEdit = (e) => {
    if (e.field === 'AMOUNT_PAID') {
      setList((l) =>
        l.map((column) => {
          if (column.id === e.id) {
            return {
              ...column,
              AMOUNT_PAID: Number(e.value),
              PAY_MODE: column.PAY_MODE,
              AMOUNT_DESC: `Amount Received by ${column.PAY_MODE}`,
            };
          } else {
            return column;
          }
        })
      );
    } else {
      if (e.field === 'AMOUNT_DESC') {
        setList((l) =>
          l.map((column) => {
            if (column.id === e.id) {
              return {
                ...column,
                AMOUNT_DESC: e.value,
              };
            } else {
              return column;
            }
          })
        );
      }
    }
  };

  useEffect(() => {
    getCollection();
  }, []);
  return (
    <div className="h-full p-2 ">
      <div
        style={{
          height: window.innerHeight * 0.6,
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            20,
        }}
      >
        <GeniusDatagrid
          rowID={(row) => row.id}
          columns={columns}
          list={list}
          editCell={cellEdit}
          onDoubleClick={(e) => {
            if (e.field === 'AMOUNT_PAID') {
              dispatch(setnumericKeyboardType(e.field));
              dispatch(
                setIssueStockItem({
                  title: e.row.EXPENCE_NAME,
                  value: e.row.AMOUNT_PAID,
                  id: e.id,
                })
              );
              dispatch(setnumericKeyboardStatus(true));
            }
          }}
        />{' '}
      </div>
      <ExpenseFooter
        rows={list}
        columns={columns}
        setExpense={setExpense}
        handleSave={handleSave}
        handleUndo={() => console.log('UNDO')}
      />

      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
      <ModNumericKeyboard
        onChange={cellEdit}
        editType={editType}
        mode={'dark'}
      />
    </div>
  );
};

export default DailyExpense;
