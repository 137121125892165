import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import {
  setAlertWithTitle,
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { SaveButton, UndoButton } from '../utility/component/buttons';
import GeniustextInput from '../utility/component/mastertext';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { FormatNumber2 } from '../utility/component/tools';
import { useEffect } from 'react';
import axios from 'axios';
import ModPartyMasterHelp from './partyMasterHelp';
import AlertModalTwoButton from '../utility/component/modals/alertWithTwoButton';
import { toProperCase } from '../utility/component/format';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';

const Party_Rate_Master = () => {
  const headerRef = useRef();
  const formRef = useRef();
  const [selectAll, setSelectAll] = useState(false);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const windowWidth = useSelector(selectWidth);

  const [id, setId] = useState();
  const [rows, setRows] = useState([]);
  const [saveCartList, setSaveCartList] = useState([]);
  const [partyMasterName, setPartyName] = useState('');
  const [accountNo, setAccountNo] = useState('');
  const [address, setAddress] = useState('');
  const [mobile, setMobile] = useState('');
  const [stationId, setStationId] = useState('');
  const [stationName, setStationName] = useState('');
  const width = useSelector(selectWidth);

  const columns = [
    {
      width: width < 764 ? 70 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width: width < 764 ? 100 : 200,
      headerAlign: 'center',
      align: 'left',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width: width < 764 ? 70 : 100,
      headerAlign: 'center',
      align: 'right',
      field: 'MRP',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>MRP</div>,
    },
    {
      width: width < 764 ? 70 : 100,
      headerAlign: 'center',
      align: 'right',
      field: 'SALE_PRICE',
      headerName: 'Rate',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: (params) => <div>Rate</div>,
      editable: 'true',
    },

    {
      width: width < 764 ? 70 : 100,
      field: 'selection',
      renderHeader: () => <div>Select</div>,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={saveCartList.some((row) => row.ICODE === params.row.ICODE)}
            // isSelected={checkk}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const columnsMobile = [
    {
      width: 50,
      headerAlign: 'center',
      align: 'center',
      field: 'ICODE',
      headerName: 'Code',
      renderHeader: (params) => <div>Code</div>,
    },
    {
      width: 90,
      headerAlign: 'center',
      align: 'center',
      field: 'ITEMNAME',
      headerName: 'Item Name',
      renderHeader: (params) => <div>Item Name</div>,
    },
    {
      width: 60,
      headerAlign: 'center',
      align: 'center',
      field: 'MRP',
      headerName: 'Rate',
      renderHeader: (params) => <div>MRP</div>,
      editable: 'true',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
    },
    {
      width: 60,
      headerAlign: 'center',
      align: 'center',
      field: 'SALE_PRICE',
      headerName: 'Rate',
      renderHeader: (params) => <div>Rate</div>,
      editable: 'true',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
    },
    {
      flex: 1,
      field: 'selection',
      renderHeader: () => <div>Select</div>,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={saveCartList.some((row) => row.ICODE === params.row.ICODE)}
            // isSelected={checkk}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
      align: 'center',
      headerAlign: 'center',
    },
  ];

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const getData = async () => {
    const data = {
      table_prefix: prefix,
      ACCOUNT_NO: accountNo,
    };
    // dispatch(setSpinnerLoading('Loading'));
    dispatch(setSpinnerLoading('Loading'));
    await axios
      .post('/api/issueMasterRoute/loadPartyPriceList', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      .then((p) => {
        if (!p.data.error) {
          const record = p.data.response;

          let list = [];

          if (record) {
            for (let i = 0; i < record.length; i++) {
              list.push({
                ...record[i],
                ACCOUNT_NO: accountNo,
                NAME: partyMasterName,
                SID: stationId,
                STATION_NAME: stationName,
              });
            }
          }
          setRows(list);
          setSaveCartList(() => {
            const d = record.filter((e) => e.SID && e.SID > 0);
            return d.map((e) => {
              return {
                ...e,
                SID: String(e.SID),
                NAME: partyMasterName,
              };
            });
          });

          // setUpdatedRow(list);
        }
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (partyMasterName) {
      getData();
    }
  }, [partyMasterName]);

  useEffect(() => {
    if (selectAll) {
    }
  }, [selectAll]);
  const updateRowsData = ({ id, field, value }) => {
    if (field === 'SALE_PRICE') {
      const list = rows.map((row, i) => {
        if (row.ICODE === id) {
          return {
            ...row,
            SALE_PRICE: Number(value),
          };
        } else {
          return {
            ...row,
          };
        }
      });

      setRows(list);
      setSaveCartList((p) => {
        const oldDataWithSameItem = p.filter((item) => item.ICODE === id);
        const oldDataWithOutSameitem = p.filter((item) => item.ICODE !== id);
        const inRowItemDetails = rows.filter((item) => item.ICODE === id);
        if (oldDataWithSameItem[0]) {
          return [
            ...oldDataWithOutSameitem,
            {
              ...oldDataWithSameItem[0],
              SALE_PRICE: Number(value),
            },
          ];
        } else {
          return [
            ...oldDataWithOutSameitem,
            {
              ...inRowItemDetails[0],
              SALE_PRICE: Number(value),
            },
          ];
        }
      });
    }
  };
  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      console.log(event.target.checked);
      setSaveCartList((p) => {
        return [
          ...p,
          {
            ...row,
          },
        ];
      });
    } else {
      setSaveCartList((p) => p.filter((sf) => sf.ICODE !== row.ICODE));
    }
  };

  const savePartyRateMasterApi = async () => {
    if (partyMasterName && accountNo && saveCartList[0]) {
      dispatch(setSpinnerLoading('Saving'));
      axios
        .post(
          `/api/issueMasterRoute/createPartyRateMaster`,

          {
            table_prefix: prefix,
            cartItems: saveCartList,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async function (response) {
          if (!response.data.error) {
            setAlertTwoButtonModal({
              ...alertTwoButtonModal,
              status: true,
              title: 'Success',
              msg: 'Your Data has been saved successfully',
              button1: 'Ok',

              button1Click: () => {
                setAlertTwoButtonModal({
                  ...alertTwoButtonModal,
                  status: false,
                });
                clearState();
              },
            });
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Something Went Wrong!',
              })
            );
          }
        })
        .catch(function (error) {
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: error.response.statusText,
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      if (!partyMasterName) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Please Select Party For Save Record!',
          })
        );
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Please Select Items For Save Record!',
          })
        );
      }
    }
  };

  const clearState = () => {
    setPartyName('');
    setAddress('');
    setMobile('');
    setAccountNo('');
    setStationId('');
    setStationName('');
    setSaveCartList([]);
    setRows([]);
  };

  const handlePartyHelp = () => {
    if (stationId) {
      dispatch(setModPartyMasterHelpStatus(true));
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please select SID first to select Party Name',
        })
      );
    }
  };

  // useEffect(() => {
  //   if (!partyMasterName) {
  //     handlePartyHelp();
  //   }
  // }, [partyMasterName]);

  const selectAllFunc = (event) => {
    console.log(event.target.checked);

    if (event.target.checked) {
      setSelectAll(true);
      rows.map((row) => {
        setSaveCartList((p) => {
          return [
            ...p,
            {
              ...row,
            },
          ];
        });
      });
    } else {
      setSelectAll(false);

      rows.map((ro) => {
        setSaveCartList((p) => p.filter((sf) => sf.ICODE !== ro.ICODE));
      });
    }
  };
  const navigate = useNavigate();
  const handleClose = () => {
    navigate('/masters');
  };

  return (
    <div className="w-full h-full flex justify-center p-8 bg-[#f5f5f7]  overflow-y-scroll">
      <div style={{ borderRadius: '12px' }}>
        <div ref={headerRef}>
          <ModalHeaderStyle
            header={'Create Party Rate Master'}
            onClose={handleClose}
          />
        </div>

        <div className=" bg-white px-8 py-5 flex flex-col max-sm:px-2">
          <div>
            <div className=" grid grid-cols-2  gap-2">
              <GeniustextInput
                title={'SID'}
                width={windowWidth > 764 ? '300px' : '150px'}
                type={'text'}
                search={() => {
                  dispatch(setListStationMasterModalStatus(true));
                }}
                readOnly={true}
                autofocus={true}
                value={stationId}
              />
              <div>
                <GeniustextInput
                  title={'SID Name'}
                  width={windowWidth > 764 ? '300px' : '150px'}
                  type={'text'}
                  value={stationName}
                />
              </div>
            </div>

            <div className=" grid grid-cols-2  gap-2">
              <GeniustextInput
                title={'Party Name'}
                width={windowWidth > 764 ? '300px' : '150px'}
                type={'text'}
                fontBold={600}
                value={partyMasterName}
                search={handlePartyHelp}
                onChange={(e) => {
                  setPartyName(toProperCase(e.target.value));
                }}
              />
              <div>
                <GeniustextInput
                  title={'Account No.'}
                  width={windowWidth > 764 ? '100px' : '100px'}
                  type={'text'}
                  value={accountNo}
                  onChange={(e) => {
                    setAccountNo(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className=" grid grid-cols-2  gap-2">
              <GeniustextInput
                title={'Address'}
                width={windowWidth > 764 ? '300px' : '150px'}
                type={'text'}
                value={address}
                onChange={(e) => {
                  setAddress(toProperCase(e.target.value));
                }}
              />
              <div>
                <GeniustextInput
                  title={'Mobile'}
                  width={windowWidth > 764 ? '300px' : '150px'}
                  type={'number'}
                  error={'mobile'}
                  value={mobile}
                  onChange={(e) => {
                    setMobile(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width:
                  columns[0].width +
                  columns[1].width +
                  columns[2].width +
                  columns[3].width +
                  columns[4].width +
                  30,
              }}
              className="w-full ll:h-[400px] h-[240px] mt-1 flex"
            >
              <DataGrid
                hideFooterPagination
                onRowClick={(p) => {
                  setId(p.row.CODE);
                }}
                headerHeight={0}
                rowHeight={30}
                showCellRightBorder={true}
                showColumnRightBorder={true}
                disableColumnMenu={true}
                hideFooter
                getRowId={(p) => p.ICODE}
                renderCell
                components={{
                  Header: () => {
                    const headerDetails =
                      windowWidth > 764 ? columns : columnsMobile;
                    return (
                      <div
                        className="  bg-[#383535] flex text-white text-lg "
                        style={{
                          borderTopLeftRadius: '3px',
                          borderTopRightRadius: '3px',
                          letterSpacing: '1px',
                        }}
                      >
                        {headerDetails?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="  text-#4a4a4a flex justify-center items-center py-2  "
                              style={
                                item.flex
                                  ? index < headerDetails.length - 1
                                    ? {
                                        flex: item.flex,
                                        borderRight: '1px solid #b5b3b3',
                                        borderBottom: '1px solid #b5b3b3',
                                      }
                                    : {
                                        flex: item.flex,
                                        borderBottom: '1px solid #b5b3b3',
                                      }
                                  : index < headerDetails.length - 1
                                  ? {
                                      width: item.width,

                                      borderRight: '1px solid #b5b3b3',
                                      borderBottom: '1px solid #b5b3b3',
                                    }
                                  : {
                                      width: item.width,
                                      borderBottom: '1px solid #b5b3b3',
                                    }
                              }
                            >
                              {item.field === 'selection' ? (
                                <div className="flex items-center">
                                  <div>Select</div>
                                  <div className="flex items-center ml-2">
                                    <input
                                      type="checkbox"
                                      checked={selectAll}
                                      isSelected={selectAll}
                                      onChange={selectAllFunc}
                                    />
                                  </div>
                                </div>
                              ) : (
                                item.renderHeader()
                              )}
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                  NoRowsOverlay: () => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        No Records Found
                      </div>
                    );
                  },
                }}
                sx={
                  window.innerWidth < 770
                    ? {
                        backgroundColor: 'white',

                        fontSize: 12,
                        '& .super-app-theme--header': {
                          backgroundColor: '#f5f0e8',
                          display: 'none',
                        },
                        borderTop: '1px solid #b5b3b3',
                        borderRadius: 0,
                      }
                    : {
                        backgroundColor: 'white',
                        fontSize: 13,
                        borderTop: '1px solid #b5b3b3',
                        borderRadius: '3.5px',
                        '& .super-app-theme--header': {
                          backgroundColor: '#f5f0e8',
                          borderRight: `1px solid #b5b3b3`,

                          display: 'none',
                        },
                      }
                }
                columns={windowWidth > 764 ? columns : columnsMobile}
                rows={rows}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableColumnSelector
                disableColumnReorder
                onCellEditCommit={updateRowsData}
              />
            </div>
          </div>
        </div>

        <div
          style={{
            borderTop: '1px solid #D2D2D7',
          }}
          className="flex justify-end bg-white py-5 rounded-b-sm  px-4 "
        >
          <div className="flex justify-end items-center h-full ">
            <div className="mr-1 ">
              <SaveButton onClick={savePartyRateMasterApi} />
            </div>
            <UndoButton onClick={clearState} />
          </div>
        </div>

        <ModPartyMasterHelp
          SID={stationId}
          onRowClick={(p) => {
            setPartyName(p.row.NAME);
            setAddress(p.row.ADDRESS);
            setMobile(p.row.MOBILE);
            setAccountNo(p.row.ACCOUNT_NO);
            setStationId(p.row.SID);
            setStationName(p.row.SNAME);
            setSelectAll(true);
            setSaveCartList([]);
            setRows([]);
            dispatch(setModPartyMasterHelpStatus(false));
          }}
          onClose={() => {
            dispatch(setModPartyMasterHelpStatus(false));
            navigate();
          }}
        />
        <AlertModalTwoButton
          details={alertTwoButtonModal}
          setAlertTwoButtonModal={setAlertTwoButtonModal}
        />
        <ModListStationMaster
          onRowClick={(p) => {
            setStationId(p.row.CODE);
            setStationName(p.row.STATION_NAME);
            setPartyName('');
            setAddress('');
            setMobile('');
            setAccountNo('');
            setRows([]);
            setSelectAll(true);
            setSaveCartList([]);
            dispatch(setListStationMasterModalStatus(false));
          }}
        />
      </div>
    </div>
  );
};

export default Party_Rate_Master;
