import React, { useEffect, useRef, useState } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { selectToken } from '../utility/Redux/security';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import {
  setAlertWithTitle,
  setItemMasterHelpStatus,
  setListStationMasterModalStatus,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import { SaveButton, UndoButton } from '../utility/component/buttons';
import ItemMasterHelp from '../master/iteemMasterHelp';
import { DataGrid } from '@mui/x-data-grid';
import ModListStationMaster from '../utility/component/modals/modListStationMaster';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';

const UpdatepartyItemRate = () => {
  const headerRef = useRef();
  const [list, setList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [itemName, setItemName] = useState('');
  const [itemCode, setItemCode] = useState('');
  const [mrp, setMrp] = useState('');
  const [sName, setSName] = useState('');
  const [saveCartList, setSaveCartList] = useState([]);
  const [SalePrice, setSalePrice] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [fullList, setFullList] = useState([]);
  const [stationId, setStationId] = useState('');
  const windowWidth = useSelector(selectWidth);
  const [stationName, setStationName] = useState('');
  const handleCheckboxChange = (event, row) => {
    if (event.target.checked) {
      setSaveCartList((p) => {
        return [
          ...p,
          {
            ACCOUNT_NO: row.ACCOUNT_NO,
            NAME: row.NAME,
            ICODE: itemCode,
            ITEMNAME: itemName,
            SNAME: sName,
            SALE_PRICE: SalePrice ? SalePrice : 0,
            MRP: mrp,
            SID: row.SID,
            STATION_NAME: row.SNAME,
          },
        ];
      });
    } else {
      setSaveCartList((p) =>
        p.filter((sf) => sf.ACCOUNT_NO !== row.ACCOUNT_NO)
      );
    }
  };

  const selectAllFuncFromAPI = (l) => {
    setSelectAll(true);
    l.map((row) => {
      setSaveCartList((p) => {
        return [
          ...p,
          {
            ACCOUNT_NO: row.ACCOUNT_NO,
            NAME: row.NAME,
            ICODE: itemCode,
            ITEMNAME: itemName,
            SNAME: sName,
            MRP: mrp,
            SALE_PRICE: SalePrice ? SalePrice : 0,

            SID: row.SID,
            STATION_NAME: row.SNAME,
          },
        ];
      });
    });
  };
  const selectAllFunc = (event) => {
    if (event.target.checked) {
      setSelectAll(true);
      list.map((row) => {
        setSaveCartList((p) => {
          return [
            ...p,
            {
              ACCOUNT_NO: row.ACCOUNT_NO,
              NAME: row.NAME,
              ICODE: itemCode,
              ITEMNAME: itemName,
              SNAME: sName,
              MRP: mrp,
              SALE_PRICE: SalePrice ? SalePrice : 0,

              SID: row.SID,
              STATION_NAME: row.SNAME,
            },
          ];
        });
      });
    } else {
      setSelectAll(false);
      setSaveCartList([]);
    }
  };
  const columns = [
    {
      field: 'ACCOUNT_NO',
      width: windowWidth <= 768 ? 80 : 110,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Acc. No.'}</strong>
      ),
    },
    {
      field: 'SID',
      width: windowWidth <= 768 ? 80 : 90,
      align: 'left',

      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Station id'}</strong>
      ),
    },

    {
      field: 'NAME',
      width: windowWidth <= 768 ? 150 : 300,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Party Name'}</strong>
      ),
    },
    {
      field: 'MOBILE',
      width: windowWidth <= 768 ? 120 : 120,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Mobile'}</strong>
      ),
    },
    {
      field: 'ADDRESS',
      width: windowWidth <= 768 ? 120 : 180,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Address'}</strong>
      ),
    },

    {
      field: 'Select',
      width: windowWidth <= 768 ? 70 : 75,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={saveCartList.some(
              (row) => row.ACCOUNT_NO === params.row.ACCOUNT_NO
            )}
            // isSelected={checkk}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Select'}</strong>
      ),
    },
  ];

  const columnsMobile = [
    {
      field: 'ACCOUNT_NO',
      width: 100,
      headerAlign: 'center',
      // headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Acc.'}</strong>
      ),
    },

    {
      field: 'NAME',
      width: 100,
      headerAlign: 'center',
      // headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Name'}</strong>
      ),
    },
    {
      field: 'MOBILE',
      width: 120,
      headerAlign: 'center',
      // headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Mobile'}</strong>
      ),
    },
    {
      field: 'ADDRESS',
      width: 120,
      headerAlign: 'center',
      // headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Address'}</strong>
      ),
    },

    {
      field: 'Select',
      width: 75,
      headerAlign: 'center',
      align: 'center',
      // headerClassName: 'super-app-theme--header',
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={saveCartList.some(
              (row) => row.ACCOUNT_NO === params.row.ACCOUNT_NO
            )}
            // isSelected={checkk}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
      renderHeader: (params) => (
        <strong style={{ fontSize: 13 }}>{'Select'}</strong>
      ),
    },
  ];

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const getData = async () => {
    dispatch(setSpinnerLoading('Load party list'));
    axios
      .post(
        '/api/masters/listAccountMaster',
        {
          table_prefix: prefix,
          mobile: '',
          name: '',
          sid: stationId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setList(p.data.response);
        setFullList(p.data.response);
        selectAllFuncFromAPI(p.data.response);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.data.name === 'TokenExpiredError') {
          console.log(error.response);
          dispatch(setSessionExpired(true));
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something went wrong',
            })
          );
        }
      })
      .finally(() => dispatch(setSpinnerLoading(false)));
  };

  const savePartyRateMasterApi = async () => {
    if (itemCode && SalePrice > 0 && saveCartList[0] && stationId) {
      dispatch(setSpinnerLoading('Saving'));

      let list = [];

      for (let i = 0; i < saveCartList.length; i++) {
        list.push({
          ...saveCartList[i],
          ICODE: itemCode,
          ITEMNAME: itemName,
          SNAME: sName,
          MRP: mrp ? mrp : 0,
          SALE_PRICE: SalePrice ? SalePrice : 0,
        });
      }
      if ((list.length = saveCartList.length)) {
        axios
          .post(
            `/api/issueMasterRoute/updatePartyItemRate`,

            {
              table_prefix: prefix,
              cart_items: list,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async function (response) {
            if (!response.data.error) {
              dispatch(
                setAlertWithTitle({
                  title: 'Succces',
                  msg: 'Data saved successfully',
                })
              );
              setStationId('');
              setSaveCartList([]);
              setSelectAll(false);
              setItemCode('');
              setItemName('');
              setSalePrice('');
              setMrp('');
              setStationName('');
              setList([]);
            }
          })
          .catch(function (error) {
            console.log(error);
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Something Went Wrong!',
              })
            );
          })
          .finally(() => {
            dispatch(setSpinnerLoading(false));
          });
      }
    } else {
      if (!itemCode) {
        dispatch(
          setAlertWithTitle({
            title: 'Error',
            msg: 'Please Select Item For Save Record!',
          })
        );
      } else {
        if (SalePrice < 1) {
          dispatch(
            setAlertWithTitle({
              title: 'Alert',
              msg: 'Sale price cannot be 0',
            })
          );
        } else {
          if (!stationId) {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'Select Station first for save data',
              })
            );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Alert',
                msg: 'No data to save',
              })
            );
          }
        }
      }
    }
  };

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.MOBILE.toString().includes(searchText);
        } else {
          return item.NAME.toLowerCase().includes(searchText.toLowerCase());
        }
      });

      setList(searchItems);
    } else {
      setList(fullList);
    }
  }, [searchText]);

  useEffect(() => {
    if (stationId) {
      getData();
    }
  }, [stationId]);

  const undo = () => {
    setSaveCartList([]);
    setSelectAll(false);
  };
  return (
    <div className="w-full flex justify-center  bg-gray-50 h-full  p-3">
      <div className="text-sm  rounded-sm  h-full overflow-hidden">
        <div ref={headerRef}>
          <ModalHeaderStyle
            header={'Update Party(s) - Item Rate'}
            onClose={() => {
              navigation('/masters');
            }}
          />
        </div>
        <div
          style={{
            height: headerRef.current
              ? `calc(100% - ${headerRef.current.clientHeight}px)`
              : '90%',
          }}
          className="      flex flex-col   "
        >
          <div
            style={{
              height: `calc(100% - ${45}px`,
            }}
            className="  py-3 px-5 max-sm:px-2 p-3  pt-5 bg-white  flex flex-col  "
          >
            <div>
              <div className="flex ">
                <GeniustextInput
                  type="text"
                  title={'Station Id'}
                  value={stationId}
                  autofocus={true}
                  width={windowWidth <= 768 ? '150px' : '140px'}
                  search={() => {
                    dispatch(setListStationMasterModalStatus(true));
                  }}
                  onClick={() => {
                    dispatch(setListStationMasterModalStatus(true));
                  }}
                />
                <div className="ml-2">
                  <GeniustextInput
                    type="text"
                    title={'Station Name'}
                    value={stationName}
                    width={windowWidth <= 768 ? '150px' : '200px'}
                    onClick={() => {
                      dispatch(setListStationMasterModalStatus(true));
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="flex ">
                  <GeniustextInput
                    type="text"
                    title={'Item Name'}
                    search={() => {
                      if (stationId) {
                        dispatch(setItemMasterHelpStatus(true));
                      } else {
                        dispatch(
                          setAlertWithTitle({
                            title: 'Alert',
                            msg: 'Please select Station first to load item list.',
                          })
                        );
                      }
                    }}
                    value={itemName}
                    width={windowWidth <= 768 ? '150px' : '350px'}
                  />

                  <div className="ml-2">
                    <GeniustextInput
                      type="number"
                      title={'MRP'}
                      value={mrp}
                      onChange={(e) => setMrp(e.target.value)}
                      width={windowWidth <= 768 ? '150px' : '220px'}
                    />
                  </div>
                  <div className="ml-2 max-sm:hidden">
                    <GeniustextInput
                      type="text"
                      value={SalePrice}
                      titleBold={true}
                      title={'Sale Price'}
                      width={'120px'}
                      onChange={(e) => setSalePrice(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="max-sm:flex hidden">
                  <GeniustextInput
                    type="text"
                    value={SalePrice}
                    titleBold={true}
                    title={'Sale Price'}
                    width={'120px'}
                    onChange={(e) => setSalePrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            {headerRef.current && (
              <div
                style={{
                  height: '480px',
                  width:
                    columns[0].width +
                    columns[1].width +
                    columns[2].width +
                    columns[3].width +
                    columns[4].width +
                    columns[5].width +
                    20,
                }}
              >
                <DataGrid
                  hideFooterPagination
                  headerHeight={0}
                  rowHeight={30}
                  showCellRightBorder={true}
                  showColumnRightBorder={true}
                  disableColumnMenu={true}
                  hideFooter
                  getRowId={(p) => p.ACCOUNT_NO}
                  components={{
                    Header: () => {
                      const headerDetails =
                        window.innerWidth <= 770 ? columnsMobile : columns;
                      return (
                        <div
                          className="  bg-[#383535] flex text-white text-lg "
                          style={{
                            borderTopLeftRadius: '3px',
                            borderTopRightRadius: '3px',
                            letterSpacing: '1px',
                          }}
                        >
                          {headerDetails?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className="  text-#4a4a4a flex justify-center items-center py-2  "
                                style={
                                  item.flex
                                    ? index < headerDetails.length - 1
                                      ? {
                                          flex: item.flex,
                                          borderRight: '1px solid #b5b3b3',
                                          borderBottom: '1px solid #b5b3b3',
                                        }
                                      : {
                                          flex: item.flex,
                                          borderBottom: '1px solid #b5b3b3',
                                        }
                                    : index < headerDetails.length - 1
                                    ? {
                                        width: item.width,

                                        borderRight: '1px solid #b5b3b3',
                                        borderBottom: '1px solid #b5b3b3',
                                      }
                                    : {
                                        width: item.width,
                                        borderBottom: '1px solid #b5b3b3',
                                      }
                                }
                              >
                                {item.field === 'Select' ? (
                                  <div className="flex items-center">
                                    <div>Select</div>
                                    <div className="flex items-center ml-2">
                                      <input
                                        type="checkbox"
                                        checked={selectAll}
                                        isSelected={selectAll}
                                        onChange={selectAllFunc}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  item.renderHeader()
                                )}
                              </div>
                            );
                          })}
                        </div>
                      );
                    },
                    NoRowsOverlay: () => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          No Records Found
                        </div>
                      );
                    },
                  }}
                  sx={
                    window.innerWidth < 770
                      ? {
                          backgroundColor: 'white',

                          fontSize: 12,
                          '& .super-app-theme--header': {
                            backgroundColor: '#f5f0e8',
                            display: 'none',
                          },
                          borderTop: '1px solid #b5b3b3',
                          borderRadius: 0,
                        }
                      : {
                          backgroundColor: 'white',
                          fontSize: 13,
                          borderTop: '1px solid #b5b3b3',
                          borderRadius: '3.5px',
                          '& .super-app-theme--header': {
                            backgroundColor: '#f5f0e8',
                            borderRight: `1px solid #b5b3b3`,

                            display: 'none',
                          },
                        }
                  }
                  columns={window.innerWidth <= 770 ? columnsMobile : columns}
                  rows={list}
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  disableColumnSelector
                  disableColumnReorder
                />
              </div>
            )}
            <div className="w-full mt-2 flex">
              <div
                style={{
                  width: columns[0].width + columns[1].width + columns[2].width,
                  border: '1px solid #D2D2D7',
                }}
                className="rounded-sm p-1 flex items-center pl-2"
              >
                {' '}
                <input
                  type="text"
                  value={searchText}
                  className="w-full"
                  style={{
                    outline: 'none ',
                  }}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                />
              </div>
              <div className="max-sm:ml-2 flex justify-end grow">
                <SaveButton onClick={savePartyRateMasterApi} />
                <UndoButton onClick={undo} />
              </div>
            </div>
            <div className="text-gray-400 mt-5 flex">
              <div>
                <TipsAndUpdatesIcon
                  fontSize={'small'}
                  sx={{
                    color: '#fdb608',
                  }}
                />
              </div>
              <div className="ml-2">
                Update MRP & Sale Price to all Selected Party(s) of Station
              </div>
            </div>
          </div>

          <ItemMasterHelp
            onRowClick={(p) => {
              setItemName(p.row.ITEMNAME);
              setItemCode(p.row.ICODE);
              setMrp(p.row.SALE_PRICE);
              setSName(p.row.SNAME);
              dispatch(setItemMasterHelpStatus(false));
            }}
          />

          <ModListStationMaster
            onRowClick={(p) => {
              setStationId(p.row.CODE);
              setStationName(p.row.STATION_NAME);
              dispatch(setListStationMasterModalStatus(false));
              setSaveCartList([]);
              setSelectAll(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UpdatepartyItemRate;
