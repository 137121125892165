import { Button } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setItemMasterModalStatus,
  setPartyLedgerModalStatus,
} from '../utility/Redux/modal';
import { selectUserType } from '../utility/Redux/profile';

const Reports = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const masters = [
    {
      name: 'Route Alert Report',
      path: '',
      userType: 'MANAGER,ADMIN',
      onclick: () => {
        navigate('/Route_Alert_Report');
      },
    },
    {
      name: 'Item Wise Sale Report',
      path: '',
      userType: 'MANAGER,ADMIN,STORE',
      onclick: () => {
        navigate('/Item_Wise_Sale_Report');
      },
    },
    {
      name: 'Party Balance Report',
      path: '',
      userType: 'MANAGER,ADMIN,USER,STORE',
      onclick: () => {
        navigate('/Party_Balance_Report');
        dispatch(setItemMasterModalStatus(true));
      },
    },
    {
      name: 'Party Ledger',
      path: '',
      userType: 'MANAGER,ADMIN,STORE',
      onclick: () => {
        navigate('/party_ledger');
      },
    },
    {
      name: 'Sales Map',
      path: '',
      userType: 'MANAGER,ADMIN',
      onclick: () => {
        navigate('/salesMap');
      },
    },
    {
      name: 'All Station Balance Report',
      path: '',
      userType: 'MANAGER,ADMIN,STORE',
      onclick: () => {
        navigate('/All_Stations_Balance_Report');
      },
    },
    {
      name: 'Station Day End Report',
      path: '',
      userType: 'MANAGER,ADMIN,STORE',
      onclick: () => {
        navigate('/Station_day_end_reports');
      },
    },
    {
      name: 'Difference Report',
      path: '',
      userType: 'MANAGER,ADMIN,STORE',
      onclick: () => {
        navigate('/Difference_report');
      },
    },
    {
      name: 'Profit Report',
      path: '',
      userType: 'MANAGER,ADMIN,STORE',
      onclick: () => {
        navigate('/Profit_report');
      },
    },
  ];
  const userType = useSelector(selectUserType);
  return (
    <div className="h-full p-4 ">
      <div className="grid grid-cols-1 t:grid-cols-4 d:grid-cols-6 ll:grid-cols-7 gap-3  ">
        {masters.map((e, i) => {
          if (e.userType.includes(userType)) {
            return (
              <Button
                key={i}
                onClick={() => {
                  if (e.path) {
                    navigate(e.path);
                  } else {
                    e.onclick();
                  }
                }}
                variant={'contained'}
                style={{
                  textTransform: 'none',
                  backgroundColor: 'transparent',
                  padding: '0px',
                  borderRadius: '7px',
                  overflow: 'hidden',
                }}
              >
                <div className="bg-[#e9e9e5] py-5 w-full text-brown">
                  {e.name}
                </div>
              </Button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default Reports;
