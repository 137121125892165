import React, { useRef } from 'react';
import moment from 'moment';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../utility/Redux/modal';
import { useEffect } from 'react';
import axios from 'axios';
import { selectToken } from '../../utility/Redux/security';
import {
  selectPartyBalSelctedRowData,
  selectPartyBalSelectedStationName,
  selectPartyBalStationList,
  selectPrefix,
  selectStationId,
  selectStationName,
  selectUserType,
  selectWidth,
  selectreportFromDate,
  selectreportToDate,
  setPartyBalSelectedRowData,
  setPartyBalStationList,
  setpartyBalStationName,
  setreportsFromDate,
  setreportsTodate,
} from '../../utility/Redux/profile';
import { FormatNumber2 } from '../../utility/component/tools';
import PrintPartyBalanceReport from './printPartyBalanceReport';
import ReactToPrint from 'react-to-print';
import GeniusDatagrid from '../../utility/component/datagrid/geniusDatagrid';
import GeniusReportsDate from '../../utility/component/reportDate';
import dataToLocal from '../../utility/functions/summaryToLocal';
import IsLocalData from '../../utility/functions/ceckLocalData';
import { selectStationList, setStationList } from '../../utility/Redux/list';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase';

const PartyBalanceReport = () => {
  const printRef = useRef();
  const UserstationId = useSelector(selectStationId);
  const userstationName = useSelector(selectStationName);
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const usertype = useSelector(selectUserType);

  const width = window.innerWidth / 100;
  const dispatch = useDispatch();
  const routenames = useSelector(selectPartyBalSelectedStationName);
  const selectedRowData = useSelector(selectPartyBalSelctedRowData);
  const reportsFromDate = useSelector(selectreportFromDate);
  const reportsToDate = useSelector(selectreportToDate);
  const [stname, setStName] = useState([
    usertype === 'USER' ? userstationName : routenames,
  ]);
  const [fromDate, setFromDate] = useState(reportsFromDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);

  const [toDate, setToDate] = useState(reportsToDate);
  const [toDateFocus, setToDateFocus] = useState(false);

  const [date, setDate] = useState(reportsFromDate);
  const [dateFocus, SetDateFocus] = useState(false);

  const [date1, setDate1] = useState(reportsToDate);
  const [dateFocus1, SetDateFocus1] = useState(false);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [selectedRows, setSelectedRows] = useState(
    usertype === 'USER'
      ? [
          {
            CODE: UserstationId,
            STATION_NAME: userstationName,
          },
        ]
      : selectedRowData
  );
  const selectedStaionList = useSelector(selectPartyBalStationList);
  const [stationId, setstationId] = useState(
    usertype === 'USER' ? UserstationId : selectedStaionList
  );
  const stationList = useSelector(selectStationList);
  const [routeRows, setRouteRows] = useState(stationList);

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);
  const windowWidth = useSelector(selectWidth);

  const columns = [
    {
      width: windowWidth > 768 ? 100 : 100,
      headerAlign: 'center',
      align: 'center',
      field: 'ACCOUNT_NO',
      renderHeader: (params) => <div>Account No.</div>,
    },
    {
      width: windowWidth > 768 ? 100 : 100,
      headerAlign: 'center',
      align: 'center',
      field: 'SID',
      renderHeader: (params) => <div>Route ID</div>,
    },
    {
      width: windowWidth > 768 ? 200 : 150,
      headerAlign: 'center',
      align: 'left',
      field: 'ADDRESS',
      renderHeader: (params) => <div>Address</div>,
    },
    {
      width: windowWidth > 768 ? 250 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width: windowWidth > 768 ? 100 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'MOBILE',
      renderHeader: (params) => <div>Mobile</div>,
    },
    {
      field: 'OP_BALANCE',
      width: windowWidth > 768 ? 150 : 100,
      headerAlign: 'center',
      align: 'right',

      renderHeader: (params) => <div>Opening</div>,
    },
    {
      width: windowWidth > 768 ? 150 : 100,
      headerAlign: 'center',
      align: 'right',
      field: 'DEBIT',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: () => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4  text-center"
        >
          Amount Due
          <div>[Debit]</div>
        </div>
      ),
    },
    {
      width: windowWidth > 768 ? 150 : 100,
      headerAlign: 'center',
      align: 'right',
      field: 'CREDIT',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return FormatNumber2(param.value);
        }
      },
      renderHeader: () => (
        <div
          style={{ fontSize: 12 }}
          className="whitespace-normal leading-4  text-center"
        >
          {'Amount Received'}
          <div>[Collection]</div>
        </div>
      ),
    },
    {
      width: windowWidth > 768 ? 150 : 100,
      headerAlign: 'center',
      align: 'right',
      field: 'balance',

      renderHeader: () => <div>Balance</div>,
    },
  ];

  const routeColumns = [
    {
      width: width * 4,
      headerAlign: 'center',
      align: 'center',
      field: 'CODE',
      renderHeader: (params) => <div>Route ID</div>,
    },
    {
      width: width * 9,
      headerAlign: 'center',
      align: 'left',
      field: 'STATION_NAME',
      renderHeader: (params) => <div>Route Name</div>,
    },
    {
      width: width * 4,
      headerAlign: 'center',
      align: 'center',
      field: '',
      renderHeader: () => <div>Select</div>,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={selectedRows.some(
              (row) => Number(row.CODE) === Number(params.row.CODE)
            )}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
    },
  ];

  const routeColumnsMobile = [
    {
      width: width * 22,
      headerAlign: 'center',
      align: 'center',
      field: 'CODE',
      renderHeader: (params) => <div>Route ID</div>,
    },
    {
      width: width * 50,
      headerAlign: 'center',
      align: 'left',
      field: 'STATION_NAME',
      renderHeader: (params) => <div>Route Name</div>,
    },
    {
      width: width * 23,
      headerAlign: 'center',
      align: 'center',
      field: '',
      renderHeader: () => <div>Select</div>,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={selectedRows.some(
              (row) => Number(row.CODE) === Number(params.row.CODE)
            )}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
    },
  ];

  const routeColumnsTab = [
    {
      width: width * 15,
      headerAlign: 'center',
      align: 'center',
      field: 'CODE',
      renderHeader: (params) => <div>Route ID</div>,
    },
    {
      width: width * 25,
      headerAlign: 'center',
      align: 'left',
      field: 'STATION_NAME',
      renderHeader: (params) => <div>Route Name</div>,
    },
    {
      width: width * 15,
      headerAlign: 'center',
      align: 'center',
      field: '',
      renderHeader: () => <div>Select</div>,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={selectedRows.some(
              (row) => Number(row.CODE) === Number(params.row.CODE)
            )}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
    },
  ];

  const routeColumnsTabVerticle = [
    {
      width: width * 15,
      headerAlign: 'center',
      align: 'center',
      field: 'CODE',
      renderHeader: (params) => <div>Route ID</div>,
    },
    {
      width: width * 25,
      headerAlign: 'center',
      align: 'left',
      field: 'STATION_NAME',
      renderHeader: (params) => <div>Route Name</div>,
    },
    {
      width: width * 15,
      headerAlign: 'center',
      align: 'center',
      field: '',
      renderHeader: () => <div>Select</div>,
      renderCell: (params) => {
        return (
          <input
            type="checkbox"
            checked={selectedRows.some(
              (row) => Number(row.CODE) === Number(params.row.CODE)
            )}
            onChange={(event) => handleCheckboxChange(event, params.row)}
          />
        );
      },
    },
  ];

  const getAllPartyPrice = async () => {
    deleteWholeDatabase('Party_Balance_Report');
    const data = {
      table_prefix: prefix,
      SID: stationId,
      FDATE: window.innerWidth > 1280 ? date : fromDate,
      TDATE: window.innerWidth > 1280 ? date1 : toDate,
    };
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        '/api/reports/allPartyBalance',

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setLoading(false);
        const data = p.data.response;
        console.log(data);
        let list = [];
        if (data[0]) {
          for (let i = 0; i < data.length; i++) {
            const v =
              Number(data[i].OP_DEBIT) -
              Number(data[i].OP_CREDIT) +
              Number(data[i].PRV_DEBIT) -
              Number(data[i].PRV_CREDIT);
            list.push({
              ...data[i],
              OP_BALANCE: v > 0 ? v : '',
              balance:
                data[i].OP_DEBIT +
                data[i].DEBIT -
                (data[i].OP_CREDIT + data[i].CREDIT),
            });
          }
          console.log(list);
          dataToLocal('Party_Balance_Report', list, [
            {
              SID: stationId,
              ICODE: 1,
              FDATE: window.innerWidth > 1280 ? date : fromDate,
              TDATE: window.innerWidth > 1280 ? date1 : toDate,
            },
          ]);
        }

        setRows(list);
      })
      .catch((error) => {
        console.log(error);

        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const getStationIDDetails = async () => {
    setLoading(true);
    try {
      await axios
        .post(
          '/api/masters/listStationMaster',
          {
            table_prefix: prefix,
            CODE: '',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((p) => {
          if (p.data?.response[0]) {
            setRouteRows(p.data.response);
            setLoading(false);
            dispatch(setStationList(p.data.response));
          }
        })
        .catch((error) => {
          console.log(error);

          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!routeRows[0]) {
      getStationIDDetails();
    }
  }, []);

  const okButton = () => {
    getAllPartyPrice();
  };
  useEffect(() => {
    const callback = (details, data) => {
      setRows(data);
      setstationId(details[0].SID);
    };
    const elseFunc = () => {};
    IsLocalData('Party_Balance_Report', callback, elseFunc);
  }, []);

  const [searchQuery, setSearchQuery] = useState();
  const [filterData, setFilterData] = useState([]);

  const Filter = () => {
    const filteredparty = rows.filter((f) => {
      return f.NAME.toLowerCase().includes(searchQuery.toLowerCase());
    });
    setFilterData(filteredparty);
  };

  useEffect(() => {
    const onHandleChange = () => {
      if (searchQuery) {
        Filter();
      }
    };
    onHandleChange();
  }, [searchQuery]);

  const [searchRoute, setSearchRoute] = useState('');
  const [filterRouteData, setFilterRouteData] = useState([]);

  const FilterRoute = () => {
    const filteredStationName = routeRows.filter((f) => {
      return f.STATION_NAME.toLowerCase().includes(searchRoute.toLowerCase());
    });
    setFilterRouteData(filteredStationName);
  };

  const FilterRouteCode = () => {
    const filteredStationId = routeRows.filter((f) => {
      return f.CODE.toString().includes(searchRoute);
    });
    setFilterRouteData(filteredStationId);
  };

  useEffect(() => {
    const onHandleChangeRoute = () => {
      if (isNaN(searchRoute)) {
        FilterRoute();
      } else {
        FilterRouteCode();
      }
    };
    onHandleChangeRoute();
  }, [searchRoute]);

  const handleCheckboxChange = (event, row) => {
    if (usertype !== 'USER') {
      const selectedRowIds = new Set(
        selectedRows.map((selectedRow) => selectedRow.CODE)
      );
      if (event.target.checked) {
        selectedRowIds.add(row.CODE);
      } else {
        selectedRowIds.delete(row.CODE);
      }
      const selectRowData = routeRows.filter((row) =>
        selectedRowIds.has(row.CODE)
      );

      setSelectedRows(selectRowData);
      dispatch(setPartyBalSelectedRowData(selectRowData));

      const inputRouteName = selectRowData.map((row) => row.STATION_NAME);
      setStName(inputRouteName);
      dispatch(setpartyBalStationName(inputRouteName));
      const inputRouteID = selectRowData.map((row) => row.CODE);
      const selectedStationIdList = inputRouteID.toString();
      dispatch(setPartyBalStationList(selectedStationIdList));
      setstationId(selectedStationIdList);
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'No permission to change station',
        })
      );
    }
  };

  const [toggle, setToggle] = useState(false);

  const handleClick = () => {
    setToggle(!toggle);
  };

  const toggleStyle = {
    width: '22px',
    height: '2px',
    backgroundColor: '#424242',
    margin: '3px 0',
    borderRadius: '3px',
  };

  let sum = 0;
  rows.forEach((row) => {
    sum += row.OP_DEBIT;
  });

  const OpeningBal = rows.reduce(
    (total, row) => Number(total) + Number(row.OP_BALANCE),
    0
  );
  const sumDebit = rows.reduce(
    (total, row) => Number(total) + Number(row.DEBIT),
    0
  );

  const sumCredit = rows.reduce(
    (total, row) => Number(total) + Number(row.CREDIT),
    0
  );

  const netBalance = rows.reduce(
    (total, row) => Number(total) + Number(row.balance),
    0
  );

  return (
    <>
      <div
        id="container"
        style={
          window.innerWidth <= 768
            ? { width: '100%', height: '100%', backgroundColor: 'white' }
            : window.innerWidth <= 1024
            ? {
                width: '100%',
                height: '100%',
                backgroundColor: 'white',
              }
            : { width: '100%', height: '100%', backgroundColor: 'white' }
        }
      >
        {toggle && (
          <>
            <div style={{ width: '100%' }}>
              <div
                style={{
                  backgroundColor: 'rgb(56,53,53)',
                  width: '99.7%',
                  height: '40px',
                }}
                className="xl:hidden mt-1 text-white flex font-semibold items-center px-3"
              >
                <div style={{ width: '90%' }}>
                  <p>Select Route</p>
                </div>
                <div
                  style={{ width: '10%' }}
                  className="max-sm:pl-2 t:pl-10 l:pl-16 "
                >
                  <i
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                    style={{
                      fontSize: '25px',
                      color: 'white',
                      display: 'flex',
                    }}
                    className="bi bi-x-circle"
                  ></i>
                </div>
              </div>
              <div
                className="xl:hidden ml-2"
                style={
                  window.innerWidth <= 500
                    ? {
                        width: '96%',
                        height: '450px',
                        position: 'absolute',
                        top: '20%',
                      }
                    : window.innerWidth <= 768
                    ? {
                        width: '55%',
                        height: '600px',
                        position: 'absolute',
                        top: '16%',
                      }
                    : {
                        width: '55%',
                        height: '400px',
                        position: 'absolute',
                        top: '22%',
                      }
                }
              >
                <DataGrid
                  hideFooterPagination
                  hideFooter
                  headerHeight={0}
                  rowHeight={35}
                  showCellRightBorder={true}
                  showColumnRightBorder={true}
                  disableColumnMenu={true}
                  loading={loading}
                  getRowId={(p) => p.CODE}
                  components={{
                    Header: () => {
                      const headerDetails =
                        window.innerWidth <= 500
                          ? routeColumnsMobile
                          : window.innerWidth <= 768
                          ? routeColumnsTabVerticle
                          : routeColumnsTab;
                      return (
                        <div className="  bg-[rgb(56,53,53)] flex rounded-sm rounded-b-none">
                          {headerDetails?.map((item, index) => {
                            return (
                              <div
                                key={index}
                                className=" text-white flex justify-center items-center py-3"
                                style={
                                  item.flex
                                    ? index < headerDetails.length - 1
                                      ? {
                                          flex: item.flex,
                                          borderRight: '1px solid #4a4948',
                                          borderBottom: '1px solid #4a4948',
                                        }
                                      : {
                                          flex: item.flex,
                                          borderBottom: '1px solid #4a4948',
                                        }
                                    : index < headerDetails.length - 1
                                    ? {
                                        width: item.width,

                                        borderRight: '1px solid white',
                                        borderBottom: '1px solid #4a4948',
                                      }
                                    : {
                                        width: item.width,
                                        borderBottom: '1px solid #4a4948',
                                      }
                                }
                              >
                                {item.renderHeader()}
                                {/* {item.Header} */}
                              </div>
                            );
                          })}
                        </div>
                      );
                    },
                    NoRowsOverlay: () => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                            width: '100%',
                          }}
                        >
                          No Records Found
                        </div>
                      );
                    },
                  }}
                  sx={
                    window.innerWidth < 770
                      ? {
                          backgroundColor: 'white',

                          fontSize: 10,
                          '& .super-app-theme--header': {
                            backgroundColor: '#f5f0e8',
                            display: 'none',
                          },

                          borderRadius: 2,
                        }
                      : {
                          backgroundColor: 'white',
                          fontSize: 13,
                          borderRadius: 2,
                          '& .super-app-theme--header': {
                            backgroundColor: '#f5f0e8',
                            borderRight: `1px solid #4a4948`,

                            display: 'none',
                          },
                        }
                  }
                  columns={
                    window.innerWidth <= 500
                      ? routeColumnsMobile
                      : window.innerWidth <= 768
                      ? routeColumnsTabVerticle
                      : routeColumnsTab
                  }
                  rows={
                    filterRouteData.length > 0 ? filterRouteData : routeRows
                  }
                  pageSize={100}
                  rowsPerPageOptions={[100]}
                  disableColumnSelector
                  disableColumnReorder
                />
                <div
                  style={{
                    width: '100%',
                    height: '40px',
                    borderRadius: '7px',
                    border: '1px solid #b5b3b3',
                  }}
                  className="mt-2"
                >
                  <input
                    value={searchRoute}
                    onChange={(e) => {
                      setSearchRoute(e.target.value);
                      setFilterRouteData('');
                    }}
                    style={{ borderRadius: '7px' }}
                    type="search"
                    className="h-full w-full focus:outline-none px-2 text-xs"
                    placeholder="Search Route"
                  ></input>
                </div>
                <div
                  style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: 'rgb(56,53,53)',
                    color: 'white',
                    borderRadius: '7px',
                  }}
                  className="mt-2"
                >
                  <button
                    onClick={() => {
                      setToggle(!toggle);
                    }}
                    style={{ width: '100%', height: '100%' }}
                  >
                    Oksss
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {!toggle && (
          <div className="flex w-full xl:hidden">
            <div
              id="body"
              style={
                window.innerWidth <= 768
                  ? { width: '100%', color: '#424242' }
                  : window.innerWidth <= 1024
                  ? { width: '100%', color: '#424242' }
                  : { width: '100%', color: '#424242' }
              }
            >
              <div
                className="max-sm:block t:flex  p-1 "
                style={{ width: '100%' }}
              >
                <div
                  className="flex p-1"
                  style={
                    window.innerWidth <= 500
                      ? {
                          width: '100%',
                          border: '1px solid #b5b3b3',
                          borderTopLeftRadius: '7px',
                          borderTopRightRadius: '7px',
                          borderBottom: '0px',
                          fontSize: '11px',
                        }
                      : window.innerWidth <= 768
                      ? {
                          width: '60%',
                          border: '1px solid #b5b3b3',
                          borderTopLeftRadius: '7px',
                          borderBottomLeftRadius: '7px',
                        }
                      : window.innerWidth <= 1024
                      ? {
                          width: '50%',
                          border: '1px solid #b5b3b3',
                          borderTopLeftRadius: '7px',
                          borderBottomLeftRadius: '7px',
                        }
                      : {
                          width: '50%',
                          border: '1px solid #b5b3b3',
                          borderTopLeftRadius: '7px',
                          borderBottomLeftRadius: '7px',
                        }
                  }
                >
                  <div
                    style={{ width: '26%' }}
                    className="xl:flex xl:text-lg max-sm:hidden  t:hidden xl:items-center"
                  >
                    From Date
                  </div>
                  <div
                    style={{ width: '18%' }}
                    className="flex justify-center  items-center text-xs  xl:hidden"
                  >
                    From
                  </div>
                  <div className="w-fit">
                    <GeniusReportsDate
                      date={fromDate}
                      onChange={(date) => {
                        setFromDate(moment(date).format('YYYY-MM-DD'));
                        dispatch(
                          setreportsFromDate(moment(date).format('YYYY-MM-DD'))
                        );
                      }}
                      SetDateFocus={setFromDateFocus}
                      dateFocus={fromDateFocus}
                    />
                  </div>

                  <div
                    style={{ width: '15%' }}
                    className="flex justify-center items-center xl:text-lg max-sm:text-xs t:text-xs"
                  >
                    To
                  </div>

                  <div>
                    <GeniusReportsDate
                      date={toDate}
                      onChange={(date) => {
                        setToDate(moment(toDate).format('YYYY-MM-DD'));
                        dispatch(
                          setreportsTodate(moment(date).format('YYYY-MM-DD'))
                        );
                      }}
                      SetDateFocus={setToDateFocus}
                      dateFocus={toDateFocus}
                    />
                  </div>

                  <button
                    style={{
                      fontSize: 14,
                      fontWeight: '500px',
                      display: window.innerWidth <= 768 ? 'none' : 'flex',
                    }}
                    data-tooltip-id="tip"
                    data-tooltip-content="Refresh"
                    className="pl-2"
                  >
                    <i className="bi text-[20px] text-orange bi-arrow-clockwise"></i>
                  </button>
                </div>
                <div
                  className="flex p-1 items-center"
                  style={
                    window.innerWidth <= 500
                      ? {
                          width: '100%',
                          border: '1px solid #b5b3b3',
                        }
                      : window.innerWidth <= 768
                      ? {
                          width: '40%',
                          border: '1px solid #b5b3b3',
                          borderLeft: '0px solid #b5b3b3',
                          borderTopRightRadius: '7px',
                          borderBottomRightRadius: '7px',
                        }
                      : window.innerWidth <= 1024
                      ? {
                          width: '50%',
                          border: '1px solid #b5b3b3',
                          borderLeft: '0px solid #b5b3b3',
                          borderTopRightRadius: '7px',
                          borderBottomRightRadius: '7px',
                        }
                      : {
                          width: '100%',
                          border: '1px solid #b5b3b3',
                        }
                  }
                >
                  <input
                    value={stname}
                    type="text"
                    className="text-xs focus:outline-none p-2 pl-3 mr-2"
                    style={{
                      backgroundColor: '#f5f5f5',
                      width: '95%',
                      borderRadius: '7px',
                    }}
                    placeholder="Route Name"
                    readOnly
                  />
                  <div className="mr-2">
                    <button
                      onClick={okButton}
                      className="flex items-center rounded-sm ml-2"
                      style={{
                        backgroundColor: '#c1c1c1',
                        paddingLeft: 30,
                        paddingRight: 30,
                        paddingTop: 4,
                        paddingBottom: 4,
                        color: 'black',
                      }}
                    >
                      <div className="flex items-center text-sm">Ok</div>
                    </button>
                  </div>
                  <button onClick={handleClick} className="xl:hidden">
                    <div style={toggleStyle}></div>
                    <div style={toggleStyle}></div>
                    <div style={toggleStyle}></div>
                  </button>
                </div>
              </div>
              <div
                className="xl:p-4 xl:pt-0 max-sm:p-2 max-sm:pt-0 t:p-2 t:pt-0"
                style={
                  window.innerWidth <= 500
                    ? { width: '100%', height: '380px' }
                    : window.innerWidth <= 768
                    ? { width: '100%', height: '500px' }
                    : { width: '100%', height: '370px' }
                }
              >
                <GeniusDatagrid
                  rowID={(p) => p.ACCOUNT_NO}
                  columns={columns}
                  list={filterData.length > 0 ? filterData : rows}
                  dataGridHeader={true}
                />
              </div>
              <div
                className=" max-sm:p-2 max-sm:pt-0 t:hidden"
                style={{ width: '100%' }}
              >
                <div
                  style={{
                    width: '100%',
                    borderRadius: '7px',
                    border: '1px solid #b5b3b3',
                    height: '45px',
                  }}
                >
                  <input
                    style={{
                      width: width * 65,
                      borderTopLeftRadius: '7px',
                      borderBottomLeftRadius: '7px',
                      height: '100%',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    placeholder="Total"
                    className="px-2 focus:outline-none text-right placeholder:text-black"
                    readOnly
                  />

                  <input
                    value={FormatNumber2(OpeningBal)}
                    style={{
                      width: width * 30,
                      borderTopRightRadius: '7px',
                      borderBottomRightRadius: '7px',
                    }}
                    className="text-right pr-2 text-md focus:outline-none font-bold"
                    readOnly
                  />
                </div>
              </div>

              <div
                className="max-sm:hidden t:p-2 t:pt-0"
                style={{ width: '100%' }}
              >
                <div
                  style={{
                    width: '100%',
                    borderRadius: '7px',
                    border: '1px solid #b5b3b3',
                    height: '45px',
                    display: 'flex',
                  }}
                >
                  <input
                    style={{
                      width: width * 55,
                      borderTopLeftRadius: '7px',
                      borderBottomLeftRadius: '7px',
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    placeholder="Total"
                    className="px-2 focus:outline-none text-right placeholder:text-black"
                    readOnly
                  />
                  <input
                    value={FormatNumber2(OpeningBal)}
                    style={{
                      width: width * 10,
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    className="text-right l:pr-2 t:pr-0 text-md t:text-xs focus:outline-none font-bold"
                    readOnly
                  />
                  <input
                    value={FormatNumber2(sumDebit)}
                    style={{
                      width: width * 10,
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    className="text-right l:pr-2 t:pr-0 text-md t:text-xs focus:outline-none font-bold"
                    readOnly
                  />
                  <input
                    value={FormatNumber2(sumCredit)}
                    style={{
                      width: width * 10,
                      borderTopRightRadius: '0px',
                      borderBottomRightRadius: '0px',
                      borderRight: '1px solid #b5b3b3',
                    }}
                    className="text-right l:pr-2 t:pr-0 text-md t:text-xs focus:outline-none font-bold"
                    readOnly
                  />
                  <input
                    value={FormatNumber2(netBalance)}
                    style={{
                      width: width * 10,
                      borderTopRightRadius: '7px',
                      borderBottomRightRadius: '7px',
                    }}
                    className="text-right l:pr-2 t:pr-0  text-md t:text-xs focus:outline-none font-bold"
                    readOnly
                  />
                </div>
              </div>

              <div
                className=" flex xl:p-4 xl:pt-0 max-sm:p-2 max-sm:pt-0 t:p-2 t:pt-0"
                style={{ width: '100%' }}
              >
                <div
                  style={
                    window.innerWidth < 500
                      ? {
                          width: '50%',
                          borderRadius: '7px',
                          border: '1px solid #b5b3b3',
                          height: '45px',
                          display: 'flex',
                        }
                      : {
                          width: '80%',
                          borderRadius: '7px',
                          border: '1px solid #b5b3b3',
                          height: '45px',
                          display: 'flex',
                        }
                  }
                >
                  <input
                    value={searchQuery}
                    onChange={(e) => {
                      setSearchQuery(e.target.value);
                      setFilterData('');
                    }}
                    type="search"
                    className="focus:outline-none pl-3"
                    style={{
                      width: '100%',
                      borderRadius: '7px',
                      height: '100%',
                    }}
                    placeholder="Search Party"
                  />
                </div>
                <div
                  style={
                    window.innerWidth < 500
                      ? {
                          width: '50%',
                          marginLeft: '5px',
                        }
                      : {
                          width: '20%',
                          marginLeft: '5px',
                        }
                  }
                >
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button
                          style={{
                            width: '100%',
                            borderRadius: '7px',
                            height: '100%',
                            backgroundColor: '#bad4f9',
                            color: 'black',
                          }}
                        >
                          Print Report
                        </button>
                      );
                    }}
                    content={() => printRef.current}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="xl:flex w-full max-sm:hidden t:hidden l:hidden  h-full p-1  ">
          <div
            style={
              window.innerWidth <= 768
                ? { width: '100%', color: '#424242' }
                : window.innerWidth <= 1024
                ? { width: '100%', color: '#424242' }
                : {
                    width: '80%',
                    color: '#424242',
                  }
            }
          >
            <div className="flex  " style={{ width: '100%' }}>
              <div
                className="flex p-3 px-3  items-center"
                style={{
                  border: '1px solid #b5b3b3',
                  borderTopLeftRadius: '7px',
                  borderBottomLeftRadius: '7px',
                }}
              >
                <div className="xl:flex max-sm:hidden text-[12px]  t:hidden xl:items-center">
                  {window.innerWidth <= 1280 ? 'From' : 'From'}
                </div>
                <div className="   ml-2">
                  <GeniusReportsDate
                    date={date}
                    onChange={(date) => {
                      setDate(moment(date).format('YYYY-MM-DD'));
                      dispatch(
                        setreportsFromDate(moment(date).format('YYYY-MM-DD'))
                      );
                      SetDateFocus(false);
                    }}
                    SetDateFocus={SetDateFocus}
                    dateFocus={dateFocus}
                  />
                </div>

                <div className="flex justify-center items-center mx-4 text-[12px]  ">
                  To
                </div>

                <div>
                  <GeniusReportsDate
                    date={date1}
                    onChange={(date1) => {
                      setDate1(moment(date1).format('YYYY-MM-DD'));
                      dispatch(
                        setreportsTodate(moment(date1).format('YYYY-MM-DD'))
                      );
                      SetDateFocus1(false);
                    }}
                    SetDateFocus={SetDateFocus1}
                    dateFocus={dateFocus1}
                  />
                </div>

                <button
                  style={{
                    fontSize: 14,
                    fontWeight: '500px',
                  }}
                  data-tooltip-id="tip"
                  data-tooltip-content="Refresh"
                  className="pl-2"
                >
                  <i className="bi text-[20px] text-orange bi-arrow-clockwise"></i>
                </button>
              </div>
              <div
                className="flex flex-1 p-1 items-center"
                style={{
                  border: '1px solid #b5b3b3',
                  borderLeft: '0px solid #b5b3b3',
                  borderTopRightRadius: '7px',
                  borderBottomRightRadius: '7px',
                }}
              >
                <input
                  value={stname}
                  type="text"
                  className="xl:text-lg t:text:sm max-sm:text-xs l:text-md p-2 focus:outline-none pl-3"
                  style={{
                    backgroundColor: '#f5f5f5',
                    width: '100%',
                    borderRadius: '7px',
                  }}
                  placeholder="Route Name"
                  readOnly
                />
                <div>
                  <button
                    onClick={okButton}
                    className="flex items-center rounded-sm ml-2"
                    style={{
                      backgroundColor: '#c1c1c1',
                      paddingLeft: 30,
                      paddingRight: 30,
                      paddingTop: 4,
                      paddingBottom: 4,
                      color: 'black',
                    }}
                  >
                    <div className="flex items-center text-sm">Ok</div>
                  </button>
                </div>
                <div>
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button
                          style={{
                            borderRadius: '7px',
                            paddingLeft: 30,
                            paddingRight: 30,
                            paddingTop: 4,
                            paddingBottom: 4,
                            backgroundColor: '#bad4f9',
                            color: 'black',
                            marginLeft: 7,
                          }}
                        >
                          <div className="text-sm">Print</div>
                        </button>
                      );
                    }}
                    content={() => printRef.current}
                  />
                </div>
              </div>
            </div>
            <div
              className="mt-2"
              style={
                window.innerWidth <= 768
                  ? { width: '100%', height: `calc(100% - 170px)` }
                  : window.innerWidth <= 1024
                  ? { width: '100%', height: `calc(100% - 170px)` }
                  : window.innerWidth <= 1600
                  ? { width: '100%', height: `calc(100% - 170px)` }
                  : { width: '100%', height: `calc(100% - 170px)` }
              }
            >
              <GeniusDatagrid
                rowID={(p) => p.ACCOUNT_NO}
                columns={columns}
                list={filterData.length > 0 ? filterData : rows}
                dataGridHeader={true}
                showPagination={true}
              />
            </div>
            <div className="mt-2" style={{ width: '100%', color: '#424242' }}>
              <div
                style={{
                  width: '100%',
                  borderRadius: '7px',
                  height: '45px',
                  display: 'flex',
                }}
              >
                <div
                  style={{
                    width: columns[0].width + columns[1].width,
                  }}
                ></div>

                <input
                  style={{
                    width:
                      columns[2].width + columns[3].width + columns[4].width,
                    borderTopLeftRadius: '7px',
                    borderBottomLeftRadius: '7px',
                    height: '100%',
                    border: '1px solid #b5b3b3',
                  }}
                  placeholder="Total"
                  className="px-2 focus:outline-none text-right placeholder:text-black"
                  readOnly
                />
                <input
                  value={FormatNumber2(OpeningBal)}
                  style={{
                    width: columns[5].width,
                    height: '100%',
                    border: '1px solid #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="text-right pr-2 text-md focus:outline-none font-bold"
                  readOnly
                />
                <input
                  value={FormatNumber2(sumDebit)}
                  style={{
                    width: columns[6].width,
                    height: '100%',
                    border: '1px solid #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="text-right pr-2 text-md focus:outline-none font-bold"
                  readOnly
                />
                <input
                  value={FormatNumber2(sumCredit)}
                  style={{
                    width: columns[7].width,
                    height: '100%',
                    border: '1px solid #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="text-right pr-2 text-md focus:outline-none font-bold"
                  readOnly
                />
                <input
                  value={FormatNumber2(netBalance)}
                  style={{
                    width: columns[8].width + 11,
                    height: '100%',
                    borderTopRightRadius: '7px',
                    borderBottomRightRadius: '7px',
                    border: '1px solid #b5b3b3',
                    borderLeft: '0px solid #b5b3b3',
                  }}
                  className="text-right pr-2 text-md focus:outline-none font-bold"
                  readOnly
                />
              </div>
              <div
                style={{
                  width: '100%',
                  borderRadius: '7px',
                  border: '1px solid #b5b3b3',
                  height: '45px',
                }}
                className="mt-2"
              >
                <input
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setFilterData('');
                  }}
                  type="search"
                  className="focus:outline-none pl-3"
                  style={{
                    width: '100%',
                    borderRadius: '7px',
                    height: '100%',
                  }}
                  placeholder="Search Party"
                />
              </div>
            </div>
          </div>
          <div className="max-sm:hidden t:hidden l:block t:pl-0 l:ml-2  h-full">
            <div
              style={{
                width: '100%',
                height: `calc(100% - 53px)`,
                borderRadius: '7px',
              }}
            >
              <DataGrid
                hideFooterPagination
                hideFooter
                headerHeight={0}
                rowHeight={35}
                showCellRightBorder={true}
                showColumnRightBorder={true}
                disableColumnMenu={true}
                loading={loading}
                getRowId={(p) => p.CODE}
                components={{
                  Header: () => {
                    const headerDetails =
                      window.innerWidth <= 1024
                        ? routeColumnsTab
                        : routeColumns;
                    return (
                      <div className="  bg-[rgb(56,53,53)] flex rounded-sm rounded-b-none">
                        {headerDetails?.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className=" text-white flex justify-center items-center py-3"
                              style={
                                item.flex
                                  ? index < headerDetails.length - 1
                                    ? {
                                        flex: item.flex,
                                        borderRight: '1px solid #4a4948',
                                        borderBottom: '1px solid #4a4948',
                                      }
                                    : {
                                        flex: item.flex,
                                        borderBottom: '1px solid #4a4948',
                                      }
                                  : index < headerDetails.length - 1
                                  ? {
                                      width: item.width,

                                      borderRight: '1px solid white',
                                      borderBottom: '1px solid #4a4948',
                                    }
                                  : {
                                      width: item.width,
                                      borderBottom: '1px solid #4a4948',
                                    }
                              }
                            >
                              {item.renderHeader()}
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                  NoRowsOverlay: () => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          height: '100%',
                          width: '100%',
                        }}
                      >
                        No Records Found
                      </div>
                    );
                  },
                }}
                sx={
                  window.innerWidth < 770
                    ? {
                        backgroundColor: 'white',

                        fontSize: 10,
                        '& .super-app-theme--header': {
                          backgroundColor: '#f5f0e8',
                          display: 'none',
                        },

                        borderRadius: 2,
                      }
                    : {
                        backgroundColor: 'white',
                        fontSize: 13,
                        borderRadius: 2,
                        '& .super-app-theme--header': {
                          backgroundColor: '#f5f0e8',
                          borderRight: `1px solid #4a4948`,

                          display: 'none',
                        },
                      }
                }
                columns={
                  window.innerWidth <= 1024 ? routeColumnsTab : routeColumns
                }
                rows={filterRouteData.length > 0 ? filterRouteData : routeRows}
                pageSize={100}
                rowsPerPageOptions={[100]}
                disableColumnSelector
                disableColumnReorder
              />
            </div>
            <div
              style={{
                width: '100%',
                height: '45px',
                borderRadius: '7px',
                border: '1px solid #b5b3b3',
              }}
              className="mt-2"
            >
              <input
                value={searchRoute}
                onChange={(e) => {
                  setSearchRoute(e.target.value);
                  setFilterRouteData('');
                }}
                style={{ borderRadius: '7px' }}
                type="search"
                className="h-full w-full focus:outline-none px-2 text-lg"
                placeholder="Search Route"
              ></input>
            </div>
          </div>
        </div>

        {printRef && windowSize ? (
          <div
            ref={printRef}
            style={{ width: '100%', display: 'block', visibility: 'hidden' }}
          >
            <PrintPartyBalanceReport
              date={date}
              date1={date1}
              data={rows}
              // data={balanceData}
              stationId={stationId}
              sum={OpeningBal}
              sumDebit={sumDebit}
              sumCredit={sumCredit}
              sumBalance={netBalance}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default PartyBalanceReport;
