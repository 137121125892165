import React, { useEffect, useState } from 'react';
import ModalHeaderStyle from '../utility/component/styles/modalHeader';
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectCompanyAddress,
  selectEmail,
  selectUserType,
} from '../utility/Redux/profile';
import { setSessionExpired } from '../utility/Redux/modal';
const UserControl = () => {
  const navigate = useNavigate();
  const userEmail = useSelector(selectEmail);
  const companyDetail = useSelector(selectCompanyAddress);
  const userType = useSelector(selectUserType);
  const [list, setList] = useState([]);
  const dispatch = useDispatch();
  const getListStationMaster = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    // const hmac = CryptoJS.HmacSHA256(message, secret);
    const hmac = CryptoJS.HmacSHA256(userEmail, secretPass);
    // Convert the HMAC to a string and encode it using Base64
    const hmacString = hmac.toString(CryptoJS.enc.Base64);

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${hmacString}`,
    };

    axios
      .post(
        `/api/users/userHelp`,
        {
          company_email: companyDetail.email,
          user_email: userEmail,
          user_mobile: '',
          user_type: userType,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setList(data);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  useEffect(() => {
    getListStationMaster();
  }, []);

  return (
    <div className="w-full h-full flex justify-center bg-[#f5f5f7] p-8 overflow-y-scroll">
      <div className="text-sm  rounded-sm  h-full">
        <div className="bg-white   ">
          <ModalHeaderStyle
            header={'UserControl'}
            onClose={() => {
              navigate('/setting');
            }}
          />
          <div
            style={{
              height: `calc(100% - 150px)`,
            }}
            className="      flex flex-col  "
          ></div>
        </div>
      </div>
    </div>
  );
};

export default UserControl;
