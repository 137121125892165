import { Box, Modal } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { BackButton } from '../buttons';
import { selectToken } from '../../Redux/security';
import { selectPrefix, selectWidth } from '../../Redux/profile';
import { toProperCase } from '../format';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';
import {
  SelectModEmployeeMasterHelpStatus,
  setModEmployeeMasterHelpStatus,
  setSessionExpired,
} from '../../Redux/modal';
import { DownloadFile } from '../../functions/firebase';
import RoutbillingLogo from '../styles/routbillingLogo';
import MasterHelp from './masterHelp';

const ModEmployeeMasterHelp = ({
  onRowClick,

  setWorkerData,
  type,
}) => {
  const dispatch = useDispatch();

  const token = useSelector(selectToken);
  const [fullList, setFulllist] = useState([]);
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    height: '80%',
    width: 'auto',
    padding: '2px',
    borderRadius: '8px',
    // Change the shadow color
    overflow: 'hidden',
    backgroundColor: 'white',
  };

  const status = useSelector(SelectModEmployeeMasterHelpStatus);
  const [rows, setRows] = useState([]);
  const width = useSelector(selectWidth);
  const columns = [
    {
      width:
        width <= 400
          ? 50
          : width <= 768
          ? 50
          : width <= 1280
          ? 80
          : width <= 1440
          ? 80
          : 150,
      headerAlign: 'center',
      align: 'left',
      field: 'code',
      renderHeader: (params) => <div className="text-md">Code</div>,
    },
    {
      field: 'employee_name',
      renderHeader: (params) => <div className="text-md">Employee </div>,
      width:
        width <= 400
          ? 100
          : width <= 768
          ? 100
          : width <= 1280
          ? 150
          : width <= 1440
          ? 150
          : 150,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'address',
      renderHeader: (params) => <div className="text-md">Address</div>,
      width:
        width <= 400
          ? 80
          : width <= 768
          ? 100
          : width <= 1280
          ? 150
          : width <= 1440
          ? 150
          : 150,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = toProperCase(params.value);
        return <div>{runningCaseValue}</div>;
      },
    },
    {
      field: 'mobile',
      renderHeader: (params) => <div className="text-md">Mobile</div>,
      width:
        width <= 400
          ? 100
          : width <= 768
          ? 80
          : width <= 1280
          ? 120
          : width <= 1440
          ? 150
          : 150,
      headerAlign: 'center',
      align: 'left',
    },
  ];

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (searchQuery) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchQuery.substring(0, 1))) {
          return item.mobile.toString().includes(searchQuery);
        } else {
          return item.employee_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase());
        }
      });
      setRows(searchItems);
    } else {
      setRows(fullList);
    }
  }, [searchQuery]);

  const [loading, setloading] = useState(true);

  const prefix = useSelector(selectPrefix);

  const getData = async () => {
    await axios
      .post(
        '/api/masters/listEmployeeMaster',
        {
          table_prefix: prefix,
          MOBILE: '',
          CODE: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        let a = p.data.response;
        setRows(a);
        setFulllist(a);
        if (type === 'master') {
          setWorkerData(a);
        }
        setloading(false);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  useEffect(() => {
    if (status) {
      getData();
    }
  }, [status]);

  const handleClose = () => {
    dispatch(setModEmployeeMasterHelpStatus(false));
    setSearchQuery('');
  };

  return (
    <div>
      <MasterHelp
        status={status}
        onClose={() => {
          handleClose();
          setRows([]);
        }}
        style={style}
        title={'Employee Master | Help'}
        columns={columns}
        list={rows}
        rowId={(p) => p.code}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onRowClick={(p) => {
          onRowClick(p);
          setRows([]);
        }}
      />
    </div>
  );
};

export default ModEmployeeMasterHelp;
