import React from 'react';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import axios from 'axios';
import { LoadOrderDetials } from './loadOrder';

const DeleteItem = (
  prefix,
  sid,
  token,
  icode,
  serial,
  dispatch,
  setOrderDetails,
  date
) => {
  dispatch(setSpinnerLoading('Deleting Item'));
  const data = {
    table_prefix: prefix,
    SERIAL_NO: serial,
    SID: sid,
    ICODE: icode,
  };
  axios
    .post(
      '/api/orderBooking/deleteOrderItem',
      data,

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((p) => {
      dispatch(
        setAlertWithTitle({
          title: 'Deleted',
          msg: 'Item Deleted',
        })
      );
      LoadOrderDetials(setOrderDetails, prefix, token, date, sid);
    })
    .catch((error) => {
      console.log(error);
      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export default DeleteItem;
