import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { setAlertWithTitle, setSessionExpired } from '../../Redux/modal';
import MasterHelp from './masterHelp';
import {
  selectCompanyAddress,
  selectEmail,
  selectUserType,
  selectWidth,
} from '../../Redux/profile';
import CryptoJS from 'crypto-js';
import axios from 'axios';
import { ref, set } from 'firebase/database';
import database from '../../../routeBilling/issueStock/firebase';
import { Button } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: '85%',
  backgroundColor: 'white',
  padding: '2px',
  borderRadius: '8px',
  overflow: 'hidden',
};

export default function ModUserHelp({ comapany, onRowClick, onClose, status }) {
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState('');
  const width = useSelector(selectWidth);
  const columns = React.useMemo(
    () => [
      {
        field: 'USER_NAME',
        type: 'string',
        width:
          width <= 768 ? 100 : width <= 1280 ? 180 : width <= 1440 ? 150 : 180,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'User Name'}</strong>,
      },
      {
        field: 'STATION_ID',
        type: 'string',
        width:
          width <= 768 ? 80 : width <= 1280 ? 80 : width <= 1440 ? 100 : 80,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Station Id'}</strong>,
      },
      {
        field: 'STATION_NAME',
        type: 'number',
        width:
          width <= 768 ? 100 : width <= 1280 ? 100 : width <= 1440 ? 100 : 150,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <strong>{'Station Name'}</strong>,
      },
      {
        field: 'USER_EMAIL',
        type: 'number',
        width:
          width <= 768 ? 150 : width <= 1280 ? 170 : width <= 1440 ? 100 : 250,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <strong>{'User Email'}</strong>,
      },
      {
        field: 'USER_MOBILE',
        type: 'number',
        width:
          width <= 768 ? 100 : width <= 1280 ? 90 : width <= 1440 ? 100 : 150,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <strong>{'User Mobile'}</strong>,
      },
      {
        field: 'USER_TYPE',
        type: 'number',
        width:
          width <= 768 ? 100 : width <= 1280 ? 0 : width <= 1440 ? 100 : 110,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => (
          <strong
            style={{
              display: width <= 1280 ? 'none' : 'flex',
            }}
          >
            {'User Type'}
          </strong>
        ),
      },
      {
        field: 'COMPANY_NAME',
        type: 'number',
        width:
          width <= 768 ? 130 : width <= 1280 ? 0 : width <= 1440 ? 150 : 180,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',
        renderCell: (p) => {
          const text = p.row.COMPANY_NAME;
          const words = text.split(' ');
          const result =
            words.reduce((acc, word) => {
              if (word) {
                acc += word[0];
              }
              return acc;
            }, '') + '_';
          const oldconnectedRef = ref(
            database,
            `${result.toLowerCase()}/user/${p.row.CODE}/status/connection`
          );
          return (
            <div className="flex w-full justify-between pr-4">
              <div>{p.value}</div>
              <div>
                <div>
                  <Button
                    style={{
                      padding: 0,
                      paddingLeft: 5,
                      textTransform: 'none',
                      color: '#de7d4b',
                    }}
                    onClick={() => {
                      set(oldconnectedRef, 'd');
                      if (p.row.USER_TYPE === 'MANAGER') {
                        const old2connectedRef = ref(
                          database,
                          `${result.toLowerCase()}/user/${
                            p.row.CODE
                          }/status2/connection`
                        );
                        const old3connectedRef = ref(
                          database,
                          `${result.toLowerCase()}/user/${
                            p.row.CODE
                          }/status3/connection`
                        );
                        set(old2connectedRef, 'd');
                        set(old3connectedRef, 'd');
                      }
                      dispatch(
                        setAlertWithTitle({
                          title: 'Alert',
                          msg: 'User signout',
                        })
                      );
                    }}
                    variant={'text'}
                  >
                    Signout
                  </Button>
                </div>
              </div>
            </div>
          );
        },
        renderHeader: (params) => (
          <strong
            style={{
              display: width <= 1280 ? 'none' : 'flex',
            }}
          >
            {'Company Name'}
          </strong>
        ),
      },
    ],
    []
  );

  const companyDetail = useSelector(selectCompanyAddress);
  const [list, setList] = useState([]);
  const [fullList, setFullList] = useState([]);
  const userEmail = useSelector(selectEmail);
  const userType = useSelector(selectUserType);
  const getListStationMaster = () => {
    const secretPass = process.env.REACT_APP_AUTH_SECRET_KEY;

    const hmac = CryptoJS.HmacSHA256(
      comapany?.userEmail ? comapany.userEmail : userEmail,
      secretPass
    );

    const hmacString = hmac.toString(CryptoJS.enc.Base64);
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${hmacString}`,
    };
    const data = {
      company_email: comapany?.userEmail
        ? comapany?.companyEmail
        : companyDetail.email,
      user_email: comapany?.userEmail ? comapany?.userEmail : userEmail,
      user_mobile: '',
      user_type: comapany?.userEmail ? 'USER' : userType,
    };

    axios
      .post(`/api/users/userHelp`, data, {
        headers: headers,
      })
      .then((res) => {
        if (!res.data.error) {
          const data = res.data.response;
          setList(data);
          setFullList(data);
          console.log(data);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      })
      .finally(() => {});
  };

  useEffect(() => {
    if (status) {
      getListStationMaster();
    }
  }, [status]);

  useEffect(() => {
    if (searchText) {
      const regex = /\d+/;

      const searchItems = fullList.filter((item) => {
        if (regex.test(searchText.substring(0, 1))) {
          return item.CODE.toString().includes(searchText);
        } else {
          return item.STATION_NAME.toLowerCase().includes(
            searchText.toLowerCase()
          );
        }
      });

      setList(searchItems);
    } else {
      setList(fullList);
    }
  }, [searchText]);

  return (
    <div className="bg-white">
      <MasterHelp
        status={status}
        onClose={() => {
          onClose();
          setList([]);
          setSearchText('');
        }}
        style={style}
        title={'User - Help'}
        columns={columns}
        list={list}
        rowId={(p) => p.CODE}
        searchQuery={searchText}
        setSearchQuery={setSearchText}
        onRowClick={(p) => {
          onRowClick(p);
          setList([]);
          setSearchText('');
        }}
      />
    </div>
  );
}
