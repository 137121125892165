import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../../Redux/modal';
import axios from 'axios';
import { selectPrefix, selectWidth } from '../../Redux/profile';
import moment from 'moment';
import { selectToken } from '../../Redux/security';
import { useState } from 'react';
import { FormatNumber2 } from '../tools';
import AlertModalTwoButton from './alertWithTwoButton';
import DiscountFotter from '../itemWiseDiscount';
import GeniusDatagrid from '../datagrid/geniusDatagrid';
import { SaveButton, UndoButton } from '../buttons';

const PartyWiseDiscount = ({
  stationId,
  setStatus,
  list,
  setlist,
  date,
  stationName,
}) => {
  const dispatch = useDispatch();
  const [voucherList, setVoucherList] = useState([]);
  const windowWidth = useSelector(selectWidth);
  const [fullList, setFullList] = useState([]);
  const [alertTwoButtonModal, setAlertTwoButtonModal] = useState({
    title: '',
    msg: '',
    status: false,
    setStatus: '',
    button1: '',
    button1Click: '',
    button2: '',
    button2Click: '',
  });

  const columns = [
    {
      width: windowWidth > 768 ? 100 : 60,
      headerAlign: 'center',
      align: 'center',
      field: 'ACCOUNT_NO',
      renderHeader: (params) => <div>Acc No.</div>,
    },
    {
      width: windowWidth > 768 ? 250 : 100,
      headerAlign: 'center',
      align: 'left',
      field: 'NAME',
      renderHeader: (params) => <div>Party Name</div>,
    },
    {
      width: windowWidth > 768 ? 120 : 60,

      field: 'VOUCHER_NO',
      renderHeader: (params) => <div>Voucher</div>,
      headerAlign: 'center',

      align: 'right',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return param.value;
        }
      },
    },
    {
      width: windowWidth > 768 ? 120 : 60,

      field: 'PAY_MODE',
      renderHeader: (params) => <div>Pay Mode</div>,
      headerAlign: 'center',
    },
    {
      width: windowWidth > 768 ? 150 : 60,
      editable: true,
      field: 'AMOUNT_PAID',
      renderHeader: (params) => <div>Discount</div>,
      headerAlign: 'center',

      align: 'right',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return <div className="pr-4">{FormatNumber2(param.value)}</div>;
        }
      },
    },
  ];

  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const getCollection = () => {
    const data = {
      table_prefix: prefix,
      SID: stationId,
      DATE: moment(date).format('YYYY-MM-DD'),
    };
    dispatch(setSpinnerLoading('Loading'));
    axios
      .post(
        `/api/dayEndSumRoute/stationLessSum`,

        data,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async function (response) {
        if (!response.data.error) {
          const data = response.data.response;

          setFullList(data);
          setlist(data);
          let l = [];

          for (let i = 0; i < data.length; i++) {
            if (data[i].AMOUNT_PAID !== 0) {
              l.push({
                VOUCHER_NO: data[i].VOUCHER_NO ? data[i].VOUCHER_NO : '',
                DATE: moment(date).format('YYYY-MM-DD'),
                PAY_MODE: data[i].PAY_MODE ? data[i].PAY_MODE : 'Cash',
                CARD_NO: '',
                BANK_NAME: '',
                EPAYMENT_BANK: '',
                ACCOUNT_NO: data[i].ACCOUNT_NO,
                NAME: data[i].NAME,
                ADDRESS: data[i].ADDRESS,
                MOBILE: data[i].MOBILE,
                AMOUNT_RECEIVED: data[i].AMOUNT_RECEIVED
                  ? data[i].AMOUNT_RECEIVED
                  : 0,
                AMOUNT_PAID: data[i].AMOUNT_PAID ? data[i].AMOUNT_PAID : 0,
                AMOUNT_DESC: 'Less',
                INVNO: '0',
                SID: stationId,
                SNAME: stationName,
                LATITUDE: '',
                LONGITUDE: '',
                APPROVED: 'NO',
              });
            }
          }
          setVoucherList(l);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .catch(function (error) {
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        } else {
          dispatch(
            setAlertWithTitle({
              title: 'Error',
              msg: 'Something Went Wrong!',
            })
          );
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const handleSave = () => {
    if (voucherList[0]) {
      dispatch(setSpinnerLoading('Saving'));
      const list = {
        table_prefix: prefix,
        cart_item: voucherList,
      };
      axios
        .post(`/api/voucherRoute/saveVoucher`, list, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(function (response) {
          if (response.status === 200) {
            dispatch(
              setAlertWithTitle({
                title: 'success',
                msg: 'Item saved successfully',
                lottie: 'success',
              })
            );

            setStatus(false);
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Soemthing went wrong',
                lottie: 'reject',
              })
            );
          }
        })
        .catch(function (error) {
          if (error?.response.data?.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error',
                msg: 'Soemthing went wrong',
                lottie: 'reject',
              })
            );
          }
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'No data',
          msg: 'No any changes found to saved.',
        })
      );
    }
  };

  const editCell = ({ field, id, value }) => {
    if (field === 'AMOUNT_PAID') {
      if (!isNaN(value)) {
        const filterdData = voucherList.filter(
          (item) => item.ACCOUNT_NO === id
        );
        const filterdListData = list.filter((item) => item.ACCOUNT_NO === id);

        if (filterdData[0]) {
          setVoucherList((f) => {
            return f.map((i) => {
              if (i.ACCOUNT_NO === id) {
                return {
                  VOUCHER_NO: filterdData[0].VOUCHER_NO
                    ? filterdData[0].VOUCHER_NO
                    : '',
                  DATE: moment(date).format('YYYY-MM-DD'),
                  PAY_MODE: filterdData[0].PAY_MODE
                    ? filterdData[0].PAY_MODE
                    : 'Cash',
                  CARD_NO: '',
                  BANK_NAME: '',
                  EPAYMENT_BANK: '',
                  ACCOUNT_NO: filterdData[0].ACCOUNT_NO,
                  NAME: filterdData[0].NAME,
                  ADDRESS: filterdData[0].ADDRESS,
                  MOBILE: filterdData[0].MOBILE,
                  AMOUNT_RECEIVED: filterdData[0].AMOUNT_RECEIVED
                    ? filterdData[0].AMOUNT_RECEIVED
                    : 0,
                  AMOUNT_PAID: value ? Number(value) : 0,
                  AMOUNT_DESC: 'Less',
                  INVNO: '0',
                  SID: stationId,
                  SNAME: stationName,
                  LATITUDE: '',
                  LONGITUDE: '',
                  APPROVED: 'NO',
                };
              } else {
                return i;
              }
            });
          });
        } else {
          setVoucherList((v) => {
            return [
              ...v,
              {
                VOUCHER_NO: filterdListData[0].VOUCHER_NO
                  ? filterdListData[0].VOUCHER_NO
                  : '',
                DATE: moment(date).format('YYYY-MM-DD'),
                PAY_MODE: filterdListData[0].PAY_MODE
                  ? filterdListData[0].PAY_MODE
                  : 'Cash',
                CARD_NO: '',
                BANK_NAME: '',
                EPAYMENT_BANK: '',
                ACCOUNT_NO: filterdListData[0].ACCOUNT_NO,
                NAME: filterdListData[0].NAME,
                ADDRESS: filterdListData[0].ADDRESS,
                MOBILE: filterdListData[0].MOBILE,
                AMOUNT_RECEIVED: filterdListData[0].AMOUNT_RECEIVED
                  ? filterdListData[0].AMOUNT_RECEIVED
                  : 0,
                AMOUNT_PAID: value ? Number(value) : 0,
                AMOUNT_DESC: 'Less',
                INVNO: '0',
                SID: stationId,
                SNAME: stationName,
                LATITUDE: '',
                LONGITUDE: '',
                APPROVED: 'NO',
              },
            ];
          });
        }
      }
    }
  };

  useEffect(() => {
    getCollection();
  }, []);
  return (
    <div className="h-full p-2 ">
      <div
        style={{
          height: window.innerHeight * 0.5,
          width:
            columns[0].width +
            columns[1].width +
            columns[2].width +
            columns[3].width +
            columns[4].width +
            20,
        }}
      >
        {' '}
        <GeniusDatagrid
          rowID={(row) => row.ACCOUNT_NO}
          columns={columns}
          list={list}
          editCell={editCell}
          showPagination={true}
        />
      </div>
      <DiscountFotter
        rows={list}
        columns={columns}
        handleSave={handleSave}
        handleUndo={getCollection}
        voucherList={voucherList}
        setlist={setlist}
        fullList={fullList}
      />
      <div className="flex justify-end pt-2">
        <SaveButton onClick={handleSave} />
        <UndoButton onClick={getCollection} />
      </div>
      <AlertModalTwoButton
        details={alertTwoButtonModal}
        setAlertTwoButtonModal={setAlertTwoButtonModal}
      />
    </div>
  );
};

export default PartyWiseDiscount;
