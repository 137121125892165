import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../utility/Redux/security';
import { selectPrefix, selectWidth } from '../utility/Redux/profile';
import { useState } from 'react';
import {
  setAlertWithTitle,
  setSessionExpired,
  setSpinnerLoading,
} from '../utility/Redux/modal';
import axios from 'axios';
import { useEffect } from 'react';
import GeniustextInput from '../utility/component/mastertext';
import GeniusDatagrid from '../utility/component/datagrid/geniusDatagrid';
import {
  ModifyButton,
  SaveButton,
  UndoButton,
} from '../utility/component/buttons';
import { useFocus } from '../utility/hooks/hooks';

const GeniusSectionMaster = () => {
  const dispatch = useDispatch();
  const prefix = useSelector(selectPrefix);
  const token = useSelector(selectToken);

  const windowWidth = useSelector(selectWidth);
  const [id, setId] = useState();
  const [secName, setSecName] = useState();
  const [rows, setRows] = useState([]);
  const [secError, setSecError] = useFocus();

  const columns = [
    {
      field: 'SECTION_NAME',
      type: 'string',
      renderHeader: (params) => <div>Section Name</div>,
      width: windowWidth > 764 ? 400 : 300,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params) => {
        const runningCaseValue = params.value;
        return <div>{runningCaseValue}</div>;
      },
    },
  ];

  const getData = async () => {
    // dispatch(setSpinnerLoading('Loading'));

    await axios
      .post(
        '/api/issueMasterRoute/listSectionMaster',
        {
          table_prefix: prefix,
          SECTION_NAME: '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((p) => {
        setRows(p.data.response);
      })
      .catch((error) => {
        console.log(error.response.data.name);
        if (error.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
          console.log(error.response);
        }
      })
      .finally(() => {
        setSecError();
      });
  };

  useEffect(() => {
    getData();
  }, []);

  const saveSectionMasterApi = () => {
    if (secName) {
      const isDuplicateMobile = rows.some(
        (row) => row.SECTION_NAME === secName
      );

      if (isDuplicateMobile) {
        dispatch(
          setAlertWithTitle({
            title: 'Information Error!',
            msg: 'This Section is already exists. Please choose a different section.',
          })
        );
        return;
      }

      const data = {
        table_prefix: prefix,
        CODE: id ? id : '',
        SECTION_NAME: secName,
        REGIONAL_INAME: '',
      };

      id
        ? dispatch(setSpinnerLoading('Updating'))
        : dispatch(setSpinnerLoading('Saving'));
      axios
        .post(`/api/issueMasterRoute/saveSectionMaster`, data, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async function (response) {
          if (!response.data.error) {
            id
              ? dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Updated successfully.',
                    lottie: 'success',
                  })
                )
              : dispatch(
                  setAlertWithTitle({
                    title: 'Success.',
                    msg: 'Data Saved successfully.',
                    lottie: 'success',
                  })
                );
          } else {
            dispatch(
              setAlertWithTitle({
                title: 'Error 112',
                msg: response.data.error,
              })
            );
            console.log(!response.data.error);
          }
        })
        .catch(function (error) {
          if (error.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
            console.log(error.response);
          }
          console.log(error);
          dispatch(
            setAlertWithTitle({
              title: 'ERROR',
              msg: 'Something went wrong.',
              lottie: 'reject',
            })
          );
        })
        .finally(() => {
          dispatch(setSpinnerLoading(false));
          setSecName('');
          setId('');
          getData();
        });
    } else {
      if (!secName) {
        dispatch(
          setAlertWithTitle({
            title: 'ERROR',
            msg: 'Please Enter Section Name.',
            lottie: 'reject',
          })
        );
        setSecError();
      }
    }
  };

  return (
    <div className=" bg-white    border-brown px-5 max-sm:px-2">
      <div id="input" className="flex mt-4">
        <div>
          <GeniustextInput
            title={'Section Name'}
            width={windowWidth > 764 ? '400px' : '300px'}
            type={'text'}
            //   search={handleOpenDeptHelp}
            value={secName}
            onChange={(e) => {
              setSecName(e.target.value);
            }}
            refrance={secError}
          />
        </div>
      </div>

      <div
        style={{
          width: columns[0].width + 0,
        }}
        className=" h-[400px] mt-1 flex"
      >
        <GeniusDatagrid
          rowID={(e) => e.CODE}
          onRowClick={(p) => {
            setId(p.row.CODE);
            setSecName(p.row.SECTION_NAME);
          }}
          columns={columns}
          list={rows}
        />
      </div>

      <div className="flex w-full justify-end items-center py-5">
        {id ? (
          <ModifyButton onClick={saveSectionMasterApi} />
        ) : (
          <SaveButton onClick={saveSectionMasterApi} />
        )}

        <UndoButton
          onClick={() => {
            setSecName('');
            setId('');
          }}
        />
      </div>
    </div>
  );
};

export default GeniusSectionMaster;
