import { DataGrid, GridPagination } from '@mui/x-data-grid';
import { Skeleton } from '@mui/material';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectIssuesList,
  selectPaymodeReport,
  setIssuedSummeryList,
  setPaymodeSaleReport,
} from '../../../utility/Redux/list';
import { FormatNumber3, FormatNumber2 } from '../../../utility/component/tools';
import {
  selectSpinnerLoading,
  setloading,
  setSessionExpired,
  setSpinnerLoading,
  setStationWiseIssueReportModalStatus,
} from '../../../utility/Redux/modal';
import { selectToken } from '../../../utility/Redux/security';
import { SelectDate } from '../../../utility/Redux/refresh';
import { AdminTotalFooter } from '../../../utility/component/FootrAdmnDashboard';
import { setStationWiseIssueReportApiDetails } from '../../../utility/Redux/tableOrder';
import StationWiseIssueModal from './biiling/modal/modStationWiseIssueReport';
import { selectPrefix } from '../../../utility/Redux/profile';

import { useLocation } from 'react-router-dom';
import { FromToDateDashBoard } from '../../../utility/component/fromToDateDashboard';
import DashCollectionsButton from '../../../utility/component/dashCollectionsButton';

const width = (window.innerWidth * 1) / 100;

const columns = [
  {
    field: 'SID',
    type: 'string',
    width: width * 6,
    align: 'center',
    headerClassName: 'super-app-theme--header ',
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-20 text-center leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        {'Station Id'}{' '}
      </strong>
    ),
  },

  {
    field: 'SNAME',
    type: 'string',
    flex: 1,
    headerClassName: 'super-app-theme--header',
    headerAlign: 'center',
    renderHeader: (params) => (
      <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
        {' '}
        Station Name{' '}
      </strong>
    ),
  },

  {
    field: 'IQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 6,
    cellClassName: 'super-app-theme--cell',
    headerAlign: 'center',
    align: 'right',
    renderCell: (params) => {
      if (params.value) {
        return <div>{FormatNumber3(params.value)} </div>;
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong className="text-[#555a66] " style={{ fontSize: 13 }}>
        {' '}
        Issued Qnty
      </strong>
    ),
  },

  {
    field: 'IAMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    cellClassName: 'super-app-theme--cell',
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong className="text-[#555a66]  " style={{ fontSize: 13 }}>
        {' '}
        Issued Amt.
      </strong>
    ),
  },

  {
    field: 'SQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 6,

    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong className="text-[#555a66]  " style={{ fontSize: 13 }}>
        {' '}
        Sales Qnty
      </strong>
    ),
  },

  {
    field: 'AMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    align: 'right',

    renderHeader: (params) => (
      <strong className="text-[#555a66] " style={{ fontSize: 13 }}>
        {' '}
        Sale Amount
      </strong>
    ),
  },

  {
    field: 'BAL_QNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    cellClassName: 'super-app-theme--cell',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    align: 'right',

    renderHeader: (params) => (
      <strong className="text-[#555a66] text-center " style={{ fontSize: 13 }}>
        {' '}
        Balance Qnty
      </strong>
    ),
  },

  {
    field: 'BAL_AMT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    cellClassName: 'super-app-theme--cell',
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong className="text-[#555a66] " style={{ fontSize: 13 }}>
        {' '}
        Balance Amt.
      </strong>
    ),
  },

  {
    field: 'CASH_AMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',

    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 text-center leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        Cash Amt.
      </strong>
    ),
  },

  {
    field: 'CARD_AMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    align: 'right',
    headerAlign: 'center',
    headerAlign: 'center',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] text-center w-16 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        Card Amt.
      </strong>
    ),
  },

  {
    field: 'EPAYMENT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    align: 'right',
    headerAlign: 'center',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        ePayment
      </strong>
    ),
  },

  {
    field: 'CHEQUE_AMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    align: 'right',
    headerAlign: 'center',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong className="text-[#555a66] " style={{ fontSize: 13 }}>
        {' '}
        Cheque Amt.
      </strong>
    ),
  },

  {
    field: 'BTC_AMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 6,
    align: 'right',
    headerAlign: 'center',
    renderCell: (params) => {
      if (params.value) {
        return <div>{FormatNumber2(params.value)} </div>;
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 text-center leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        Btc Amt.
      </strong>
    ),
  },
  {
    field: 'DISQ_AMT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 7,
    align: 'right',
    headerAlign: 'center',
    renderCell: (params) => {
      if (params.value) {
        return <div className="pr-4">{FormatNumber2(params.value)} </div>;
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-16 text-center leading-4 whitespace-normal"
        style={{ fontSize: 13 }}
      >
        {' '}
        Disq Amt.
      </strong>
    ),
  },
];
const columnsMobile = [
  {
    field: 'SID',
    type: 'string',
    width: width * 15,
    align: 'center',
    headerClassName: 'super-app-theme--header ',
    renderHeader: (params) => (
      <strong
        className="text-[#555a66]  text-center leading-4 whitespace-normal"
        style={{ fontSize: 11 }}
      >
        {' '}
        {'station'}{' '}
      </strong>
    ),
  },
  // {
  //   field: 'SNAME',
  //   type: 'string',
  //   width: width * 30,
  //   headerClassName: 'super-app-theme--header',
  //   headerAlign: 'center',
  //   renderHeader: (params) => (
  //     <strong className="text-[#555a66]" style={{ fontSize: 11 }}>
  //       {' '}
  //       Station{' '}
  //     </strong>
  //   ),
  // },

  {
    field: 'IQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 20,
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-12 text-center leading-4 whitespace-normal"
        style={{ fontSize: 11 }}
      >
        {' '}
        Issued Qnty
      </strong>
    ),
  },

  {
    field: 'IAMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 20,
    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66] w-12 text-center leading-4 whitespace-normal "
        style={{ fontSize: 11 }}
      >
        {' '}
        Issued Amt.
      </strong>
    ),
  },
  {
    field: 'SQNTY',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    flex: 1,

    headerAlign: 'center',
    align: 'right',
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber3(params.value);
      } else {
        return '';
      }
    },
    renderHeader: (params) => (
      <strong
        className="text-[#555a66]  w-12 text-center leading-4 whitespace-normal "
        style={{ fontSize: 11 }}
      >
        {' '}
        Sales Qnty
      </strong>
    ),
  },

  {
    field: 'AMOUNT',
    type: 'number',
    headerClassName: 'super-app-theme--header',
    width: width * 20,
    valueFormatter: (params) => {
      if (params.value) {
        return FormatNumber2(params.value);
      } else {
        return '';
      }
    },
    headerAlign: 'center',
    align: 'right',

    renderHeader: (params) => (
      <strong
        className="text-[#555a66] text-center w-16 leading-4 whitespace-normal"
        style={{ fontSize: 11 }}
      >
        {' '}
        Amount
      </strong>
    ),
  },
];

const AdminDashboard = () => {
  const location = useLocation();

  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);
  const checkDateData = useSelector(SelectDate);
  const issuedSummeryList = useSelector(selectIssuesList);
  const currency = location?.state?.currency;
  const spinnerloading = useSelector(selectSpinnerLoading);
  const dispatch = useDispatch();
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const paymodeReport = useSelector(selectPaymodeReport);

  const getIssuedData = (date) => {
    dispatch(setSpinnerLoading('Loading'));
    const data = {
      table_prefix: prefix,
      // station_id: "",
      from_date: date ? date : checkDateData,
      to_date: date ? date : checkDateData,
    };
    axios
      .post(`/api/dashBoardManager/stationWiseIssued`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);

        if (!res.data.error) {
          const data = res.data.response;

          dispatch(setIssuedSummeryList(data));
          getPaymodeDetails(date);
        }
      })
      .catch((e) => {
        console.log(e);
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setSpinnerLoading(false));
      });
  };

  const getPaymodeDetails = (date) => {
    dispatch(setloading(true));
    const data = {
      table_prefix: prefix,
      sid: '',
      from_date: date ? date : checkDateData,
      to_date: date ? date : checkDateData,
    };
    axios
      .post(`/api/dashBoradReports/payModeSale`, data, {
        headers: headers,
      })
      .then((res) => {
        console.log(res);
        if (!res.data.error) {
          if (res.data.response[0]) {
            dispatch(setPaymodeSaleReport(res.data.response));
          }
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        dispatch(setloading(false));
      });
  };

  useEffect(() => {
    if (!issuedSummeryList[0]) {
      getIssuedData();
    }
  }, []);
  const c = window.innerWidth <= 768 ? columnsMobile : columns;
  return (
    <div className=" overflow-y-scroll h-full  p-1 md:p-1    flex flex-col   ">
      <div
        style={{
          height: '100%',
        }}
      >
        <FromToDateDashBoard
          refresh={() => {
            getIssuedData();
          }}
          getIssuedData={getIssuedData}
        />

        {!spinnerloading ? (
          <div
            style={{
              height: `calc(100% - 80px)`,
            }}
            className="pt-1 md:pt-2"
          >
            <DataGrid
              sx={
                window.innerWidth <= 768
                  ? {
                      backgroundColor: 'white',

                      '& .super-app-theme--cell': {
                        backgroundColor: '#e9ebf2',
                      },
                      fontSize: 11,
                      '& .super-app-theme--header': {
                        backgroundColor: '#E6E6E3',
                        // display: 'none',
                      },
                    }
                  : {
                      backgroundColor: 'white',

                      '& .super-app-theme--cell': {
                        backgroundColor: '#e9ebf2',
                      },
                      fontSize: 13,
                      '& .super-app-theme--header': {
                        backgroundColor: '#E6E6E3',
                        // display: 'none',
                      },
                    }
              }
              headerHeight={40}
              scrollbarSize={0}
              rowHeight={33}
              columns={window.innerWidth <= 768 ? columnsMobile : columns}
              rows={issuedSummeryList ? issuedSummeryList : []}
              pageSize={100}
              onRowDoubleClick={(params) => {
                const data = params.row;

                dispatch(
                  setStationWiseIssueReportApiDetails({
                    stationId: data.SID,
                    date: checkDateData,
                  })
                );
                dispatch(setStationWiseIssueReportModalStatus(true));
              }}
              rowsPerPageOptions={[100]}
              experimentalFeatures={{ newEditingApi: true }}
              pagination
              getRowId={(i) => i.SID}
              showColumnRightBorder={true}
              showCellRightBorder={true}
              disableColumnSelector
              disableColumnReorder
              disableColumnMenu={true}
              components={{
                Pagination: GridPagination,
                // Header: () => <GeniusHeader columns={columns} />,
                Footer: () => (
                  <AdminTotalFooter
                    rows={issuedSummeryList ? issuedSummeryList : []}
                    columns={window.innerWidth <= 768 ? columnsMobile : columns}
                  />
                ),
              }}
            />
          </div>
        ) : (
          <div className="w-full ">
            <div className="flex w-full ">
              {c.map((e, k) => {
                return (
                  <Skeleton
                    key={k}
                    width={e.width ? e.width : 'auto'}
                    height={60}
                    style={{
                      marginLeft: 3,
                    }}
                  />
                );
              })}
            </div>
            <div>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e, f) => {
                return (
                  <div key={f} className="flex ">
                    {c.map((e, kf) => {
                      return (
                        <Skeleton
                          key={kf}
                          width={e.width ? e.width : 'auto'}
                          height={50}
                          style={{
                            marginLeft: 3,
                          }}
                        >
                          {' '}
                        </Skeleton>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>

      <StationWiseIssueModal />
      <div className="pt-3 ">
        {paymodeReport[0] ? (
          <div>
            <div className="  grid  grid-cols-2  md:grid-cols-4 ll:grid-cols-7 gap-2">
              <DashCollectionsButton
                mode={'Total Collection'}
                payment={FormatNumber2(
                  paymodeReport[0]?.CASH_AMOUNT - paymodeReport[0]?.DISQ_AMT
                )}
                currency={currency}
                bg={'#a8cf45'}
                iconBg={'#bbe06c'}
              />
              <DashCollectionsButton
                mode={'Cash'}
                payment={FormatNumber2(paymodeReport[0]?.CASH_AMOUNT)}
                currency={currency}
                bg={'#a8cf45'}
                iconBg={'#bbe06c'}
              />

              <DashCollectionsButton
                mode={'Card'}
                payment={FormatNumber2(paymodeReport[0]?.CARD_AMOUNT)}
                currency={currency}
                bg={'#a8cf45'}
                iconBg={'#bbe06c'}
              />

              <DashCollectionsButton
                mode={'Cheque'}
                payment={FormatNumber2(paymodeReport[0]?.CHEQUE_AMOUNT)}
                currency={currency}
                bg={'#a8cf45'}
                iconBg={'#bbe06c'}
              />

              <DashCollectionsButton
                mode={'ePayment'}
                payment={FormatNumber2(paymodeReport[0]?.EPAYMENT)}
                currency={currency}
                bg={'#a8cf45'}
                iconBg={'#bbe06c'}
              />

              <DashCollectionsButton
                mode={'BTC'}
                payment={FormatNumber2(paymodeReport[0]?.BTC_AMOUNT)}
                currency={currency}
                bg={'#a8cf45'}
                iconBg={'#bbe06c'}
              />

              <DashCollectionsButton
                mode={'Discount'}
                payment={FormatNumber2(paymodeReport[0]?.DISQ_AMT)}
                currency={currency}
                bg={'#e97a7a'}
                iconBg={'#eb8d8d'}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="  grid  grid-cols-2  md:grid-cols-4 ll:grid-cols-7 gap-2">
              {[1, 2, 3, 4, 5, 6, 7].map((e, k) => {
                return (
                  <Skeleton
                    key={k}
                    variant="rectangular"
                    animation="wave"
                    height={58}
                    sx={{
                      fontSize: '1rem',
                      borderRadius: '7px',
                      marginRight: '6px',
                      paddingX: '12px',
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminDashboard;
