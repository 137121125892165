import axios from 'axios';
import moment from 'moment';
import {
  setAlertWithTitle,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import { LoadOrderDetials } from './loadOrder';

export const SaveOrderBooking = async (
  data,
  token,
  dispatch,
  undo,
  setOrderDetails,
  prefix,
  date,
  SID
) => {
  dispatch(setSpinnerLoading('Saving..'));
  axios
    .post('/api/orderBooking/saveOrder', data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        undo();
        LoadOrderDetials(setOrderDetails, prefix, token, date, SID);
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};
