import { setAlertWithTitle, setSpinnerLoading } from '../Redux/modal';
import axios from 'axios';

const SendOtp = (MobileNumber, randomNumber, dispatch) => {
  dispatch(setSpinnerLoading('Sending OTP'));
  axios
    .post(
      `/api/cpusers/sendOTP`,

      {
        user_mobile: MobileNumber,
        OTP: randomNumber,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          // Authorization: `Bearer ${hmacString}`,
        },
      }
    )
    .then(async function (response) {
      if (response.status === 200) {
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Something went wrong',
          })
        );
      }
    })
    .catch(function (error) {
      console.log(error);

      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Something went wrong',
        })
      );
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export default SendOtp;
