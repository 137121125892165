import React from 'react';
import {
  setSessionExpired,
  setSpinnerLoading,
} from '../../../utility/Redux/modal';
import axios from 'axios';

const GetItemList = (prefix, sname, token, setlist, setAllData, dispatch) => {
  dispatch(setSpinnerLoading('Loading item list'));
  axios
    .post(
      '/api/issueMasterRoute/listItemMaster',
      {
        table_prefix: prefix,
        sname: sname,
      },

      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then((p) => {
      setlist(p.data.response);
      if (sname === '') {
        setAllData(p.data.response);
      }
    })
    .catch((error) => {
      console.log(error);

      if (error.response.data.name === 'TokenExpiredError') {
        dispatch(setSessionExpired(true));
      }
    })
    .finally(() => {
      dispatch(setSpinnerLoading(false));
    });
};

export default GetItemList;
