import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectToken } from '../../../utility/Redux/security';
import moment from 'moment';
import { selectCompanyAddress } from '../../Redux/profile';
import { setSessionExpired } from '../../Redux/modal';
import { Switch } from '@mui/material';

const width = window.innerWidth / 100;
const UserStatus = () => {
  const dispatch = useDispatch();
  const [userStatusList, setUsersStatusList] = useState([]);
  const [totalActiveUser, setTotalActiveUser] = useState(0);
  const [adminChecked, setAdminChecked] = useState(true);
  const [usersLength, setUsersLength] = useState(0);
  const columns = React.useMemo(
    () => [
      {
        field: 'USER_EMAIL',
        type: 'string',
        width: width * 20,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Email'}</strong>,
      },
      {
        field: 'USER_NAME',
        type: 'number',
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <strong>{'Username'}</strong>,
      },
      {
        field: 'STATION_ID',
        type: 'string',
        width: width * 6,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Station Id'}</strong>,
      },
      {
        field: 'STATION_NAME',
        type: 'string',
        width: width * 10,
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Station Name'}</strong>,
      },
      {
        field: 'USER_STATUS',
        type: 'string',
        width: width * 15,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
          <div className="flex pr-4">
            <Switch
              checked={params.value === 'ACTIVE'}
              color={params.value === 'ACTIVE' ? 'success' : 'error'}
              size={'small'}
              onChange={() => {
                if (params.value === 'ACTIVE') {
                  TurnOffUser(params.row.USER_EMAIL);
                } else {
                  if (params.value === 'DEACTIVE') {
                    TurnOnUser(params.row.USER_EMAIL);
                  }
                }
              }}
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: params.value === 'ACTIVE' ? 'green' : 'red',
                },
              }}
            />
            <div
              style={{
                fontSize: 13,
              }}
              className=" flex items-center pl-2 "
            >
              {' '}
              {params.value === 'ACTIVE'
                ? 'User is Activated'
                : 'User is Deactivated'}{' '}
            </div>
          </div>
        ),
        renderHeader: (params) => <strong>{'User Status'}</strong>,
      },
    ],
    []
  );
  const columnsMobile = React.useMemo(
    () => [
      {
        field: 'USER_NAME',
        type: 'number',
        flex: 1,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => <strong>{'Username'}</strong>,
      },

      {
        field: 'USER_STATUS',
        type: 'string',
        flex: 2,
        headerAlign: 'center',
        align: 'center',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => (
          <div className="flex pr-4">
            <Switch
              checked={params.value === 'ACTIVE'}
              color={params.value === 'ACTIVE' ? 'success' : 'error'}
              size={'small'}
              onChange={() => {
                if (params.value === 'ACTIVE') {
                  TurnOffUser(params.row.USER_EMAIL);
                } else {
                  if (params.value === 'DEACTIVE') {
                    TurnOnUser(params.row.USER_EMAIL);
                  }
                }
              }}
              sx={{
                '& .MuiSwitch-thumb': {
                  backgroundColor: params.value === 'ACTIVE' ? 'green' : 'red',
                },
              }}
            />
            <div
              style={{
                fontSize: 13,
              }}
              className=" flex items-center pl-2 "
            >
              {' '}
              {params.value === 'ACTIVE'
                ? 'User is Activated'
                : 'User is Deactivated'}{' '}
            </div>
          </div>
        ),
        renderHeader: (params) => <strong>{'User Status'}</strong>,
      },
    ],
    []
  );
  const token = useSelector(selectToken);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const TurnOffUser = (email) => {
    axios
      .post(
        `/api/dashBoardManager/turnOffUser`,
        {
          exp_date: moment().format('YYYY-MM-DD'),
          user_email: email,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          showUserStatus();
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };

  const TurnOnUser = (email) => {
    axios
      .post(
        `/api/dashBoardManager/turnOnUser`,
        {
          exp_date: moment().format('YYYY-MM-DD'),
          user_email: email,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          showUserStatus();
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      });
  };
  const companyDetails = useSelector(selectCompanyAddress);
  const showUserStatus = () => {
    axios
      .post(
        `/api/users/showUserStatus`,
        {
          company_email: companyDetails.email,
          exp_date: moment().format('YYYY-MM-DD'),
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          setUsersStatusList(res.data.response);
          setUsersLength(res.data.response.length);
        }
      })
      .catch((e) => {
        console.log(e);

        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
      })
      .finally(() => {
        axios
          .post(
            `/api/dashBoardManager/checkUserStatus`,
            {
              exp_date: moment().format('YYYY-MM-DD'),
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (!res.data.error) {
              setTotalActiveUser(res.data.response[0].ACTIVE_USERS);
              if (res.data.response[0].ACTIVE_USERS > 0) {
                setAdminChecked(true);
              } else {
                setAdminChecked(false);
              }
            }
          })
          .catch((e) => {
            console.log(e);

            if (e.response.data.name === 'TokenExpiredError') {
              dispatch(setSessionExpired(true));
            }
          });
      });
  };

  useEffect(() => {
    showUserStatus();
  }, []);

  return (
    <div className="h-full w-full  p-4">
      <div
        style={{ fontSize: 13 }}
        className="flex justify-end w-full pb-3 items-center"
      >
        {totalActiveUser - 3 >= usersLength
          ? 'All Users are Activated'
          : totalActiveUser > 0
          ? `${totalActiveUser} Users are Activated`
          : 'ALL Users are Deactive'}
        <Switch
          checked={adminChecked}
          color={adminChecked ? 'green' : 'error'}
          size={'small'}
          onChange={() => {
            console.log(adminChecked);
            if (adminChecked) {
              const email = '';
              TurnOffUser(email);
            } else {
              const email = '';
              TurnOnUser(email);
            }
          }}
          sx={{
            '& .MuiSwitch-thumb': {
              backgroundColor: adminChecked ? 'green' : 'red',
            },
          }}
        />
      </div>
      <div style={{ height: '90%' }}>
        <DataGrid
          hideFooterPagination
          hideFooter
          headerHeight={38}
          scrollbarSize={0}
          sx={{
            backgroundColor: 'white',

            fontSize: 13,
            '& .super-app-theme--header': {
              backgroundColor: '#E6E6E3',
            },
          }}
          rowHeight={33}
          columns={window.innerWidth < 770 ? columnsMobile : columns}
          rows={userStatusList}
          pageSize={100}
          rowsPerPageOptions={[100]}
          experimentalFeatures={{ newEditingApi: true }}
          showCellRightBorder={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          disableColumnMenu={true}
        />
      </div>
    </div>
  );
};

export default UserStatus;
