import { Close } from '@mui/icons-material';

import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  BackButton,
  SaveButton,
} from '../../../../../utility/component/buttons';
import AlertModal from '../../../../../utility/component/modals/alert';
import { FormatNumber2 } from '../../../../../utility/component/tools';
import {
  setAlertMsg,
  setALertStatus,
  setAlertWithTitle,
  setBillSattalmentModalStatus,
  setConfirmSaveBillstatus,
  setSessionExpired,
} from '../../../../../utility/Redux/modal';

import { selectToken } from '../../../../../utility/Redux/security';
import Select from '@mui/material/Select';
import {
  SelectBillingDiscount,
  SelectBillSettlementBalance,
  SelectBillSettlementDetails,
  SelectOrdersList,
  setBillSettlementBalnce,
  setBillSettlementDetails,
} from '../../../../../utility/Redux/tableOrder';

import SaveALertBillSettlement from '../../../../../utility/component/modals/confirmSaveBill';

import {
  selectAccountDetails,
  selectPrefix,
} from '../../../../../utility/Redux/profile';
import axios from 'axios';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
} from '@mui/material';
import { useFocus } from '../../../../../utility/hooks/hooks';

const width = window.innerWidth / 100;
const modWidth = width * 90;
const cardWidth = modWidth - 32;
const SettleBillMoblie = () => {
  const billingItemsList = useSelector(SelectOrdersList);
  const selectDiscountedAmount = useSelector(SelectBillingDiscount);
  const accDetails = useSelector(selectAccountDetails);
  const BillSettelmentBalance = useSelector(SelectBillSettlementBalance);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const [prevBal, setPrevBal] = useState();
  const prefix = useSelector(selectPrefix);
  const [collectionPaymode, setCollectionPaymode] = useState('CASH');

  const totalAmount = Math.round(
    billingItemsList?.reduce((acc, curr) => acc + curr.AMOUNT, 0) -
      selectDiscountedAmount
  );

  const billSettelmentDetails = useSelector(SelectBillSettlementDetails);

  const getPrevBalance = () => {
    axios
      .post(
        '/api/reports/partyBalance',
        {
          table_prefix: prefix,
          ACCOUNT_NO: accDetails.accNumber,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        if (!res.data.error) {
          const list = res.data.response;
          const prevBal =
            list[0].OP_DEBIT +
            list[0].DEBIT -
            (list[0].OP_CREDIT + list[0].CREDIT);

          setPrevBal(prevBal);
        }
        // const prevBal = res.data.response[0].BTC_AMOUNT - res.data.response[0].AMOUNT_RECEIVED
        // setPrevBal(prevBal)
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  useEffect(() => {
    if (
      Number(totalAmount) -
        (Number(billSettelmentDetails.cash) +
          Number(billSettelmentDetails.card) +
          Number(billSettelmentDetails.btc) +
          Number(billSettelmentDetails.coupanAmount) +
          Number(billSettelmentDetails.epaymentAmount)) >=
      0
    ) {
      dispatch(
        setBillSettlementBalnce(
          Number(totalAmount) -
            (Number(billSettelmentDetails.cash) +
              Number(billSettelmentDetails.card) +
              Number(billSettelmentDetails.btc) +
              Number(billSettelmentDetails.coupanAmount) +
              Number(billSettelmentDetails.epaymentAmount))
        )
      );
    }
  }, [billSettelmentDetails]);

  const confirmSave = () => {
    if (BillSettelmentBalance === 0) {
      if (
        (billSettelmentDetails.card && !billSettelmentDetails.cardNumber) ||
        (billSettelmentDetails.coupanAmount &&
          !billSettelmentDetails.coupanNumber) ||
        (billSettelmentDetails.epaymentAmount &&
          !billSettelmentDetails.epayment)
      ) {
        dispatch(
          setAlertMsg(
            'Please ensure that you have filled in both the payment amount and payment method fields to complete your transaction'
          )
        );
        dispatch(setALertStatus(true));
      } else {
        if (
          (!billSettelmentDetails.card && billSettelmentDetails.cardNumber) ||
          (!billSettelmentDetails.coupanAmount &&
            billSettelmentDetails.coupanNumber) ||
          (!billSettelmentDetails.epaymentAmount &&
            billSettelmentDetails.epayment)
        ) {
          dispatch(
            setAlertMsg(
              'Please fill in both fields of payment to complete your transaction.'
            )
          );
          dispatch(setALertStatus(true));
        } else {
          if (BillSettelmentBalance === 0) {
            dispatch(setConfirmSaveBillstatus(true));
          }
        }
      }
    } else {
      dispatch(setAlertMsg('Your bill settlement has not been cleared.'));
      dispatch(setALertStatus('true'));
    }
  };

  useEffect(() => {
    getPrevBalance();
  }, []);

  const BackHandle = () => {
    dispatch(
      setBillSettlementDetails({
        cash: '',
        card: '',
        cardNumber: '',
        coupanAmount: '',
        coupanNumber: '',
        epayment: '',
        epaymentAmount: '',
        btc: '',
        btcName: '',
        disc: '',
        btcMobile: '',
        btcAccNumber: '0',
        btcAddress: ' ',
        TOTAL_COLLECTION: 0,
      })
    );
    dispatch(setBillSattalmentModalStatus(false));
  };

  return (
    <div
      style={{
        width: modWidth,
      }}
      className=" text-psm flex justify-center"
    >
      <div
        style={{
          width: cardWidth,
        }}
        className="my-4"
      >
        <div
          style={{
            border: '1px solid black',
            width: cardWidth,
          }}
          className=" flex rounded-sm  "
        >
          <div
            style={{
              borderRight: '1px dotted gray',
              width: cardWidth / 2,
            }}
            className=" text-[#f44336] font-semibold pl-2 pt-2   pb-2"
          >
            Previous Balance
          </div>
          <div
            style={{
              width: cardWidth / 2,
            }}
            className="text-[#f44336] font-semibold flex justify-end  p-2"
          >
            {FormatNumber2(prevBal)}
          </div>{' '}
        </div>

        <div
          style={{
            border: '1px solid black',
          }}
          className=" flex rounded-sm  mt-3"
        >
          <div
            style={{
              borderRight: '1px dotted gray',
              width: cardWidth / 2,
            }}
            className=" text-[#f44336] font-semibold p-2 "
          >
            Bill Amount
          </div>
          <div
            style={{
              width: cardWidth / 2,
            }}
            className="text-[#f44336] font-semibold flex justify-end  p-2"
          >
            {' '}
            {FormatNumber2(totalAmount)}
          </div>
        </div>

        <div
          style={{
            border: '1px solid black',
            flex: 1,
          }}
          className=" mt-3 flex bg-[#ecf7c5] rounded-sm  "
        >
          <div
            style={{
              borderRight: '1px solid gray',
              width: cardWidth / 2,
            }}
            className=" text-[#0e2e3b]  font-semibold p-2"
          >
            Balance
          </div>
          <div
            style={{
              width: cardWidth / 2,
            }}
            className="text-[#073308] flex justify-end font-semibold p-2"
          >
            {' '}
            {FormatNumber2(BillSettelmentBalance)}
          </div>
        </div>

        <div className="mt-3">
          <TextField
            id="outlined-controlled"
            sx={{
              width: '100%',
              fontSize: '12px',
            }}
            type="number"
            size="small"
            label="Cash Amount"
            onChange={(t) => {
              dispatch(
                setBillSettlementDetails({
                  ...billSettelmentDetails,
                  cash: t.target.value,
                })
              );

              if (
                Number(totalAmount) -
                  (Number(t.target.value) +
                    Number(billSettelmentDetails.card) +
                    Number(billSettelmentDetails.btc) +
                    Number(billSettelmentDetails.coupanAmount) +
                    Number(billSettelmentDetails.epaymentAmount)) <
                0
              ) {
                dispatch(setAlertMsg('Invaild amount'));
                dispatch(setALertStatus(true));
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    cash: '',
                  })
                );
              }
            }}
            value={billSettelmentDetails.cash}
            InputProps={{
              sx: {
                fontSize: '14px',

                // Adjust the font size as per your preference
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black', // Remove the default outline color
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'green', // Change to your desired color on hover
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  border: '0.1px solid #03a9f4', // Change to your desired color on focus
                },
              },
            }}
            InputLabelProps={{
              sx: {
                fontSize: '14px',
                // Adjust the font size as per your preference
              },
            }}
          />
        </div>

        <div className="w-full flex mt-3 items-center">
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: 'green',
            }}
            size="small"
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                backgroundColor: 'white',
              }}
              size="small"
            >
              Epayment Type
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={billSettelmentDetails.epayment}
              label="Epayment Type"
              inputProps={{
                sx: {
                  border: '1px solid black',
                  '& .MuiOutlinedInput-sizeSmall': '10px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0px solid white', // Change to your desired color on focus
                  },
                },
              }}
              onChange={(t) =>
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    epayment: t.target.value,
                  })
                )
              }
            >
              <MenuItem value={'GPay'}>GPay</MenuItem>
              <MenuItem value={'Paytm'}>Paytm</MenuItem>
              <MenuItem value={'PhonePe'}>PhonePe</MenuItem>
              <MenuItem value={'Other UPI'}>Other UPI</MenuItem>
            </Select>
          </FormControl>

          <div
            style={{
              width: '100%',
            }}
            className="ml-3 "
          >
            <TextField
              id="outlined-controlled"
              type="number"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              size="small"
              label="Epayment Amount"
              onChange={(t) => {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    epaymentAmount: t.target.value,
                  })
                );
                if (
                  Number(totalAmount) -
                    (Number(t.target.value) +
                      Number(billSettelmentDetails.card) +
                      Number(billSettelmentDetails.btc) +
                      Number(billSettelmentDetails.cash) +
                      Number(billSettelmentDetails.coupanAmount)) <
                  0
                ) {
                  dispatch(setAlertMsg('Invaild amount'));
                  dispatch(setALertStatus(true));
                  dispatch(
                    setBillSettlementDetails({
                      ...billSettelmentDetails,
                      epaymentAmount: '',
                    })
                  );
                }
              }}
              value={billSettelmentDetails.epaymentAmount}
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>
          {billSettelmentDetails.epayment ? (
            <Close
              onClick={() => {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    epayment: '',
                  })
                );
              }}
              style={{
                color: '#f5874f',
                fontSize: 20,
              }}
            />
          ) : null}
        </div>

        <div className="w-full flex mt-3">
          <div>
            <TextField
              id="outlined-controlled"
              type="number"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              size="small"
              label="Card Amount"
              onChange={(t) => {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    card: t.target.value,
                  })
                );
                if (
                  Number(totalAmount) -
                    (Number(t.target.value) +
                      Number(billSettelmentDetails.cash) +
                      Number(billSettelmentDetails.btc) +
                      Number(billSettelmentDetails.coupanAmount) +
                      Number(billSettelmentDetails.epaymentAmount)) <
                  0
                ) {
                  dispatch(setAlertMsg('Invaild amount'));
                  dispatch(setALertStatus(true));
                  dispatch(
                    setBillSettlementDetails({
                      ...billSettelmentDetails,
                      card: '',
                    })
                  );
                }
              }}
              value={billSettelmentDetails.card}
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>

          <div className="ml-3 ">
            <TextField
              id="outlined-controlled"
              type="number"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              size="small"
              label="Card No."
              onChange={(t) =>
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    cardNumber: t.target.value,
                  })
                )
              }
              value={billSettelmentDetails.cardNumber}
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>
        </div>

        <div className="w-full flex mt-3">
          <div>
            <TextField
              id="outlined-controlled"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              size="small"
              label="BTC Amount"
              type="number"
              onChange={(t) => {
                if (t.target.value) {
                  dispatch(
                    setBillSettlementDetails({
                      ...billSettelmentDetails,
                      btcName: accDetails.accName,
                      btc: t.target.value,
                    })
                  );
                } else {
                  dispatch(
                    setBillSettlementDetails({
                      ...billSettelmentDetails,
                      btcName: '',
                      btc: t.target.value,
                    })
                  );
                }

                if (
                  Number(totalAmount) -
                    (Number(t.target.value) +
                      Number(billSettelmentDetails.card) +
                      Number(billSettelmentDetails.coupanAmount) +
                      Number(billSettelmentDetails.cash) +
                      Number(billSettelmentDetails.epaymentAmount)) <
                  0
                ) {
                  dispatch(setAlertMsg('Invaild amount'));
                  dispatch(setALertStatus(true));
                  dispatch(
                    setBillSettlementDetails({
                      ...billSettelmentDetails,
                      btc: '',
                    })
                  );
                }
              }}
              value={billSettelmentDetails.btc}
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>

          <div className="ml-3 ">
            <TextField
              id="outlined-controlled"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              size="small"
              label="BTC Name"
              readOnly
              value={billSettelmentDetails.btcName}
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>
        </div>

        <div className="w-full flex mt-3">
          <div className="flex-1">
            <TextField
              id="outlined-controlled"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              type="text"
              size="small"
              label="Remarks"
              onChange={(t) => {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    disc: t.target.value,
                  })
                );
              }}
              value={billSettelmentDetails.disc}
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>
        </div>

        <div className="w-full flex mt-3 items-center">
          <div
            style={{
              width: '100%',
            }}
          >
            <TextField
              id="outlined-controlled"
              type="number"
              sx={{
                width: '100%',
                fontSize: '12px',
              }}
              size="small"
              label="Collection"
              onChange={(t) => {
                if (prevBal && prevBal > 0) {
                  if (t.target.value <= prevBal) {
                    dispatch(
                      setBillSettlementDetails({
                        ...billSettelmentDetails,
                        TOTAL_COLLECTION:
                          billSettelmentDetails.TOTAL_COLLECTION === 0
                            ? t.target.value.replace(0, '')
                            : t.target.value,
                      })
                    );
                  } else {
                    dispatch(
                      setAlertWithTitle({
                        title: 'Alert',
                        msg: 'Invalid amount',
                      })
                    );
                  }
                }
              }}
              value={
                billSettelmentDetails.TOTAL_COLLECTION === 0
                  ? ''
                  : billSettelmentDetails.TOTAL_COLLECTION
              }
              InputProps={{
                sx: {
                  fontSize: '14px',

                  // Adjust the font size as per your preference
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'black', // Remove the default outline color
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'green', // Change to your desired color on hover
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0.1px solid #03a9f4', // Change to your desired color on focus
                  },
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: '14px',
                  // Adjust the font size as per your preference
                },
              }}
            />
          </div>
          <FormControl
            sx={{
              width: '100%',
              backgroundColor: 'green',
              marginLeft: '12px',
            }}
            size="small"
          >
            <InputLabel
              sx={{
                fontSize: '14px',
                backgroundColor: 'white',
              }}
              size="small"
            >
              Pay Mode
            </InputLabel>
            <Select
              size="small"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={collectionPaymode}
              label="Epayment Type"
              inputProps={{
                sx: {
                  border: '1px solid black',
                  '& .MuiOutlinedInput-sizeSmall': '10px',
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    border: '0px solid white', // Change to your desired color on focus
                  },
                },
              }}
              onChange={(e) => {
                setCollectionPaymode(e.target.value);
              }}
            >
              <MenuItem value={'CASH'}>CASH</MenuItem>
              <MenuItem value={'CARD'}>CARD</MenuItem>
              <MenuItem value={'CHEQUE'}>CHEQUE</MenuItem>
              <MenuItem value={'EPAYMENT'}>EPAYMENT</MenuItem>
            </Select>
          </FormControl>
          {billSettelmentDetails.epayment ? (
            <Close
              onClick={() => {
                dispatch(
                  setBillSettlementDetails({
                    ...billSettelmentDetails,
                    epayment: '',
                  })
                );
              }}
              style={{
                color: '#f5874f',
                fontSize: 20,
              }}
            />
          ) : null}
        </div>

        <div className="flex grow  mt-2">
          <Button
            // ref={updateRef}
            onClick={confirmSave}
            variant="contained"
            sx={{
              textTransform: 'none',
              letterSpacing: '1px',
              backgroundColor: '#a8cf45',
              width: '60%',
            }}
          >
            <div className="text-black px-8 font-sans w-1/2">Save </div>
          </Button>

          <Button
            // ref={updateRef}
            onClick={BackHandle}
            variant="contained"
            sx={{
              textTransform: 'none',
              letterSpacing: '1px',
              backgroundColor: '#fec24a',
              border: '1px dotted gray',
              marginLeft: '4px',
              width: '40%',
            }}
          >
            <div className="text-black hover:text-white font-sans  flex justify-center w-full whitespace-nowrap px-2 ">
              <i
                style={{
                  fontSize: '14px',
                }}
                className="bi bi-reply"
              ></i>
              <div className="flex  items-center pl-1">Back </div>
            </div>
          </Button>
        </div>
      </div>
      <SaveALertBillSettlement collectionPaymode={collectionPaymode} />
    </div>
  );
};

export default SettleBillMoblie;
