import { DataGrid, GridPagination } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCollectionAccountDetails,
  setAlertWithTitle,
  setCollectionAccountDetails,
  setListStationMasterModalStatus,
  setModPartyMasterHelpStatus,
  setSessionExpired,
} from '../../utility/Redux/modal';

import {
  selectDefultProfileSetting,
  selectPrefix,
  selectreportFromDate,
  selectreportToDate,
  selectStationId,
  selectStationName,
  selectUserType,
  setreportsFromDate,
  setreportsTodate,
  setStationID,
  setStationName,
} from '../../utility/Redux/profile';

import { FormatNumber2 } from '../../utility/component/tools';
import { PartyLedgerFooter } from '../../utility/component/datagrid/partyFooter';
import { selectToken } from '../../utility/Redux/security';
import ModListStationMaster from '../../utility/component/modals/modListStationMaster';
import { GeniusHeader } from '../../utility/component/datagrid/geniusHeader';
import { useRef } from 'react';
import ReactToPrint from 'react-to-print';

import TableGenius from '../../utility/component/geniusComponents/table';
import { FromToDate } from '../../utility/component/fromToDate';
import { PrintButton } from '../../utility/component/buttons';
import { setReportType } from '../../utility/Redux/refresh';
import GeniusSelectDate from '../../utility/component/selectDate';
import ModPartyMasterHelp from '../../master/partyMasterHelp';
import GeniusTableForReports from '../../utility/component/geniusComponents/geniusTableforReports';
import moment from 'moment';
import dataToLocal from '../../utility/functions/summaryToLocal';
import IsLocalData from '../../utility/functions/ceckLocalData';
import deleteWholeDatabase from '../../utility/functions/deleteWholeDatabase';
const width = window.innerWidth / 100;
const PartyLedger = () => {
  const dispatch = useDispatch();
  const printRef = useRef();
  const ReduxStation_id = useSelector(selectStationId);
  const accDetails = useSelector(selectCollectionAccountDetails);
  const { accNumber } = accDetails;
  const firstDate = useSelector(selectreportFromDate);
  const SecondDate = useSelector(selectreportToDate);
  const userType = useSelector(selectUserType);
  const defaultProfile = useSelector(selectDefultProfileSetting);
  const [list, setList] = useState([]);

  const [fromDate, setFromDate] = useState(firstDate);
  const [fromDateFocus, setFromDateFocus] = useState(false);
  const [toDate, setToDate] = useState(SecondDate);
  const [toDateFocus, setToDateFocus] = useState(false);
  const ReduxStationName = useSelector(selectStationName);

  const columns = React.useMemo(
    () => [
      {
        field: 'VOUCHER_NO',
        accessor: 'VOUCHER_NO',
        // header:'V No.',
        type: 'string',

        cellClassName: 'super-app-theme--cell',
        width: width * 4,
        headerAlign: 'center',
        renderCell: (params) => {
          if (params.value) {
            return <div>{params.value}</div>;
          } else {
            return <div>{''}</div>;
          }
        },

        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'V No.'}
          </strong>
        ),
      },

      {
        field: 'DATE',
        accessor: 'DATE',
        // header:'Date',
        type: 'string',
        cellClassName: 'super-app-theme--cell',
        width: width * 8,
        headerAlign: 'center',
        renderCell: (params) => <div> {params.value} </div>,
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Date'}
          </strong>
        ),
      },

      {
        field: 'ACCOUNT_NO',
        type: 'number',
        accessor: 'ACCOUNT_NO',
        // header:'Date',
        width: width * 5,
        cellClassName: 'super-app-theme--cell',
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Acc No.'}
          </strong>
        ),
      },
      {
        field: 'NAME',
        type: 'number',
        accessor: 'NAME',
        // header:'Date',
        cellClassName: 'super-app-theme--cell',
        width: width * 15,
        headerAlign: 'center',
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Party Name'}
          </strong>
        ),
      },

      {
        field: 'DESC_NAME',
        type: 'string',

        flex: 1,
        accessor: 'DESC_NAME',
        cellClassName: 'super-app-theme--cell',
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Description'}
          </strong>
        ),
      },

      {
        field: 'openingBal',
        type: 'string',
        accessor: 'openingBal',

        align: 'right',
        width: width * 8,
        headerAlign: 'center',
        cellClassName: 'super-app-theme--cell',
        renderCell: (params) => {
          if (params.value) {
            return <div> {FormatNumber2(params.value)}</div>;
          } else {
            return <div> {''} </div>;
          }
        },

        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Op.Bal'}
          </strong>
        ),
      },

      {
        field: 'BTC_AMOUNT',
        type: 'string',
        align: 'right',
        accessor: 'BTC_AMOUNT',

        width: width * 6,
        headerAlign: 'center',
        cellClassName: 'super-app-theme--cell',

        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'BTC Amt.'}
          </strong>
        ),
      },

      {
        field: 'AMOUNT_RECEIVED',
        type: 'string',
        accessor: 'AMOUNT_RECEIVED',
        align: 'right',

        width: width * 8,
        headerAlign: 'center',

        cellClassName: 'super-app-theme--cell',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Received'}
          </strong>
        ),
      },
      {
        field: 'closingBal',
        type: 'string',
        align: 'right',
        accessor: 'closingBal',
        width: width * 9,
        renderCell: (params) => {
          if (params.value) {
            return <div> {FormatNumber2(params.value)} </div>;
          } else {
            return <div> {''} </div>;
          }
        },
        headerAlign: 'center',
        cellClassName: 'super-app-theme--cell',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Closing Bal'}
          </strong>
        ),
      },

      {
        field: 'APPROVED',
        type: 'string',
        accessor: 'APPROVED',
        align: 'center',
        width: width * 6,
        headerAlign: 'center',
        renderCell: (params) => {
          return <div>{params.value === 'YES' ? 'YES' : 'NO'}</div>;
        },
        headerClassName: 'super-app-theme--header',
        cellClassName: 'super-app-theme--cell',
        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Approved'}
          </strong>
        ),
      },
    ],
    []
  );
  const columnsMobile = React.useMemo(
    () => [
      {
        field: 'DATE',
        accessor: 'DATE',
        // header:'Date',
        type: 'string',
        cellClassName: 'super-app-theme--cell',
        width: width * 18,
        headerAlign: 'center',
        renderCell: (params) => {
          if (params.value) {
            return <div>{params.value}</div>;
          } else {
            return <div>{''}</div>;
          }
        },
        align: 'left',
        headerClassName: 'super-app-theme--header',

        renderHeader: (params) => (
          <strong className="text-[#555a66]" style={{ fontSize: 13 }}>
            {'Date'}
          </strong>
        ),
      },

      {
        field: 'openingBal',
        type: 'number',
        width: 60,
        align: 'right',
        headerAlign: 'center',
        renderCell: (params) => {
          if (params.value) {
            return <div> {FormatNumber2(params.value)} </div>;
          } else {
            return '';
          }
        },
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Op.Bal.'}</strong>,
      },

      {
        field: 'BTC_AMOUNT',
        type: 'number',
        width: 60,

        headerAlign: 'center',
        align: 'right',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          if (params.value) {
            return <div> {FormatNumber2(params.value)} </div>;
          } else {
            return '';
          }
        },
        renderHeader: (params) => <strong>{'BTC Amt.'}</strong>,
      },

      {
        field: 'AMOUNT_RECEIVED',
        type: 'number',
        width: 60,

        headerAlign: 'center',
        align: 'right',
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => {
          if (params.value) {
            return (
              <div className="text-[#023606]">
                {' '}
                {FormatNumber2(params.value)}{' '}
              </div>
            );
          } else {
            return '';
          }
        },
        renderHeader: (params) => <strong>{'Rcvd.'}</strong>,
      },

      {
        field: 'APPROVED',
        type: 'string',
        flex: 1,
        align: 'right',
        renderCell: (params) => {
          if (params.value) {
            return <div> {params.value} </div>;
          } else {
            return '';
          }
        },
        headerAlign: 'center',
        headerClassName: 'super-app-theme--header',
        renderHeader: (params) => <strong>{'Approved'}</strong>,
      },
    ],
    []
  );
  const token = useSelector(selectToken);
  const prefix = useSelector(selectPrefix);

  const station_id = useSelector(selectStationId);
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  const getPartyledger = () => {
    deleteWholeDatabase('party_ledger');
    if (accNumber) {
      axios
        .post(
          `/api/reports/partyLedger`,
          {
            table_prefix: prefix,
            from_date: window.innerWidth <= 500 ? fromDate : firstDate,
            to_date: window.innerWidth <= 500 ? toDate : SecondDate,
            ACCOUNT_NO: accNumber,
          },
          {
            headers: headers,
          }
        )
        .then((res) => {
          if (!res.data.error) {
            const data = res.data.response;
            console.log(data);
            let rowList = [];
            let opbal = 0;
            let closing = 0;
            for (let index = 0; index < data.length; index++) {
              if (data[index].DESC_NAME === 'Openng Balance') {
                const o =
                  data[index].OP_DEBIT -
                  data[index].OP_CREDIT +
                  data[index].PRV_DEBIT;
                const pr = data[index].PRV_CREDIT ? data[index].PRV_CREDIT : 0;
                opbal = o - pr;
                closing =
                  opbal + data[index].BTC_AMOUNT - data[index].AMOUNT_RECEIVED;

                if (opbal < 0) {
                  rowList.push({
                    ...data[index],
                    id: index + 1,
                    VOUCHER_NO:
                      data[index].VOUCHER_NO === 0 ||
                      data[index].VOUCHER_NO === '0'
                        ? null
                        : data[index].VOUCHER_NO,
                    BTC_AMOUNT: FormatNumber2(opbal),
                    AMOUNT_RECEIVED: FormatNumber2(data[index].AMOUNT_RECEIVED),
                    openingBal: opbal,
                    closingBal: closing,
                  });
                } else {
                  rowList.push({
                    ...data[index],
                    id: index + 1,
                    VOUCHER_NO:
                      data[index].VOUCHER_NO === 0 ||
                      data[index].VOUCHER_NO === '0'
                        ? null
                        : data[index].VOUCHER_NO,
                    BTC_AMOUNT: FormatNumber2(data[index].BTC_AMOUNT),
                    AMOUNT_RECEIVED: FormatNumber2(opbal),
                    openingBal: opbal,
                    closingBal: closing,
                  });
                }
                opbal = closing;
              } else {
                closing =
                  opbal + data[index].BTC_AMOUNT - data[index].AMOUNT_RECEIVED;

                rowList.push({
                  ...data[index],
                  id: index + 1,
                  VOUCHER_NO:
                    data[index].VOUCHER_NO === 0 ||
                    data[index].VOUCHER_NO === '0'
                      ? null
                      : data[index].VOUCHER_NO,
                  BTC_AMOUNT: FormatNumber2(data[index].BTC_AMOUNT),
                  AMOUNT_RECEIVED: FormatNumber2(data[index].AMOUNT_RECEIVED),
                  openingBal: opbal,
                  closingBal: closing,
                });
                opbal = closing;
              }
            }
            if (rowList[0]) {
              dataToLocal('party_ledger', rowList, [
                {
                  from_date: window.innerWidth <= 500 ? fromDate : firstDate,
                  to_date: window.innerWidth <= 500 ? toDate : SecondDate,
                  ACCOUNT_NO: accNumber,
                  accName: accDetails.accName,
                  accAddress: accDetails.accAddress,
                  accMobile: accDetails.accMobile,
                  ICODE: 1,
                },
              ]);
            }
            console.log(rowList);
            setList(rowList);
          }
        })
        .catch((e) => {
          console.log(e);

          if (e.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
        });
    } else {
      dispatch(
        setAlertWithTitle({
          title: 'Alert',
          msg: 'Please Select account first',
        })
      );
    }
  };

  useEffect(() => {
    const elseFunc = () => {
      if (userType === 'MANAGER') {
        dispatch(setStationID(''));
        dispatch(
          setCollectionAccountDetails({
            accName: '',
            accAddress: '',
            accMobile: '',
            accNumber: '',
            stationId: '',
          })
        );
        dispatch(setStationName(''));
      } else {
        dispatch(
          setCollectionAccountDetails({
            accName: '',
            accAddress: '',
            accMobile: '',
            accNumber: '',
            stationId: '',
          })
        );
      }
    };
    const callback = (details, data) => {
      setList(data);

      dispatch(
        setreportsFromDate(moment(details[0].from_date).format('YYYY-MM-DD'))
      );

      dispatch(
        setreportsTodate(moment(details[0].to_date).format('YYYY-MM-DD'))
      );

      dispatch(
        setCollectionAccountDetails({
          accName: details[0].accName,
          accAddress: details[0].accAddress,
          accMobile: details[0].accMobile,
          accNumber: details[0].ACCOUNT_NO,
          stationId: '',
        })
      );
    };
    IsLocalData('party_ledger', callback, elseFunc);

    dispatch(setReportType('partyLedger'));
  }, []);

  const okButton = () => {
    if (userType === 'USER' && accNumber) {
      getPartyledger();
    }
    if (userType === 'MANAGER') {
      if (accNumber) {
        getPartyledger();
      } else {
        dispatch(
          setAlertWithTitle({
            title: 'Alert',
            msg: 'Please select any party first.',
          })
        );
      }
    }
  };

  return (
    <div className="h-full  w-full flex flex-col  p-2 overflow-auto">
      {window.innerWidth <= 500 ? (
        <>
          <div
            className="flex items-center py-3 px-1"
            style={{
              border: '1px solid #b5b3b3',
            }}
          >
            <div className="px-1 font-semibold">From </div>

            <GeniusSelectDate
              date={fromDate}
              setDate={setFromDate}
              dateFocus={fromDateFocus}
              SetDateFocus={setFromDateFocus}
            />
            <div className="px-1 font-semibold">To</div>
            <GeniusSelectDate
              date={toDate}
              setDate={setToDate}
              dateFocus={toDateFocus}
              SetDateFocus={setToDateFocus}
            />
          </div>
          <div
            style={{
              border: '1px solid #b5b3b3',
              borderTop: '0px',
            }}
            className="flex items-center pr-2"
          >
            <div>
              <button
                className="flex items-center rounded-sm ml-2"
                onClick={okButton}
                style={{
                  backgroundColor: '#c1c1c1',
                  paddingLeft: 30,
                  paddingRight: 30,
                  paddingTop: 4,
                  paddingBottom: 4,
                  color: 'black',
                }}
              >
                <div className="flex items-center text-sm">Ok</div>
              </button>
            </div>
          </div>
          <div
            style={{
              border: '1px solid #b5b3b3',
              borderTop: '0px',
            }}
            className="flex w-full"
          >
            <div
              style={
                userType === 'MANAGER'
                  ? {
                      fontSize: 12,
                      width: '100%',
                      color: '#a6a2a2',
                    }
                  : {
                      fontSize: 12,
                      width: '100%',
                      color: '#a6a2a2',
                    }
              }
              className=" flex whitespace-normal  py-3 justify-start items-center  "
            >
              {/* party name 1st part */}
              <div className="flex  h-full items-center px-2 ">
                <div
                  className="flex hover:cursor-pointer h-full items-center w-1/2"
                  onClick={() => dispatch(setModPartyMasterHelpStatus(true))}
                >
                  <div>
                    <i
                      className="bi bi-search"
                      style={{ color: '#f44336', fontSize: 15 }}
                    ></i>
                  </div>

                  <span
                    style={{ fontSize: 12 }}
                    className=" text-[#5c5b5b]  pl-2 flex font-semibold items-center"
                  >
                    Party
                    <span className="pl-1 text-[#5c5b5b] print:hidden font-semibold ">
                      Name
                    </span>
                  </span>
                </div>

                <div
                  style={{
                    border: '1px dotted #a6a2a2 ',
                    borderRadius: '3px',
                  }}
                  className="flex  w-20  pl-2 ml-2 "
                >
                  <input
                    size={'small'}
                    placeholder="All"
                    value={accNumber ? accNumber : 'select'}
                    style={{
                      width: '100%',
                      fontSize: 12,
                      paddingTop: '2px',
                      paddingBottom: '2px',
                      backgroundColor: 'transparent',
                    }}
                    readOnly
                    className=" outline-0 w-30 "
                  />
                </div>

                <div
                  style={{
                    border: '1px dotted #a6a2a2',
                    borderRadius: '3px',
                  }}
                  className="flex w-full h-full ml-2"
                >
                  <div className="flex grow h-full ">
                    <div className="w-full h-full">
                      <div
                        className="w-full h-1/2 print:h-full flex items-center"
                        style={{
                          fontSize: 12,
                          color: 'red',
                        }}
                      >
                        <span className="pl-4 font-semibold ">
                          {accDetails.accName}
                        </span>
                      </div>
                      <div
                        style={{
                          borderTop: '1px dotted #a6a2a2',
                          color: '#f2666f',
                          fontSize: 12,
                        }}
                        className="h-1/2 py-1 flex items-center print:hidden"
                      >
                        <span className="pl-4 font-semibold  ">
                          {accDetails.accAddress + ' '}
                          {accDetails.accMobile}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <FromToDate
          submit={okButton}
          hideStation={true}
          otherButton={
            list[0] && (
              <div>
                <ReactToPrint
                  trigger={() => {
                    return (
                      <button
                        style={{
                          borderRadius: '7px',
                          paddingLeft: 30,
                          paddingRight: 30,
                          paddingTop: 4,
                          paddingBottom: 4,
                          backgroundColor: '#bad4f9',
                          color: 'black',
                          marginLeft: 7,
                        }}
                      >
                        <div className="text-sm">Print</div>
                      </button>
                    );
                  }}
                  content={() => printRef.current}
                />
              </div>
            )
          }
          reset={() => {
            if (userType === 'MANAGER') {
              dispatch(setStationID(''));
              dispatch(setStationName(''));
              dispatch(
                setCollectionAccountDetails({
                  accName: '',
                  accAddress: '',
                  accMobile: '',
                  accNumber: '',
                  stationId: '',
                })
              );
            } else {
              dispatch(
                setCollectionAccountDetails({
                  accName: '',
                  accAddress: '',
                  accMobile: '',
                  accNumber: '',
                  stationId: '',
                })
              );
            }
          }}
        />
      )}
      <div
        style={{
          height: `calc(100% - 100px)`,
        }}
        className="flex flex-col  pt-2 "
      >
        <DataGrid
          headerHeight={30}
          scrollbarSize={0}
          sx={
            window.innerWidth <= 425
              ? {
                  backgroundColor: 'white',

                  fontSize: 10,
                  '& .super-app-theme--header': {
                    backgroundColor: '#E6E6E3',
                  },
                  '& .super-app-theme--cell': {
                    '@media print': {
                      borderRight: '1px dotted black',
                      // borderBottom: '1px dotted #f0ebeb',
                    },
                  },
                }
              : window.innerWidth <= 768
              ? {
                  backgroundColor: 'white',

                  fontSize: 10,
                  '& .super-app-theme--header': {
                    backgroundColor: '#E6E6E3',
                  },
                  '& .super-app-theme--cell': {
                    '@media print': {
                      border: '1px dashed black',
                      // borderBottom: '1px dotted #f0ebeb',
                    },
                  },
                }
              : {
                  backgroundColor: 'white',

                  fontSize: 13,
                  '& .super-app-theme--header': {
                    backgroundColor: '#E6E6E3',
                    // display: 'none',
                  },
                  '& .super-app-theme--cell': {
                    '@media print': {
                      border: '1px dashed black',
                      // borderBottom: '1px dotted #f0ebeb',
                    },
                  },
                }
          }
          rowHeight={33}
          columns={window.innerWidth <= 500 ? columnsMobile : columns}
          rows={list}
          components={{
            Pagination: GridPagination,

            // Header: () => (
            //   <GeniusHeader
            //     columns={window.innerWidth <= 500 ? columnsMobile : columns}
            //   />
            // ),
            NoRowsOverlay: () => {
              return (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  No Records Found
                </div>
              );
            },
            // Header: () => {
            //   return <>{/* <GeniusHeader2 columns={columns} /> */}</>;
            // },

            // Footer: () => <PartyLedgerFooter rows={list} columns={columns} />,
          }}
          pageSize={16}
          rowsPerPageOptions={[100]}
          experimentalFeatures={{ newEditingApi: true }}
          showCellRightBorder={true}
          pagination={true}
          showColumnRightBorder={true}
          disableColumnSelector
          disableColumnReorder
          disableColumnMenu={true}
        />
        <PartyLedgerFooter
          rows={list}
          columns={window.innerWidth <= 500 ? columnsMobile : columns}
        />
      </div>
      <div
        ref={printRef}
        className="p-4   hidden print:flex justify-between  w-full h-full  "
      >
        <GeniusTableForReports
          stationId={ReduxStation_id}
          date={fromDate}
          date1={toDate}
          columns={columns}
          data={list}
          title={'Party Ledger'}
        />
      </div>
      <ModListStationMaster
        onRowClick={(p) => {
          dispatch(setStationID(p.row.CODE));
          dispatch(setStationName(p.row.STATION_NAME));
          dispatch(setListStationMasterModalStatus(false));
        }}
      />
      <ModPartyMasterHelp
        SID={station_id}
        onRowClick={(params) => {
          const name = params.row.NAME;
          const mobile = params.row.MOBILE;
          const accNumber = params.row.ACCOUNT_NO;
          const address = params.row.ADDRESS;
          const stationId = params.row.SID;
          const stationName = params.row.SNAME;
          dispatch(
            setCollectionAccountDetails({
              accName: name,
              accAddress: address,
              accMobile: mobile,
              accNumber: accNumber,
              stationId: stationId,
              sName: stationName,
            })
          );
          dispatch(setModPartyMasterHelpStatus(false));
        }}
        onClose={() => {
          dispatch(setModPartyMasterHelpStatus(false));
        }}
      />
    </div>
  );
};

export default PartyLedger;
