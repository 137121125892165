import React, { useEffect, useRef, useState } from 'react';
import GeniusDatagrid from '../datagrid/geniusDatagrid';
import {
  selectPrefix,
  selectStationId,
  selectStationName,
  selectWidth,
} from '../../Redux/profile';
import { selectToken } from '../../Redux/security';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectExpensePaymode,
  setALertStatus,
  setAlertMsg,
  setExpensePaymode,
  setExpenseVoucherModalStatus,
  setListStationMasterModalStatus,
  setSessionExpired,
  setTotalExpense,
} from '../../Redux/modal';
import axios from 'axios';
import moment from 'moment';
import {
  ExitButton,
  GeniusOfficeButton,
  SaveButton,
  UndoButton,
} from '../buttons';
import GeniustextInput from '../mastertext';
import { toProperCase } from '../format';
import { useNavigate } from 'react-router-dom';
import ModalHeaderStyle from '../styles/modalHeader';
import ModListStationMaster from './modListStationMaster';

const isProduction = process.env.NODE_ENV === 'production';

const ModalExpenseVoucher = () => {
  const headerRef = useRef();

  const navigate = useNavigate();
  const [SID, setSid] = useState(useSelector(selectStationId));

  const token = useSelector(selectToken);
  const [stationName, setStationName] = useState(
    useSelector(selectStationName)
  );
  const prefix = useSelector(selectPrefix);
  const dispatch = useDispatch();
  const [expenseList, setExpenseList] = useState([]);
  const paymode = useSelector(selectExpensePaymode);
  const [latitude, setLatitute] = useState('');
  const [longitude, setlongitude] = useState('');

  const [expenseName, setExpenseName] = useState('');
  const [expenseAmount, setExpenseAmount] = useState('');
  const [expenseDesc, setExpenseDesc] = useState('');
  const [code, setCode] = useState('');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };
  const [list, setList] = useState([]);

  const windowWidth = useSelector(selectWidth);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setLatitute(position.coords.latitude);
        setlongitude(position.coords.longitude);
      },
      (error) => {
        if (isProduction) {
          dispatch(
            setAlertMsg(
              'To continue using this app, please enable location services.'
            )
          );
          dispatch(setALertStatus(true));
          dispatch(setExpenseVoucherModalStatus(false));
        }

        console.error(error);
      }
    );
  }, []);

  const total = list.reduce((total, row) => total + row.AMOUNT_PAID, 0);
  const handleSave = () => {
    if (expenseName && expenseDesc && expenseAmount) {
      const data = {
        table_prefix: prefix,
        cart_item: [
          {
            DATE: moment().format('YYYY-MM-DD HH:mm:ss'),
            PAY_MODE: paymode,
            CARD_NO: '',
            VOUCHER_NO: '',
            BANK_NAME: '',
            EPAYMENT_BANK: '',
            ACCOUNT_NO: 0,
            NAME: '',
            EXPENCE_CODE: code,
            ADDRESS: '',
            MOBILE: '',
            AMOUNT_RECEIVED: 0,
            AMOUNT_PAID: expenseAmount,
            AMOUNT_DESC: expenseDesc,
            INVNO: '0',
            SID: SID,
            SNAME: stationName,
            EXPENCE_NAME: expenseName,
            LATITUDE: latitude ? latitude : '38.8951',
            LONGITUDE: longitude ? longitude : '-77.0364',
            APPROVED: 'NO',
          },
        ],
      };
      axios
        .post(`/api/voucherRoute/saveExpenceVoucher`, data, {
          headers: headers,
        })
        .then((res) => {
          if (!res.data.error) {
            dispatch(setAlertMsg('Expense Voucher Saved Successfully. '));
            dispatch(setALertStatus(true));
            setExpenseName('');
            setExpenseAmount('');
            setExpenseDesc('');
            setCode('');
            setSid('');
            setStationName('');
          } else {
            dispatch(setAlertMsg('Something wrong'));
            dispatch(setALertStatus(true));
          }
        })
        .catch((e) => {
          if (e.response.data.name === 'TokenExpiredError') {
            dispatch(setSessionExpired(true));
          }
          console.log(e);
        });
    } else {
      if (!expenseDesc) {
        dispatch(setAlertMsg('Fill Description Field.'));
        dispatch(setALertStatus(true));
      } else {
        if (!expenseAmount) {
          dispatch(setAlertMsg('Fill Amount Field.'));
          dispatch(setALertStatus(true));
        }
      }
    }
  };

  const columns = [
    {
      field: 'EXPENCE_NAME',
      type: 'string',
      width: 300,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Expense Name'}</div>
      ),
    },

    {
      field: 'AMOUNT_PAID',
      type: 'number',
      width: 150,
      headerAlign: 'center',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return <div className="pr-4"> {param.value}</div>;
        }
      },
      headerClassName: 'super-app-theme--header',
      align: 'center',
      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Amount'}</div>,
    },
  ];

  const columnsMobile = [
    {
      field: 'EXPENCE_NAME',
      type: 'string',
      width: 150,
      headerAlign: 'center',
      headerClassName: 'super-app-theme--header',
      renderHeader: (params) => (
        <div style={{ fontSize: 12 }}>{'Expense Name'}</div>
      ),
    },

    {
      field: 'AMOUNT_PAID',
      type: 'number',
      width: 150,
      headerAlign: 'center',
      renderCell: (param) => {
        if (param.value === 0) {
          return <div> {''}</div>;
        } else {
          return <div className="pr-4"> {param.value}</div>;
        }
      },
      headerClassName: 'super-app-theme--header',

      renderHeader: (params) => <div style={{ fontSize: 12 }}>{'Amount'}</div>,
    },
  ];

  const getListExpenseMaster = () => {
    axios
      .post(
        `/api/masters/listExpenceMaster`,
        {
          table_prefix: prefix,
        },
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (!res.data.error) {
          let rowList = [];
          const data = res.data.response;

          for (let index = 0; index < data.length; index++) {
            rowList.push({
              ...data[index],
              id: index + 1,
              AMOUNT_PAID: data[index].EXPENCE_AMOUNT,
              AMOUNT_DESC: '',
            });
          }

          setExpenseList(rowList);
        }
      })
      .catch((e) => {
        if (e.response.data.name === 'TokenExpiredError') {
          dispatch(setSessionExpired(true));
        }
        console.log(e);
      });
  };

  useEffect(() => {
    getListExpenseMaster();
  }, []);

  useEffect(() => {
    dispatch(setTotalExpense(total));
  }, [list]);

  return (
    <div className="w-full h-full  flex justify-center  bg-[#f5f5f7] overflow-y-scroll py-4 items-center  ">
      <div className="h-full ">
        <div style={{ borderRadius: '12px' }} className=" ">
          <div ref={headerRef}>
            <ModalHeaderStyle
              header={'Expense Voucher'}
              onClose={() => {
                navigate('/updation');
              }}
            />
          </div>

          <div
            style={{
              height: headerRef.current
                ? `calc(100% - ${headerRef.current.clientHeight}px)`
                : '90%',
            }}
            className="flex flex-col  "
          >
            <div
              style={{
                height: 'calc(100% -80px)',
              }}
              className="  py-3 px-5 justify-center  pt-5 bg-white max-sm:px-2"
            >
              <div className=" grid grid-cols-2 gap-2 mt-2 max-sm:mt-0">
                <div>
                  <GeniustextInput
                    title={'Date'}
                    width={windowWidth > 764 ? '150px' : '150px'}
                    type={'text'}
                    value={moment().format('YYYY-MM-DD')}
                  />
                </div>
              </div>
              <div className="flex">
                <GeniustextInput
                  title={'Station Id'}
                  search={() => {
                    dispatch(setListStationMasterModalStatus(true));
                  }}
                  width={windowWidth > 764 ? '150px' : '150px'}
                  type={'number'}
                  value={SID}
                />
                <div className="ml-2">
                  <GeniustextInput
                    title={'Station Name'}
                    width={windowWidth > 764 ? '300px' : '150px'}
                    type={'text'}
                    value={stationName}
                  />
                </div>
              </div>

              <div className=" flex">
                <GeniustextInput
                  title={'Expense Name'}
                  width={windowWidth > 764 ? '300px' : '150px'}
                  type={'text'}
                  value={expenseName}
                />
                <div className="ml-2">
                  <GeniustextInput
                    title={'Expense Amount'}
                    width={windowWidth > 764 ? '150px' : '150px'}
                    type={'number'}
                    value={expenseAmount}
                    onChange={(t) =>
                      setExpenseAmount(toProperCase(t.target.value))
                    }
                  />
                </div>
              </div>

              <div className="flex">
                <GeniustextInput
                  title={'Description'}
                  width={windowWidth > 764 ? '450px' : '300px'}
                  type={'text'}
                  value={expenseDesc}
                  onChange={(t) => setExpenseDesc(toProperCase(t.target.value))}
                />
              </div>

              <div
                style={{
                  width:
                    window.innerWidth <= 500
                      ? columnsMobile[0].width + columnsMobile[1].width + 30
                      : columns[0].width + columns[1].width + 30,
                  height: '280px',
                }}
                className="w-full  mt-1 max-sm:mt-0 flex"
              >
                <GeniusDatagrid
                  rowID={(t) => t.CODE}
                  onRowClick={(p) => {
                    const row = p.row;
                    setExpenseName(row.EXPENCE_NAME);
                    setExpenseDesc(row.AMOUNT_DESC);
                    setExpenseAmount(row.AMOUNT_PAID);
                    setCode(row.CODE);
                  }}
                  columns={window.innerWidth <= 500 ? columnsMobile : columns}
                  list={expenseList}
                />
              </div>

              <div className="flex mt-4">
                <div className="flex justify-between">
                  <div>Paymode</div>

                  <span>:</span>
                </div>
                <div className="ml-3">{paymode}</div>
              </div>

              <div className=" flex mt-4 justify-center max-sm:block">
                <div className="flex justify-around">
                  <div>
                    <GeniusOfficeButton
                      checked={paymode === 'CASH' ? true : false}
                      onClick={() => dispatch(setExpensePaymode('CASH'))}
                      title={'Cash Amt.'}
                      color={'#ffffff'}
                    />
                  </div>

                  <div>
                    <GeniusOfficeButton
                      checked={paymode === 'CARD' ? true : false}
                      onClick={() => dispatch(setExpensePaymode('CARD'))}
                      title={'Card Amt.'}
                      color={'#ffffff'}
                    />
                  </div>
                </div>

                <div className="flex justify-around max-sm:mt-2">
                  <GeniusOfficeButton
                    checked={paymode === 'EPayment' ? true : false}
                    onClick={() => dispatch(setExpensePaymode('EPayment'))}
                    title={'ePayment'}
                    color={'#ffffff'}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                borderTop: '1px solid #D2D2D7',
              }}
              className="flex justify-end bg-white py-5 rounded-b-sm  px-4"
            >
              <SaveButton onClick={handleSave} />
              <UndoButton
                onClick={() => {
                  setExpenseName('');
                  setExpenseAmount('');
                  setCode('');
                  setExpenseDesc('');
                }}
              />
              <ExitButton
                onClick={() => {
                  navigate('/updation');
                }}
              />
              <ModListStationMaster
                onRowClick={(p) => {
                  setSid(p.row.CODE);
                  setStationName(p.row.STATION_NAME);
                  dispatch(setListStationMasterModalStatus(false));
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalExpenseVoucher;
